import { createSlice } from '@reduxjs/toolkit';
import deviceCategories from '../common/static/deviceCategories';

const sourceCategories = ['anti_uav', 'uav', 'radar'];

const { reducer, actions } = createSlice({
  name: 'devices',
  initialState: {
    items: {},
    sources: {},
    itemsParams: JSON.parse(window.localStorage.getItem('devicesParams')) ?? {},
    stateSyncDevices: new Date().getTime(),
    stateSyncDevicesRemoving: new Date().getTime(),
    stateSyncClosedDevices: new Date().getTime(),
    stateSyncItemsParams: new Date().getTime(),
    selectedId: null,
    closedDevices: JSON.parse(window.localStorage.getItem('closedDevices')) || {},
    categories: JSON.parse(window.localStorage.getItem('deviceCategoriesOrder')) || deviceCategories,
    accessibleCategoriesByDetected: [],
    deviceInited: false,
  },
  reducers: {
    init(state, action) {
      state.deviceInited = action.payload;
    },
    refresh(state, action) {
      let needUpdateClosedDevices = true;
      const closedDevice = JSON.parse(localStorage.getItem('closedDevices'));
      const closeNewDevices = JSON.parse(localStorage.getItem('closeNewDevices'));
      const closedGroups = JSON.parse(localStorage.getItem('closedGroups'));

      state.items = {};

      action.payload.forEach((item) => {
        state.items[item.id] = item;
        if (closedDevice && closedDevice[item.id] === undefined) {
          const isClosedNewDevice = closeNewDevices && closeNewDevices[item.category];
          const isClosedGroup = closedGroups && closedGroups[item.category] && closedGroups[item.category][item.groupId];

          if (isClosedNewDevice || isClosedGroup) {
            state.closedDevices[item.id] = true;
          } else {
            state.closedDevices[item.id] = false;
          }
          needUpdateClosedDevices = true;
        }
      });

      state.stateSyncDevices = new Date().getTime();
      state.stateSyncDevicesRemoving = new Date().getTime();
      if (needUpdateClosedDevices) {
        window.localStorage.setItem('closedDevices', JSON.stringify(state.closedDevices));
        state.stateSyncClosedDevices = new Date().getTime();
      }
    },
    update(state, action) {
      let needUpdateClosedDevices = false;
      const closeNewDevices = JSON.parse(localStorage.getItem('closeNewDevices'));
      const closedGroups = JSON.parse(localStorage.getItem('closedGroups'));

      action.payload.forEach((item) => {
        if (sourceCategories.includes(item.category)) {
          state.sources[item.uniqueId] = item.id;
        }

        state.items[item.id] = item;

        if (state.closedDevices[item.id] === undefined) {
          const isClosedNewDevice = closeNewDevices && closeNewDevices[item.category];
          const isClosedGroup = closedGroups && closedGroups[item.category] && closedGroups[item.category][item.groupId];

          if (isClosedNewDevice || isClosedGroup) {
            state.closedDevices[item.id] = true;
          } else {
            state.closedDevices[item.id] = false;
          }
          needUpdateClosedDevices = true;
        }
      });

      state.stateSyncDevices = new Date().getTime();
      if (needUpdateClosedDevices) {
        window.localStorage.setItem('closedDevices', JSON.stringify(state.closedDevices));
        state.stateSyncClosedDevices = new Date().getTime();
      }
    },
    select(state, action) {
      state.selectedId = action.payload.id;
    },
    deselect(state, action) {
      state.selectedId = action.payload;
    },
    remove(state, action) {
      for (const item of action.payload) {
        const foundSource = state.items[item];
        if (foundSource && sourceCategories.includes(foundSource.category)) {
          delete state.sources[foundSource.uniqueId];
        }

        delete state.items[item];
        delete state.closedDevices[item];
      }
      if (action.payload.length) {
        window.localStorage.setItem('closedDevices', JSON.stringify(state.closedDevices));
        state.stateSyncDevices = new Date().getTime();
        state.stateSyncClosedDevices = new Date().getTime();
        state.stateSyncDevicesRemoving = new Date().getTime();
      }
    },
    hide(state, action) {
      state.closedDevices[Number(action.payload)] = true;
      window.localStorage.setItem('closedDevices', JSON.stringify(state.closedDevices));
      state.stateSyncClosedDevices = new Date().getTime();
    },
    open(state, action) {
      state.closedDevices[Number(action.payload)] = false;
      window.localStorage.setItem('closedDevices', JSON.stringify(state.closedDevices));
      state.stateSyncClosedDevices = new Date().getTime();
    },
    turnAll(state, action) {
      state.closedDevices = { ...action.payload };
      window.localStorage.setItem('closedDevices', JSON.stringify(state.closedDevices));
      state.stateSyncClosedDevices = new Date().getTime();
    },
    clear(state) {
      state.items = {};
      state.selectedId = null;
      window.localStorage.setItem('closedDevices', JSON.stringify({}));
      state.closedDevices = {};
      state.sources = {};
      state.stateSyncDevices = new Date().getTime();
      state.stateSyncDevicesRemoving = new Date().getTime();
      state.stateSyncClosedDevices = new Date().getTime();
    },
    changeCategories(state, action) {
      state.categories = action.payload;
      window.localStorage.setItem('deviceCategoriesOrder', JSON.stringify(state.categories));
    },
    changeCategory(state, action) {
      state.categories[action.payload.title] = action.payload;
      window.localStorage.setItem('deviceCategoriesOrder', JSON.stringify(state.categories));
    },
    addAccessibleCategories(state, action) {
      state.accessibleCategoriesByDetected = [...action.payload];
    },
    changeItemParam(state, action) {
      Object.entries(action.payload).forEach(([deviceId, param]) => {
        state.itemsParams[deviceId] = { ...(state.itemsParams[deviceId] ?? {}), ...param };
      });
      window.localStorage.setItem('devicesParams', JSON.stringify(state.itemsParams));
      state.stateSyncItemsParams = new Date().getTime();
    },
    changeTemporaryStatus(state, action) {
      const { devices, value } = action.payload;
      const category = 'detected_object';

      if (Array.isArray(devices)) {
        for (const deviceId of devices) {
          state.items[category][deviceId].temporary = value;
        }
      } else {
        state.items[category][devices].temporary = value;
      }

      state.stateSyncDevices = new Date().getTime();
    },
  },
});

export { actions as devicesActions };
export { reducer as devicesReducer };
