import React, { useEffect, useState } from 'react';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../../common/components/LocalizationProvider';
import EditItemView from './components/EditItemView';
import MarkerEditForm from '../../common/components/MarkerEditForm';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const MarkerPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const [item, setItem] = useState();
  const [permissions, setPermissions] = useState();
  const [errors, setErrors] = useState({});
  const [initState, setInitState] = useState();

  useEffect(() => {
    setItem(initState);
  }, [initState]);

  useEffect(() => {
    if (item && !permissions) {
      const permissionsDict = {
        unions: {
          baseId: item.id,
          keyBase: 'markerId',
          keyLink: 'unionId',
          linked: new Set(),
          old: new Set(),
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  return (
    <EditItemView
      endpoint="markers"
      item={item}
      setItem={setInitState}
      permissions={permissions}
      permissionsReverse
      disabledSave={!item?.color
        || !item?.iconName || Object.values(errors).some((v) => v)}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('settingsMarker')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>

              <MarkerEditForm
                item={item}
                setItem={setItem}
                permissions={permissions}
                setPermissions={setPermissions}
                errors={errors}
                setErrors={setErrors}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </EditItemView>
  );
};

export default MarkerPage;
