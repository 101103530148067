import React, { useEffect, useState } from 'react';
import {
  makeStyles, Paper, Tooltip,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  trackingMode: {
    borderRadius: '10px',
    minWidth: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  avatar: {
    backgroundColor: 'rgba(255,255,255,0)',
    cursor: 'pointer',
  },
  eye: {
    color: '#E32636',
    animation: '$color 10s infinite alternate',
  },
  '@keyframes color': {
    '50%': {
      color: '#ebebeb',
    },
  },
}));
const MarkerMovingModeOn = ({ moveableMarker, setMoveableMarker }) => {
  const classes = useStyles();
  const t = useTranslation();
  const [trackingMode, setTrackingMode] = useState(false);

  useEffect(() => {
    setTrackingMode(true);
  }, [moveableMarker]);

  useEffect(() => {
    if (trackingMode) {
      setTimeout(() => {
        setTrackingMode(false);
      }, 5000);
    }
  }, [trackingMode]);

  return (
    <>
      <Paper className={classes.trackingMode} elevation={3}>
        <Tooltip title={t('markerMovingMode')}>
          <Avatar
            onClick={() => setMoveableMarker(null)}
            className={classes.avatar}
          >
            <OpenWithIcon className={classes.eye} />
          </Avatar>
        </Tooltip>
      </Paper>
    </>
  );
};

export default MarkerMovingModeOn;
