import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'devextreme-react/data-grid';
import {
  formatPosition, getCategoryParam,
} from '../../common/utils/formatter';
import { useTranslation } from '../../common/components/LocalizationProvider';
import usePersistedState from '../../common/utils/usePersistedState';
import { positionHandler, requestTemporaries } from '../../common/utils/requestRoute';
import ReportSmartTemplate from './components/ReportSmartTemplate';
import { getGroupName } from '../../common/components/SmartTable/funcs/calculateCellValue';
import useTemplatesFieldsPresetForReports from '../../common/components/SmartTable/hooks/columnTemplates/reportTables/useTemplatesFieldsPresetForReports';
import useAutoCompleteColumns from '../../common/components/SmartTable/hooks/useAutoCompleteColumns';
import { usePreference } from '../../common/utils/preferences';

// Имя ключа в localStorage со списком выключенных колонок
const typeReport = 'detectedObjectReport';

const columnsArray = [];

const DetectedObjectsReportPage = () => {
  const category = 'detected_object';
  const t = useTranslation();
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const coordinateFormat = usePreference('coordinateFormat');
  const history = useHistory();
  const dispatch = useDispatch();

  const groups = useSelector((state) => state.groups.items);

  const [items, setItems] = useState([]);
  const [progress, setProgress] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [positionsPath, setPositionsPath] = useState({});
  const [progressReport, setProgressReport] = useState(0);
  const reportAbortController = useRef();
  const devicesAbortController = useRef();

  const templateField = useTemplatesFieldsPresetForReports({}, category, coordinateFormat);
  const reportColumns = useAutoCompleteColumns(templateField);

  const handleSubmit = async (deviceId, from, to, headers, hideLoadingForm) => {
    setItems([]);
    setProgressReport(0);
    const result = [];
    setProgress(1);
    await requestTemporaries(history, dispatch, result, from, to, reportAbortController,
      headers, setProgressReport, hideLoadingForm);
    setItems(result);
    setProgress(100);
    setProgress(0);
  };

  const formatSmartValue = (key, caption, devices) => {
    switch (key) {
      case 'serverTime':
      case 'fixTime':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="datetime"
            caption={t(caption)}
          />
        );
      case 'uniqueId':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => devices[item[key]]?.uniqueId}
            caption={t(caption)}
          />
        );
      case 'sourceId':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => getCategoryParam(item, key)}
            caption={t(caption)}
          />
        );
      case 'deviceId':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => devices[item[key]]?.name}
            caption={t(caption)}
          />
        );
      case 'group':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => getGroupName(devices[item.deviceId] ?? {}, groups)}
            caption={t(caption)}
          />
        );
      case 'longitude':
      case 'latitude':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => formatPosition(item[key], key, t)}
            caption={t(caption)}
          />
        );
      case 'address':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => formatPosition(item[key], key, t)}
            caption={t(caption)}
          />
        );
      case 'sector':
      case 'centerFrequency':
      case 'bandwidth':
      case 'averageSnr':
      case 'comment':
      case 'peleng':
      case 'dist':
      case 'zone':
        return (
          <Column
            key={key}
            name={`attributes.categoryParams.${key}`}
            dataField={`attributes.categoryParams.${key}`}
            calculateCellValue={(item) => (item.attributes.categoryParams ? (item.attributes.categoryParams[key] ?? '') : '')}
            caption={t(caption)}
          />
        );
      default:
        return (
          <Column
            dataField={key}
            caption={t(caption)}
            dataType="string"
          />
        );
    }
  };

  useEffect(() => {
    if (selectedItem && !Object.keys(positionsPath).length) {
      const path = items.reduce((a, v) => ({ ...a, [v.deviceId]: a[v.deviceId] ? [...a[v.deviceId], v] : [v] }), {});
      const deviceIds = Object.keys(path);
      const result = {};
      for (let i = 0; i < deviceIds.length; i += 1) {
        const deviceId = deviceIds[i];
        const clearResponsePath = [];
        positionHandler(path[deviceId], clearResponsePath, true);
        result[deviceId] = clearResponsePath;
      }
      setPositionsPath(result);
    }
  }, [selectedItem]);

  useEffect(() => () => {
    if (reportAbortController.current) {
      reportAbortController.current.abort(); // Cancel the request
    }
  }, []);

  useEffect(() => () => {
    if (devicesAbortController.current) {
      devicesAbortController.current.abort(); // Cancel the request
    }
  }, []);

  return (
    <ReportSmartTemplate
      items={items}
      progress={progress}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="fixTime"
      formatValue={formatSmartValue}
      reportColumns={reportColumns}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      positionsPath={positionsPath}
      setPositionsPath={setPositionsPath}
      breadcrumbs={['reportTitle', 'reportDetectedObject']}
      withoutDevices
      progressReport={progressReport}
    // groups={groups}
    // chosenGroups={chosenGroups}
    // chooseGroups={chooseGroups}
    // map={selectedItem && (
    //   <div className={classes.containerMap}>
    //     <Map noFixed>
    //       <ReplayPathMap positions={positionsPath} devices={devicesObject} />
    //       <PositionsMap
    //         positions={{ [selectedItem.deviceId]: [selectedItem] }}
    //         devices={devicesObject}
    //         data={positionsTypes.current}
    //         noClick
    //       />
    //       <MapCamera latitude={selectedItem.latitude} longitude={selectedItem.longitude} />
    //     </Map>
    //   </div>
    // )}
    // mapOn
    />
  );
};

export default DetectedObjectsReportPage;
