import React from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Checkbox, FormControlLabel,
  Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { toTitleCase } from '../../../common/utils/formatter';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  categoryTitle: {
    marginBottom: 16,
  },
}));

const DevicesBlock = ({ item, setItem }) => {
  const classes = useStyles();
  const t = useTranslation();
  const categories = useSelector((state) => state.devices.categories);

  const handleCheckboxChange = (category, key, checked) => {
    setItem((prevState) => {
      const currentSettings = prevState.attributes?.deviceDisplaySettings?.[category] || {};
      return {
        ...prevState,
        attributes: {
          ...prevState.attributes,
          deviceDisplaySettings: {
            ...prevState.attributes?.deviceDisplaySettings,
            [category]: {
              ...currentSettings,
              [key]: checked,
            },
          },
        },
      };
    });
  };

  const getCheckedStatus = (category, type) => {
    try {
      return item.attributes.deviceDisplaySettings[category][type];
    } catch (error) {
      return false;
    }
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('settingsUserDevices')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {Object.keys(categories).map((category) => (
          <React.Fragment key={category}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body2">
                  {t(`serverPage${toTitleCase(category)}`)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={getCheckedStatus(category, 'colorDeviceByStatus')}
                      onChange={(event) => handleCheckboxChange(category, 'colorDeviceByStatus', event.target.checked)}
                    />
                  )}
                  label={t('colorDeviceByStatus')}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={getCheckedStatus(category, 'opacityByLifetime')}
                      onChange={(event) => handleCheckboxChange(category, 'opacityByLifetime', event.target.checked)}
                    />
                  )}
                  label={t('opacityByLifetime')}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={getCheckedStatus(category, 'showStroke')}
                      onChange={(event) => handleCheckboxChange(category, 'showStroke', event.target.checked)}
                    />
                  )}
                  label={t('showStroke')}
                />
              </AccordionDetails>
            </Accordion>
            <Divider />
          </React.Fragment>
        ))}

        <React.Fragment key="temporary">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2">
                {t(`serverPage${toTitleCase('temporary')}`)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={getCheckedStatus('temporary', 'colorDeviceByStatus')}
                    onChange={(event) => handleCheckboxChange('temporary', 'colorDeviceByStatus', event.target.checked)}
                  />
                )}
                label={t('colorDeviceByStatus')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={getCheckedStatus('temporary', 'opacityByLifetime')}
                    onChange={(event) => handleCheckboxChange('temporary', 'opacityByLifetime', event.target.checked)}
                  />
                )}
                label={t('opacityByLifetime')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={getCheckedStatus('temporary', 'showStroke')}
                    onChange={(event) => handleCheckboxChange('temporary', 'showStroke', event.target.checked)}
                  />
                )}
                label={t('showStroke')}
              />
            </AccordionDetails>
          </Accordion>
        </React.Fragment>

        <React.Fragment key="markers">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2">
                {t(`serverPage${toTitleCase('markers')}`)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={getCheckedStatus('markers', 'opacityByLifetime')}
                    onChange={(event) => handleCheckboxChange('markers', 'opacityByLifetime', event.target.checked)}
                  />
                )}
                label={t('opacityByLifetime')}
              />
            </AccordionDetails>
          </Accordion>
        </React.Fragment>

      </AccordionDetails>
    </Accordion>
  );
};

export default DevicesBlock;
