/* eslint-disable object-curly-newline */
import { getSourceName } from '../../funcs/calculateCellValue';
import presetsColumns from '../shared/presetsColumns';
import baseProperties from '../shared/baseProperties';
import reportsSharedFields from './reportsSharedColumns';

export default ({ groups, sources, devices, t, theme, coordinateFormat }) => [
  ...reportsSharedFields.calculateValues.sharedSlim({ groups, t, theme }),
  {
    ...presetsColumns.base.sourceId,
    ...baseProperties,
    calculateCellValue: (item) => getSourceName(item, sources, devices),
  },
  ...reportsSharedFields.serverField,
  presetsColumns.common.speed,
  presetsColumns.common.latitude({ coordinateFormat }),
  presetsColumns.common.longitude({ coordinateFormat }),
  presetsColumns.common.altitude,
  presetsColumns.common.address,
  presetsColumns.common.attributes.categoryParams.averageSnr,
  presetsColumns.common.attributes.categoryParams.bandwidth,
  presetsColumns.common.attributes.categoryParams.centerFrequency,
  presetsColumns.common.attributes.categoryParams.comment,
  presetsColumns.common.attributes.categoryParams.dist,
  presetsColumns.common.attributes.categoryParams.peleng,
  presetsColumns.common.course,
  presetsColumns.common.attributes.categoryParams.sector,
  presetsColumns.common.attributes.categoryParams.zone,
  presetsColumns.common.attributes.categoryParams.elevationAngle,
];
