import moment from 'moment';
import theme from '../../common/theme';
import { getTitle, getCategory, toTitleCase } from '../../common/utils/formatter';
import { getBeforeId } from './pathFuncs';
import { map } from '../Map';
import { convertToConfig } from '../MapControllerDynamic';
import store from '../../store';
import icons from '../../common/static/icons';

const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
const onMouseLeave = () => map.getCanvas().style.cursor = '';

export const createLayers = (id, clustersId, data, onClusterClick, onMarkerClick, onClusters) => {
  const clustersCountId = `${id}-clusters-count`;
  const courseId = `${id}-course`;

  map.addSource(id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
    cluster: !!onClusters,
    clusterMaxZoom: 14,
    clusterRadius: 20,
  });

  // Слой для иконок, которые могут вращаться (canRotate = true) и для всех иконок типа 'replay'
  map.addLayer({
    id: `${id}_rotatable`,
    type: 'symbol',
    source: id,
    filter: [
      'all',
      ['!', ['has', 'point_count']],
      ['any',
        ['==', ['get', 'canRotate'], true],
        data.type === 'replay',
      ],
    ],
    layout: {
      'icon-image': '{icon}',
      'icon-allow-overlap': true,
      'icon-size': { type: 'identity', property: 'iconSize' },
      'icon-rotate': data.type === 'replay' ? { type: 'identity', property: 'course' } : ['case', ['boolean', ['get', 'canRotate'], false], ['get', 'course'], 0],
      'icon-rotation-alignment': 'map',
      'text-field': '{title}',
      'text-allow-overlap': true,
      'text-anchor': 'center',
      'text-offset': { type: 'identity', property: 'textOffset' },
      'text-font': ['Roboto Medium'],
      'text-size': 12,
    },
    paint: {
      'icon-opacity': { type: 'identity', property: 'iconOpacity' },
      'text-halo-color': 'white',
      'text-halo-width': 1,
      'text-color': { type: 'identity', property: 'textColor' },
      'text-opacity': { type: 'identity', property: 'textOpacity' },
    },
  }, getBeforeId(`${id}_rotatable`));

  // Слой для всех остальных иконок (canRotate = false и type !== 'replay')
  map.addLayer({
    id: `${id}_non_rotatable`,
    type: 'symbol',
    source: id,
    filter: [
      'all',
      ['!', ['has', 'point_count']],
      ['all',
        ['==', ['get', 'canRotate'], false],
        data.type !== 'replay',
      ],
    ],
    layout: {
      'icon-image': '{icon}',
      'icon-allow-overlap': true,
      'icon-size': { type: 'identity', property: 'iconSize' },
      'icon-rotate': 0,
      'icon-rotation-alignment': 'viewport',
      'text-field': '{title}',
      'text-allow-overlap': true,
      'text-anchor': 'center',
      'text-offset': { type: 'identity', property: 'textOffset' },
      'text-font': ['Roboto Medium'],
      'text-size': 12,
    },
    paint: {
      'icon-opacity': { type: 'identity', property: 'iconOpacity' },
      'text-halo-color': 'white',
      'text-halo-width': 1,
      'text-color': { type: 'identity', property: 'textColor' },
      'text-opacity': { type: 'identity', property: 'textOpacity' },
    },
  }, getBeforeId(`${id}_non_rotatable`));

  if (data.name === 'current') {
    map.addLayer({
      id: courseId,
      type: 'symbol',
      source: id,
      filter: [
        'all',
        ['!=', '$type', 'Polygon'],
      ],
      layout: {
        'icon-image': '{courseIcon}',
        'icon-rotate': { type: 'identity', property: 'course' },
        'icon-rotation-alignment': 'map',
      },
      paint: {
        'icon-opacity': { type: 'identity', property: 'iconOpacity' },
      },
    }, getBeforeId(courseId));
  }

  if (onClusters) {
    map.addLayer({
      id: clustersId,
      type: 'circle',
      source: id,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#bbbd55',
          3,
          '#d9a9a3',
          4,
          '#d6796d',
          6,
          '#e3513d',
        ],
        'circle-opacity': 0.8,
        'circle-blur': 0.1,
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          data.type === 'replay' ? 10 : 15,
          3,
          15,
          4,
          20,
          6,
          30,
        ],
      },
    }, getBeforeId(clustersId));

    map.addLayer({
      id: clustersCountId,
      type: 'symbol',
      source: id,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['Roboto Regular'],
        'text-size': [
          'step',
          ['get', 'point_count'],
          data.type === 'replay' ? 10 : 15,
          4,
          15,
        ],
      },
    }, getBeforeId(clustersCountId));

    map.on('mouseenter', clustersId, onMouseEnter);
    map.on('mouseleave', clustersId, onMouseLeave);
    map.on('click', clustersId, onClusterClick);
  }

  map.on('mouseenter', `${id}_rotatable`, onMouseEnter);
  map.on('mouseleave', `${id}_rotatable`, onMouseLeave);
  map.on('click', `${id}_rotatable`, onMarkerClick);

  map.on('mouseenter', `${id}_non_rotatable`, onMouseEnter);
  map.on('mouseleave', `${id}_non_rotatable`, onMouseLeave);
  map.on('click', `${id}_non_rotatable`, onMarkerClick);

  return () => {
    Array.from(map.getContainer().getElementsByClassName('maplibregl-popup'))
      .forEach((el) => el.remove());

    if (onClusters) {
      map.off('mouseenter', clustersId, onMouseEnter);
      map.off('mouseleave', clustersId, onMouseLeave);
      map.off('click', clustersId, onClusterClick);

      if (map.getLayer(clustersId)) {
        map.removeLayer(clustersId);
      }
      if (map.getLayer(clustersCountId)) {
        map.removeLayer(clustersCountId);
      }
    }

    map.off('mouseenter', `${id}_rotatable`, onMouseEnter);
    map.off('mouseleave', `${id}_rotatable`, onMouseLeave);
    map.off('click', `${id}_rotatable`, onMarkerClick);

    map.off('mouseenter', `${id}_non_rotatable`, onMouseEnter);
    map.off('mouseleave', `${id}_non_rotatable`, onMouseLeave);
    map.off('click', `${id}_non_rotatable`, onMarkerClick);

    if (map.getLayer(`${id}_rotatable`)) {
      map.removeLayer(`${id}_rotatable`);
    }
    if (map.getLayer(`${id}_non_rotatable`)) {
      map.removeLayer(`${id}_non_rotatable`);
    }
    if (map.getLayer(courseId)) {
      map.removeLayer(courseId);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  };
};

export const crateTemporaryLayer = (id, data, onMarkerClick) => {
  map.addSource(id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    id,
    type: 'symbol',
    source: id,
    layout: {
      'icon-image': '{icon}',
      'icon-size': 0.7,
      'icon-allow-overlap': true,
      'text-field': '{title}',
      'text-allow-overlap': true,
      'text-anchor': 'bottom',
      'text-offset': data.type === 'extra' ? [0, -1] : [0, -2],
      'text-font': ['Roboto Medium'],
      'text-size': 12,
    },
    // paint: {
    //   'icon-opacity': 1,
    //   'icon-opacity-transition': {
    //     duration: formatTimeoutToMs(temporaryLifetime.value, temporaryLifetime.time),
    //   },
    //   'text-opacity': 1,
    //   'text-opacity-transition': {
    //     duration: formatTimeoutToMs(temporaryLifetime.value, temporaryLifetime.time),
    //   },
    // },
  }, getBeforeId(data?.name));

  map.on('mouseenter', id, onMouseEnter);
  map.on('mouseleave', id, onMouseLeave);
  map.on('click', id, onMarkerClick);

  // if (!isPersistance) {
  //   map.setPaintProperty(id, 'icon-opacity', 0);
  //   map.setPaintProperty(id, 'text-opacity', 0);
  // }

  return () => {
    map.off('click', id, onMarkerClick);
    map.off('mouseenter', id, onMouseEnter);
    map.off('mouseleave', id, onMouseLeave);

    if (map.getLayer(id)) {
      map.removeLayer(id);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  };
};

export const createRadarLayers = (id, data) => {
  const radarId = `${id}-radar`;
  const radarId3D = `${id}-radar-3D`;
  const sectorId = `${id}-sector`;
  const sectorId3D = `${id}-sector-3D`;

  if (data.name === 'current') {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    map.addLayer({
      source: id,
      id: radarId,
      type: 'fill',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'category', 'radar'],
      ],
      paint: {
        'fill-color': { type: 'identity', property: 'radarColor' },
        'fill-outline-color': '#705439',
        'fill-opacity': 0.25,
      },
    }, getBeforeId(radarId));

    map.addLayer({
      source: id,
      id: radarId3D,
      type: 'fill-extrusion',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'category', 'radar'],
        ['==', 'showIn3D', true],
      ],
      paint: {
        'fill-extrusion-color': { type: 'identity', property: 'radarColor' },
        'fill-extrusion-opacity': 0.25,
        'fill-extrusion-height': { type: 'identity', property: 'altitude' },
        'fill-extrusion-base': { type: 'identity', property: 'minAltitude' },
      },
    }, getBeforeId(radarId3D));

    map.addLayer({
      source: id,
      id: sectorId,
      type: 'fill',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'category', 'sector'],
      ],
      paint: {
        'fill-color': { type: 'identity', property: 'radarColor' },
        'fill-outline-color': '#705439',
        'fill-opacity': { type: 'identity', property: 'opacity' },
      },
    }, getBeforeId(sectorId));

    map.addLayer({
      source: id,
      id: sectorId3D,
      type: 'fill-extrusion',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'category', 'sector'],
        ['==', 'showIn3D', true],
      ],
      paint: {
        'fill-extrusion-color': { type: 'identity', property: 'radarColor' },
        'fill-extrusion-opacity': 0.25,
        'fill-extrusion-height': { type: 'identity', property: 'altitude' },
        'fill-extrusion-base': { type: 'identity', property: 'minAltitude' },
      },
    }, getBeforeId(sectorId3D));
  }

  return () => {
    Array.from(map.getContainer().getElementsByClassName('maplibregl-popup'))
      .forEach((el) => el.remove());

    if (map.getLayer(radarId)) {
      map.removeLayer(radarId);
    }
    if (map.getLayer(radarId3D)) {
      map.removeLayer(radarId3D);
    }
    if (map.getLayer(sectorId)) {
      map.removeLayer(sectorId);
    }
    if (map.getLayer(sectorId3D)) {
      map.removeLayer(sectorId3D);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  };
};

export const deviceColor = (device) => {
  switch (device.status) {
    case 'online':
      return 'green';
    case 'offline':
      return 'red';
    default:
      return 'gray';
  }
};

const getStatus = (device, position, t) => {
  let title = '';
  let textColor = '#000';
  const positionParams = position.attributes.categoryParams;
  if (positionParams) {
    switch (device.category) {
      case 'radar':
      case 'anti_uav':
        switch (positionParams.connectionStatus) {
          case 'disconnected':
          case 'error':
            title += positionParams.connectionStatusDescription || t(`connectionStatus${toTitleCase(positionParams.connectionStatus)}`);
            textColor = '#9c0000';
            break;
          default:
            break;
        }
        if (positionParams.workModeDescription || positionParams.workMode) {
          title += `\n${positionParams.workModeDescription || t(`workMode${toTitleCase(positionParams.workMode)}`)}`;
        }
        break;
      default:
        break;
    }
  }
  return { title, textColor };
};

const getOpacity = (category, lastUpdate) => {
  try {
    let lifetime;
    let needToShowOpacity;
    if (category === 'temporary') {
      lifetime = store.getState().session?.server?.attributes?.temporaryLifetime;
      needToShowOpacity = store.getState().session?.user?.attributes?.deviceDisplaySettings.temporary.opacityByLifetime;
    } else {
      lifetime = store.getState().session?.server?.attributes?.categoryLifetime[category];
      needToShowOpacity = store.getState().session?.user?.attributes?.deviceDisplaySettings[category].opacityByLifetime;
    }

    let ratio = 1;
    if (lifetime && needToShowOpacity) {
      const lastUpdateMoment = moment(lastUpdate);
      const now = moment();
      const elapsedMilliseconds = now.diff(lastUpdateMoment);
      const lifetimeMilliseconds = moment.duration(lifetime.value, lifetime.time).asMilliseconds();
      ratio = 1 - (elapsedMilliseconds / lifetimeMilliseconds);
    }
    return ratio;
  } catch (error) {
    return 1;
  }
};

const getStroke = (category, primaryColor) => {
  try {
    const needToShowStroke = store.getState().session?.user?.attributes?.deviceDisplaySettings[category].showStroke;
    if (needToShowStroke) {
      return primaryColor;
    }
    return '';
  } catch (error) {
    return '';
  }
};

const getPrimary = (device, category, primaryColor) => {
  try {
    const needToColorDevice = store.getState().session?.user?.attributes?.deviceDisplaySettings[category].colorDeviceByStatus;
    if (category === 'detected_object' || !needToColorDevice) {
      return primaryColor;
    }
    return deviceColor(device);
  } catch (error) {
    return primaryColor;
  }
};

export const getPositionProperties = (position, device, user, t, data, text, positionsSimilar) => {
  const params = { textColor: '#000' };
  const samePositions = positionsSimilar && positionsSimilar[position.deviceId] && positionsSimilar[position.deviceId][`${position.longitude}-${position.latitude}`];
  const numberPoints = samePositions?.length;
  let iconReplay;
  if (!data.icon) {
    if (data.type === 'replay') {
      if (position.attributes?.approximate) {
        iconReplay = numberPoints > 1 ? 'bs-hot' : 'bs';
      } else if (position.course !== -1) {
        iconReplay = numberPoints > 1 ? 'arrow-hot' : 'arrow';
      } else {
        iconReplay = numberPoints > 1 ? 'point-hot' : 'point';
      }
    } else if (data.type === 'current') {
      const primaryColor = device.attributes.color || theme.trackColors.replay0;
      if (device.temporary) {
        iconReplay = convertToConfig({
          temporary: 'temporary',
          icon: device.icon || 'default',
          primary: getPrimary(device, 'temporary', primaryColor),
          colorStroke: getStroke('temporary', primaryColor),
          category: device.category,
        });
      } else {
        iconReplay = convertToConfig({
          icon: device.icon || 'default',
          primary: getPrimary(device, device.category, primaryColor),
          colorStroke: getStroke(device.category, primaryColor),
          category: device.category,
        });
      }
    }
  }
  let title = '';
  if (text) {
    title = getTitle(device.name, position.fixTime, data.name);
    if (data.type === 'current') {
      const extraText = getStatus(device, position, t);
      if (extraText.title) {
        title += `\n${extraText.title}`;
        params.textColor = extraText.textColor;
      }
      if (device.disabled) {
        params.textColor = theme.palette.common.gray;
      }
    }
  }
  if (device.disabled) {
    params.iconOpacity = 0.5;
  }

  if (device.lastUpdate) {
    if (device.temporary) {
      params.iconOpacity = getOpacity('temporary', device.lastUpdate);
      params.textOpacity = getOpacity('temporary', device.lastUpdate);
    } else {
      params.iconOpacity = getOpacity(device.category, device.lastUpdate);
      params.textOpacity = getOpacity(device.category, device.lastUpdate);
    }
  }
  return {
    id: position.id,
    course: position.course || 0,
    canRotate: icons[device.icon]?.canRotate,
    numberPoints,
    icon: data.icon || iconReplay,
    iconSize: device.temporary && data.type === 'current' ? 0.7 : data.iconSize,
    courseIcon: position.course !== -1 ? 'course' : null,
    title,
    deviceId: device.id,
    radarColor: device.attributes.radarColor,
    textOffset: [0, -4],
    ...params,
  };
};

export const getStopProperties = (position, device, data, text, groups) => {
  let title = '';
  if (text) {
    title = `${getTitle(device.name, position.startTime, data.name)} - ${getTitle(device.name, position.endTime, data.name)}`;
  }
  return {
    id: position.positionId,
    title,
    course: 0,
    icon: data.icon,
    type: data.name,
    name: device.name,
    deviceCategory: getCategory(device),
    group: groups[device.groupId]?.name,
    deviceId: position.deviceId,
    positionId: position.id,
    duration: position.duration,
    startTime: position.startTime,
    endTime: position.endTime,
    latitude: position.latitude,
    longitude: position.longitude,
    coordinates: `${Number(position.latitude).toFixed(5)}, ${Number(position.longitude).toFixed(5)}`,
    address: position.address,
  };
};

/**
 * Собирает новый объект из переданных аргументов в объект позиций устройства. Эти свойства диктуют какие свойства отображать на информационной панели и не только.
 */
export const getPositionExtraProperties = (position, device, data, groups) => {
  const cellTowers = position.network?.cellTowers;
  const cellTowersData = {};
  if (cellTowers?.length) {
    cellTowersData.lacCids = cellTowers.map((tower) => `${tower.locationAreaCode}-${tower.cellId}`);
    [cellTowersData.bs] = cellTowersData.lacCids;
    cellTowersData.cid = cellTowers.length > 1 && cellTowers.slice(1).map((tower) => (tower.cellId)).join(',');
    [cellTowersData.operator] = cellTowers
      .map((tower, index) => (index === 0 && `${tower.mobileCountryCode}-${tower.mobileNetworkCode}`));
  }
  return {
    ...cellTowersData,
    type: data.name,
    startPoint: data.name === 'startPoint',
    finishPoint: data.name === 'finishPoint',
    replayPosition: data.type === 'replay' || data.type === 'extra',
    group: groups[device.groupId]?.name,
    name: device.name,
    deviceCategory: getCategory(device),
    status: data.type !== 'replay' && device.status,
    icon: device.icon || 'default',
    color: deviceColor(device),
    deviceId: position.deviceId,
    positionId: position.id,
    fixTime: position.fixTime,
    serverTime: position.serverTime,
    latitude: position.latitude,
    longitude: position.longitude,
    altitude: position.altitude,
    coordinates: `${Number(position.latitude).toFixed(5)}, ${Number(position.longitude).toFixed(5)}`,
    address: position.address,
    speed: position.speed,
    batteryLevel: position.attributes.batteryLevel,
    sat: position.attributes.sat !== 99 && position.attributes.sat,
    power: position.attributes.powerLine,
    motion: position.attributes.motion,
    protocol: position.protocol,
    message: position.attributes.message,
    accuracy: position.accuracy !== 99 && Math.round(position.accuracy),
    extraAttributes: position.attributes.extraAttributes,
    categoryParams: position.attributes.categoryParams,
  };
};
