import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import ruMessages from 'devextreme/localization/messages/ru.json';
import 'devextreme/dist/css/dx.light.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { locale, loadMessages } from 'devextreme/localization';
import { useDispatch, useSelector } from 'react-redux';
import SocketController from './SocketController';
import NotificationSyncController from './NotificationSyncController';
import CachingController from './CachingController';
import theme from './common/theme';
import { LocalizationProvider } from './common/components/LocalizationProvider';
import { sessionActions } from './store';
import Navigation from './Navigation';
import localStorageChecker from './common/utils/localStorageChecker';
import InsideVariablesController from './InsideVariablesController';
// import ExeptionSnackbar from './common/components/ExeptionSnackbar';

// test
const App = () => {
  const authenticated = useSelector((state) => !!state.session.server && !!state.session.user);
  const dispatch = useDispatch();
  const isService = useSelector((state) => state.session.user?.service);

  loadMessages(ruMessages);
  locale(navigator.language);

  useEffect(() => {
    if (authenticated) {
      dispatch(sessionActions.updateInitialized(true));
    } else {
      dispatch(sessionActions.updateInitialized(false));
    }
  }, [authenticated]);

  useEffect(() => {
    /**
     * Проверяет содержимое localStorage. При несовпадении с конфигурационными данными - удаляет значение.
     * При добавлении нового ключа в localStorage необходимо добавить этот ключ в конфигурационный словарь
     * localStorageChecker.
     */
    Object.entries(localStorage).forEach((entry) => {
      const [key, value] = entry;
      if (localStorageChecker.hasOwnProperty(key)) {
        let parseValue;
        try {
          parseValue = JSON.parse(value);
        } catch (SyntaxError) {
          parseValue = value;
        }
        if (localStorageChecker[key](parseValue)) {
          return;
        }
      }
      localStorage.removeItem(key);
    });
  }, []);

  if (isService) {
    return null;
  }

  return (
    <LocalizationProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SocketController />
        <CachingController />
        <NotificationSyncController />
        <InsideVariablesController />
        {/* <ExeptionSnackbar /> */}
        <Navigation />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
