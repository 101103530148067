/* eslint-disable indent */
/* eslint-disable object-curly-newline */
import React from 'react';
import { TemporaryCellRender } from '../../columns/cells';
import { TemporaryHeaderRender } from '../../columns/headers';
import { getStringFromBoolean } from '../../funcs/utils';
import detectedTablesSharedFields from './detectedsSharedColumns';
import presetsColumns from '../shared/presetsColumns';
import baseProperties from '../shared/baseProperties';
import { TEMPORARY_PROPERTY } from '../../constants';
import { toFixedWithConvertToNumber, tryConvertAndFixedNumber } from '../../funcs/calculateCellValue';

export default ({
  deviceListClasses, now, handleTemporaryStatusChangeFromHeader,
  handleTemporaryStatusChange, selectedObjects, disableSpeedColumn,
  disableCoordinateColumn, actionMenu, t, theme,
  innerClasses, statusCheckboxChecker,
}) => [
    ...detectedTablesSharedFields.calculateValues.shared({ innerClasses, actionMenu, selectedObjects, t, deviceListClasses, now, theme }),
    {
      dataField: 'temporary',
      dataType: 'string',
      caption: 'deviceTemporary',
      width: 180,
      headerCellComponent: () => <TemporaryHeaderRender handleTemporaryStatusChangeFromHeader={handleTemporaryStatusChangeFromHeader} innerClasses={innerClasses} statusCheckboxChecker={statusCheckboxChecker} t={t} />,
      cellComponent: ({ data }) => <TemporaryCellRender dataset={data} handleTemporaryStatusChange={handleTemporaryStatusChange} innerClasses={innerClasses} t={t} />,
      calculateCellValue: (item) => getStringFromBoolean(item.temporary ?? false, t),
    },
    {
      ...presetsColumns.base.sourceId,
      ...baseProperties,
      dataField: TEMPORARY_PROPERTY.SourceId,
    },
    {
      ...presetsColumns.common.attributes.categoryParams.elevationAngle,
      dataField: TEMPORARY_PROPERTY.CategoryParams_elevation_angle,
    },
    {
      ...presetsColumns.common.attributes.categoryParams.sector,
      ...baseProperties,
      dataField: TEMPORARY_PROPERTY.CategoryParams_sector,
    },
    {
      ...presetsColumns.common.attributes.categoryParams.centerFrequency,
      ...baseProperties,
      dataField: TEMPORARY_PROPERTY.CategoryParams_center_frequency_hz,
    },
    {
      ...presetsColumns.common.attributes.categoryParams.bandwidth,
      ...baseProperties,
      dataField: TEMPORARY_PROPERTY.CategoryParams_bandwidth_hz,
    },
    {
      ...presetsColumns.common.attributes.categoryParams.averageSnr,
      ...baseProperties,
      dataField: TEMPORARY_PROPERTY.CategoryParams_average_snr_db,
      calculateCellValue: (item) => toFixedWithConvertToNumber(item[TEMPORARY_PROPERTY.CategoryParams_average_snr_db]),
    },
    {
      ...presetsColumns.common.attributes.categoryParams.comment,
      ...baseProperties,
      dataField: TEMPORARY_PROPERTY.CategoryParams_comment_string,
    },
    {
      ...presetsColumns.common.attributes.categoryParams.dist,
      ...baseProperties,
      dataField: TEMPORARY_PROPERTY.CategoryParams_dist,
    },
    {
      ...presetsColumns.common.attributes.categoryParams.zone,
      ...baseProperties,
    },
    {
      ...presetsColumns.common.speed,
      dataField: TEMPORARY_PROPERTY.Speed,
      width: 100,
      visible: !disableSpeedColumn,
      calculateCellValue: (item) => toFixedWithConvertToNumber(item[TEMPORARY_PROPERTY.Speed]),
    },
    {
      ...presetsColumns.common.latitude({}),
      ...baseProperties,
      dataField: TEMPORARY_PROPERTY.Latitude,
      visible: !disableCoordinateColumn,
      calculateCellValue: (item) => toFixedWithConvertToNumber(item[TEMPORARY_PROPERTY.Latitude]),
    },
    {
      ...presetsColumns.common.longitude({}),
      ...baseProperties,
      dataField: TEMPORARY_PROPERTY.Longitude,
      visible: !disableCoordinateColumn,
      calculateCellValue: (item) => toFixedWithConvertToNumber(item[TEMPORARY_PROPERTY.Longitude]),
    },
    {
      ...presetsColumns.common.altitude,
      dataField: TEMPORARY_PROPERTY.CategoryParams_height,
    },
    {
      ...presetsColumns.common.attributes.categoryParams.peleng,
      ...baseProperties,
      dataField: TEMPORARY_PROPERTY.CategoryParams_peleng,
      calculateCellValue: (item) => (item[TEMPORARY_PROPERTY.CategoryParams_peleng] ? tryConvertAndFixedNumber(item[TEMPORARY_PROPERTY.CategoryParams_peleng]) : ''),
    },
    {
      ...presetsColumns.common.course,
      ...baseProperties,
      dataField: TEMPORARY_PROPERTY.Course,
      calculateCellValue: (item) => {
        if (item[TEMPORARY_PROPERTY.Course] === -1) {
          return '';
        }
        return tryConvertAndFixedNumber(item[TEMPORARY_PROPERTY.Course]);
      },
    },
  ];
