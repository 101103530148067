import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, TextField, Typography, makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { isValid } from '../../../common/utils/formatter';
import CoordinatesBlock from '../../../common/components/CoordinatesBlock';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  adornmentButton: {
    padding: 0,
  },
}));

const MapBlock = ({
  item, setItem, zoomCenter, setZoomCenter, errors, setErrors,
}) => {
  const t = useTranslation();
  const classes = useStyles();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('sharedMap')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Typography variant="body2" style={{ textAlign: 'center' }}>
          {t('serverCenterTitle')}
        </Typography>
        <CoordinatesBlock
          latitude={zoomCenter.latitude}
          longitude={zoomCenter.longitude}
          setLatitude={(latitude) => setZoomCenter({ ...zoomCenter, latitude })}
          setLongitude={(longitude) => setZoomCenter({ ...zoomCenter, longitude })}
          errors={errors}
          setErrors={setErrors}
        />
        <TextField
          label={t('serverZommTitle')}
          margin="normal"
          value={zoomCenter.zoom}
          onChange={(event) => setZoomCenter({ ...zoomCenter, zoom: event.target.value })}
          error={!isValid('zoom', zoomCenter.zoom)}
          helperText={!isValid('zoom', zoomCenter.zoom) && t('ValidateErrorMsg')}
          variant="filled"
        />
        <TextField
          label={t('mapCustomLabel')}
          margin="normal"
          value={item.mapUrl || ''}
          onChange={(event) => setItem({ ...item, mapUrl: event.target.value })}
          variant="filled"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default MapBlock;
