import { useEffect, useRef } from 'react';
import { map } from './Map';

const MapCamera = ({
  latitude, longitude, deviceId
}) => {
  const prevDeviceIdRef = useRef();

  useEffect(() => {
    if (prevDeviceIdRef.current !== deviceId) {
      map.easeTo({
        center: [longitude, latitude],
      });
      prevDeviceIdRef.current = deviceId;
    }
  }, [latitude, longitude, deviceId]);

  return null;
};

export default MapCamera;
