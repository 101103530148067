export default class FullscreenControl {
  constructor() {
    this.onAdd = (map) => {
      this.map = map;
      this.container = document.createElement('div');
      this.container.className = 'maplibregl-ctrl maplibregl-ctrl-group';

      const button = document.createElement('button');
      button.classList.add('maplibregl-style-fullScreen');

      const exitFullScreenHandler = () => {
        if (!document.webkitIsFullScreen) {
          button.classList.add('maplibregl-style-fullScreen');
          button.classList.remove('maplibregl-style-exitFullScreen');
        }
      };
      button.addEventListener('click', () => {
        if (!document.webkitIsFullScreen) {
          button.classList.remove('maplibregl-style-fullScreen');
          button.classList.add('maplibregl-style-exitFullScreen');

          document.addEventListener('fullscreenchange', exitFullScreenHandler);
          document.documentElement.requestFullscreen();
        } else {
          document.exitFullscreen();
          button.classList.add('maplibregl-style-fullScreen');
          button.classList.remove('maplibregl-style-exitFullScreen');
          document.removeEventListener('fullscreenchange', exitFullScreenHandler);
        }
      });

      this.container.appendChild(button);
      return this.container;
    };

    this.onRemove = () => {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    };
  }
}
