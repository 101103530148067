import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Paper, Button, ButtonGroup, Switch, Tooltip,
  ListItemIcon,
  Checkbox,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import SettingsIcon from '@material-ui/icons/Settings';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useDispatch, useSelector } from 'react-redux';
import {
  MoreVert, ReorderOutlined, Visibility, VisibilityOff,
} from '@material-ui/icons';
import {
  devicesActions, devicesPanelActions, mapActions, tailActions,
} from '../../../store';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { useStylesSortingMenu } from '../MainPage/MainPage.styles';
import { filterBySearchName, filterByCategories } from '../filterDevices';
import lifetimes from '../../../common/static/lifetimes';
import { getCategory } from '../../../common/utils/formatter';
import theme from '../../../common/theme';
import { HEADER_MENU_SETTINGS } from './VirtualListDevices/constants';
import PeriodModal from './PeriodModal';
import GroupsModal from './GroupsModal';

const columns = [
  'name',
  'group',
  'icon',
  'status',
  'lastUpdate',
  'batteryPercent',
  'batteryPic',
  'switch',
  'editButton',
];

const SortingMenu = ({
  sortValue,
  setSortValue,
  sortReverseValue,
  setSortReverseValue,
  searchName,
  panel,
  setPanel,
}) => {
  const classes = useStylesSortingMenu();
  const t = useTranslation();
  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const [selectedAnchorElTool, setSelectedAnchorElTool] = useState(null);
  const userId = useSelector((state) => state.session.user.id);
  const devices = useSelector((state) => state.devices.items);
  const deviceToolOff = useSelector((state) => state.map.deviceToolOff);
  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const stateSyncClosedDevices = useSelector((state) => state.devices.stateSyncClosedDevices);
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.devices.categories);
  const groups = useSelector((state) => state.groups.items);
  const categoryLifetimes = useSelector((state) => state.session.server.attributes.categoryLifetime ?? lifetimes.categories);
  const temporaryLifetime = useSelector((state) => state.session.server.attributes.temporaryLifetime ?? lifetimes.temporary);
  const [allOff, setAllOff] = useState(!!Object.keys(closedDevices).filter((deviceId) => closedDevices[deviceId]).length);

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const anchorElForMenu = useRef(undefined);
  const positionsForMenu = useRef({ top: 0, left: 0 });
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const tail = useSelector((state) => state.tail.tail);
  const [textButton, setTextButton] = useState(panel.onTextCurrent);

  const [periodModalOpen, setPeriodModalOpen] = useState(false);
  const [groupsModalOpen, setGroupsModalOpen] = useState(false);

  const includeProperties = (property) => {
    let updatedOff;
    if (deviceToolOff.includes(property)) {
      updatedOff = deviceToolOff.filter((item) => item !== property);
    } else {
      updatedOff = [...deviceToolOff, property];
    }
    dispatch(mapActions.updateDeviceToolOff(updatedOff));
  };

  const menuHide = () => {
    setSelectedAnchorEl(null);
  };

  const menuShow = (anchorId) => {
    setSelectedAnchorEl(anchorId);
  };

  const handleSelection = (value) => {
    setSortValue(sortReverseValue !== value ? value : `${value}Reverse`);
    setSortReverseValue(sortReverseValue !== value && value);
  };

  const turnOnDevices = (on) => {
    const result = { ...closedDevices };
    const tailQueue = {};
    if (on) {
      Object.keys(result).forEach((deviceId) => {
        if (result[deviceId] === false) {
          tailQueue[deviceId] = false;
        }
        result[deviceId] = true;
      });

      setAllOff(true);
    } else {
      Object.keys(result).forEach((deviceId) => {
        if (result[deviceId] === false) {
          tailQueue[deviceId] = true;
        }
        result[deviceId] = false;
      });
      Object.values(devices)
        .filter((device) => (filterBySearchName(device, groups, searchName)
          && filterByCategories(device, categories[getCategory(device)], categoryLifetimes, temporaryLifetime)))
        .forEach((device) => {
          if (result[device.id] === true) {
            tailQueue[device.id] = true;
          }
          result[device.id] = false;
        });

      setAllOff(false);
    }
    dispatch(devicesActions.turnAll(result));
    dispatch(tailActions.changeState(tailQueue));
  };

  const handleClickChangeModeTitle = (event) => {
    setSelectedAnchorElTool(event.currentTarget);
  };

  const handleClickOpenReorderingMode = () => {
    dispatch(devicesPanelActions.change(true));
  };

  const handleClickOpenSortTitle = (event) => {
    menuShow(event.currentTarget);
  };

  const handleClickOpenMenu = (event) => {
    setTextButton(JSON.parse(localStorage.getItem('panel')).onTextCurrent);
    setIsOpenMenu(true);
    try {
      const y = isTablet ? HEADER_MENU_SETTINGS.Y.Tablet : HEADER_MENU_SETTINGS.Y.Full;
      const x = isTablet ? HEADER_MENU_SETTINGS.X.Tablet : HEADER_MENU_SETTINGS.X.Full;
      positionsForMenu.current = {
        top: event.clientY - y,
        left: event.clientX - x,
      };
      setIsOpenMenu(true);
    } catch (error) {
      console.warn('Ошибка при получении позиции для меню заголовка.', error);
    }
  };

  const handleClickCloseMenu = () => {
    setIsOpenMenu(false);
  };

  const handleClickTurnOnDevices = () => {
    turnOnDevices(!allOff);
    handleClickCloseMenu();
  };

  function getCheckboxStatus(val) {
    let lcItem = localStorage.getItem(val);

    if (lcItem && lcItem.trim() !== '') {
      lcItem = JSON.parse(lcItem);

      let allTrue;
      let allFalse;

      if (val === 'closedGroups') {
        allTrue = Object.values(lcItem).every((category) => Object.values(category).every((status) => status === true));
        allFalse = Object.values(lcItem).every((category) => Object.values(category).every((status) => status === false));
      } else {
        allTrue = Object.values(lcItem).every((value) => value === true);
        allFalse = Object.values(lcItem).every((value) => value === false);
      }

      if (allTrue) {
        return {
          status: false, // Все скрыты
          color: '',
        };
      }
      if (allFalse) {
        return { // Все есть
          status: true,
          color: '#4CAF50',
        };
      }
      return { // Частично
        status: true,
        color: '#e37c1b',
      };
    }
    return {
      status: true,
      color: '#4CAF50',
    };
  }

  const [newClosed, setNewClosed] = useState(!getCheckboxStatus('closeNewDevices').status);

  const handleClickTurnOnNewDevices = () => {
    const closeNewDevices = {};
    for (const key in categories) {
      if (categories.hasOwnProperty(key)) {
        closeNewDevices[key] = !newClosed;
      }
    }
    localStorage.setItem('closeNewDevices', JSON.stringify(closeNewDevices));
    setNewClosed(!newClosed);
    handleClickCloseMenu();
  };

  const handleClickTextButton = (val) => {
    setTextButton(val);
    setPanel({ ...panel, onTextCurrent: val });
    handleClickCloseMenu();
  };

  const handleClickTailButton = (val) => {
    dispatch(tailActions.turnTail(val));
    handleClickCloseMenu();
  };

  const handlePeriodOpenModal = (mode) => {
    setPeriodModalOpen(mode);
    handleClickCloseMenu();
  };

  const handleGroupsOpenModal = () => {
    setGroupsModalOpen(true);
    handleClickCloseMenu();
  };

  const [selectedGroups, setSelectedGroups] = useState(false);

  useEffect(() => {
    fetch(`/api/groups?userId=${userId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Ошибка сети');
        }
        return response.json();
      })
      .then((data) => {
        setSelectedGroups(data);
      })
      .catch((error) => {
        console.error('Ошибка запроса:', error);
      });
  }, []);

  useEffect(() => {
    const deviceClosedCount = Object.keys(closedDevices).filter((deviceId) => closedDevices[deviceId]).length;
    if (deviceClosedCount === Object.keys(devices).length) {
      setAllOff(true);
    } else if (deviceClosedCount === 0) {
      setAllOff(false);
    }
  }, [stateSyncClosedDevices, Object.keys(devices).length]);

  return (
    <>
      <Paper square elevation={3} className={classes.container}>
        <ButtonGroup size="small" variant="text" className={classes.buttonGroup} fullWidth>
          <Tooltip title={t('changeModeTitle')}>
            <Button className={classes.button} onClick={handleClickChangeModeTitle}>
              <SettingsIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t('lazyDevicesListDeviceReorderingMode')}>
            <Button className={classes.button} onClick={handleClickOpenReorderingMode}>
              <ReorderOutlined />
            </Button>
          </Tooltip>
          <Tooltip title={t('sortTitle')}>
            <Button className={classes.button} onClick={handleClickOpenSortTitle}>
              <SortIcon />
            </Button>
          </Tooltip>
          <Button className={classes.button} onClick={handleClickOpenMenu}>
            <MoreVert />
          </Button>
        </ButtonGroup>
        <Menu
          open={!!selectedAnchorElTool}
          anchorEl={selectedAnchorElTool}
          onClose={() => setSelectedAnchorElTool(null)}
          className={classes.menuEditRemove}
        >
          {columns && columns.map((column) => (
            <MenuItem key={column} onClick={() => includeProperties(column)}>
              <Switch checked={!deviceToolOff.includes(column)} />
              {t(`${column}ToolName`)}
            </MenuItem>
          ))}
        </Menu>
        <Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide} className={classes.menuEditRemove}>
          <MenuItem
            onClick={() => handleSelection('sortByName')}
            className={`${sortValue.replace('Reverse', '') === 'sortByName' && classes.activeItem}`}
          >
            {t('sortByName')}
          </MenuItem>
          <MenuItem
            onClick={() => handleSelection('sortByGroup')}
            className={`${sortValue.replace('Reverse', '') === 'sortByGroup' && classes.activeItem}`}
          >
            {t('sortByGroup')}
          </MenuItem>
          <MenuItem
            onClick={() => handleSelection('sortByStatus')}
            className={`${sortValue.replace('Reverse', '') === 'sortByStatus' && classes.activeItem}`}
          >
            {t('sortByStatus')}
          </MenuItem>
          <MenuItem
            onClick={() => handleSelection('sortByLastUpdate')}
            className={`${sortValue.replace('Reverse', '') === 'sortByLastUpdate' && classes.activeItem}`}
          >
            {t('sortByLastUpdate')}
          </MenuItem>
        </Menu>
      </Paper>

      {useMemo(() => (
        <div ref={anchorElForMenu}>
          <Menu
            open={isOpenMenu}
            anchorEl={anchorElForMenu.current}
            className={classes.menuEditRemove}
            style={{ ...positionsForMenu.current }}
            onClose={handleClickCloseMenu}
          >
            <MenuItem key={1} style={{ paddingTop: '0px', paddingBottom: '0px' }} button onClick={handleClickTurnOnDevices}>
              <ListItemIcon>
                <Checkbox style={{ color: getCheckboxStatus('closedDevices').color }} checked={getCheckboxStatus('closedDevices').status} />
              </ListItemIcon>
              <Typography>
                {t('allShowDevicesOnMap')}
              </Typography>
            </MenuItem>

            <MenuItem key={2} style={{ paddingTop: '0px', paddingBottom: '0px' }} button onClick={handleClickTurnOnNewDevices}>
              <ListItemIcon>
                <Checkbox style={{ color: getCheckboxStatus('closeNewDevices').color }} checked={getCheckboxStatus('closeNewDevices').status} />
              </ListItemIcon>
              <Typography>
                {t('allShowNewDevicesOnMap')}
              </Typography>
            </MenuItem>

            <MenuItem key={3} style={{ paddingTop: '0px', paddingBottom: '0px' }} button onClick={handleGroupsOpenModal}>
              <ListItemIcon style={{
                width: '42px', height: '42px', paddingLeft: '12px', alignItems: 'center', color: getCheckboxStatus('closedGroups').color,
              }}
              >
                <GroupWorkIcon />
              </ListItemIcon>
              <Typography>
                {t('allGroupsOnMap')}
              </Typography>
            </MenuItem>

            <MenuItem key={4} style={{ paddingTop: '0px', paddingBottom: '0px' }} button onClick={() => { handlePeriodOpenModal('show'); }}>
              <ListItemIcon style={{
                width: '42px', height: '42px', paddingLeft: '12px', alignItems: 'center',
              }}
              >
                <Visibility />
              </ListItemIcon>
              <Typography>
                {t('showForPeriodOnMap')}
              </Typography>
            </MenuItem>

            <MenuItem key={5} style={{ paddingTop: '0px', paddingBottom: '0px' }} button onClick={() => { handlePeriodOpenModal('hide'); }}>
              <ListItemIcon style={{
                width: '42px', height: '42px', paddingLeft: '12px', alignItems: 'center',
              }}
              >
                <VisibilityOff />
              </ListItemIcon>
              <Typography>
                {t('hideForPeriodOnMap')}
              </Typography>
            </MenuItem>

            <MenuItem style={{ paddingTop: 0, paddingBottom: 0 }} key={6}>
              <ButtonGroup fullWidth variant="outlined" size="small">
                <Button size="small" style={textButton === 1 ? { backgroundColor: '#deefd8' } : {}} onClick={() => handleClickTextButton(1)}>
                  <TextFieldsIcon style={{ color: '#198cff' }} size="small" />
                </Button>
                <Button size="small" style={textButton === 0 ? { backgroundColor: '#deefd8' } : {}} onClick={() => handleClickTextButton(0)}>
                  <TextFieldsIcon size="small" />
                </Button>
              </ButtonGroup>
            </MenuItem>

            <MenuItem style={{ paddingTop: 0, paddingBottom: 0 }} key={7}>
              <ButtonGroup fullWidth variant="outlined" size="small">
                <Button size="small" style={tail === true ? { backgroundColor: '#deefd8' } : {}} onClick={() => handleClickTailButton(true)}>
                  <img
                    src="/images/icon/switch/tail-1.svg"
                    alt=""
                  />
                </Button>
                <Button size="small" style={tail === false ? { backgroundColor: '#deefd8' } : {}} onClick={() => handleClickTailButton(false)}>
                  <img
                    src="/images/icon/switch/tail-off.svg"
                    alt=""
                  />
                </Button>
              </ButtonGroup>
            </MenuItem>
          </Menu>
        </div>
      ), [isOpenMenu])}

      {useMemo(() => (
        periodModalOpen
          ? <PeriodModal modalOpen={periodModalOpen} setModalOpen={setPeriodModalOpen} />
          : <></>
      ), [periodModalOpen])}

      {useMemo(() => (
        groupsModalOpen
          ? <GroupsModal modalOpen={groupsModalOpen} setModalOpen={setGroupsModalOpen} groups={selectedGroups} />
          : <></>
      ), [groupsModalOpen])}
    </>
  );
};

export default SortingMenu;
