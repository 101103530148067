import React, { useEffect, useState } from 'react';
import {
  Avatar, Grid, makeStyles, Paper, Slide, Tooltip, useMediaQuery, useTheme,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../LocalizationProvider';
import { toTitleCase } from '../../utils/formatter';
import ClusterIcon from './icons/ClusterIcon';
import TextIcon from './icons/TextIcon';
import ComponentIcon from './icons/CommonIcon';
import items from './items';
import ComponentDrawer from './ComponentDrawer';
import markerStatus from '../../constants/markerStatus';
import ExtraLayersDrawer from './ExtraLayersDrawer';
import { tailActions } from '../../../store';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    right: theme.spacing(1),
    top: theme.spacing(35),
  },
  rootWidth: {
    position: 'fixed',
    right: '399px',
    top: theme.spacing(35),
  },
  checkboxToggle: {
    borderRadius: '10px',
    minWidth: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
    cursor: 'pointer',
    display: 'inline-block',
  },
  toggleClusters: {
    borderRadius: '90px',
    minWidth: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
    transform: 'scale(0.7)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(220,220,220,0.8)',
    },
  },
  extra: {
    marginBottom: theme.spacing(10),
  },
  avatar: {
    backgroundColor: 'rgba(255,255,255,0)',
  },
  progress: {
    margin: theme.spacing(1, 1, 0.2, 1),
  },
  notAllowed: {
    cursor: 'not-allowed',
  },
}));

const ComponentButtons = ({
  showOnly, mainPage, panel, setPanel, setButtonsDrawerOpen, buttonsDrawerOpen, needMoveMap,
  setAddMarkerMode, addMarkerMode, rulerMode, setRulerMode, markerState, setOpacityMapsOpen,
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const item = useSelector((state) => state.tail.tail);
  const [onMouseEnter, setOnMouseEnter] = useState('');
  const [singleClusters, setSingleClusters] = useState(false);
  const [singleClustersIn, setSingleClustersIn] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const towerIsActive = useSelector((state) => state.bs.isActive);
  const [onChildren, setOnChildren] = useState(null);
  const [onChildrenIn, setOnChildrenIn] = useState(false);

  const [extraLayersDrawerOpen, setExtraLayersDrawerOpen] = useState(false);

  const turnOnValue = (name, mode = 1) => {
    setPanel({ ...panel, [name]: panel[name] === mode ? 0 : mode });
  };

  const singleClustersHandle = () => {
    setSingleClustersIn(!singleClustersIn);
    if (singleClusters) {
      setTimeout(() => setSingleClusters(false), 250);
    } else {
      setSingleClusters(!singleClusters);
    }
  };

  const markerHandle = () => {
    markerState.current = { ...markerState.current, actionType: markerStatus.Create };
    setAddMarkerMode(!addMarkerMode);
  };

  const rulerHandle = () => {
    setRulerMode(!rulerMode);
  };

  const switchHandle = (value) => {
    if (onChildren === value) {
      setOnChildrenIn(false);
      setTimeout(() => setOnChildren(null), 250);
    } else {
      setOnChildrenIn(true);
      setOnChildren(value);
    }
  };

  const layersHandle = () => {
    setButtonsDrawerOpen(true);
  };

  const clickHandler = (value) => {
    switch (value) {
      case 'opacityMaps':
        setOpacityMapsOpen(true);
        break;
      case 'ruler':
        rulerHandle();
        break;
      case 'tail':
        dispatch(tailActions.turnTail(!item));
        break;
      case 'layers':
        layersHandle();
        break;
      case 'extra':
        singleClustersHandle();
        break;
      case 'markerAdd':
        markerHandle();
        break;
      case 'tower':
      case 'line':
        switchHandle(value);
        break;
      case 'extraLayers':
        setExtraLayersDrawerOpen(true);
        break;
      default:
        turnOnValue(`on${toTitleCase(value)}`);
        break;
    }
  };

  const getBlockVisibleStatus = (value) => {
    switch (value) {
      case 'tail':
        return mainPage;
      case 'extra':
        return !isTablet;
      default:
        return true;
    }
  };

  const getButtonVisibleStatus = (value) => {
    switch (value) {
      case 'tower':
        return towerIsActive;
      case 'bs':
      case 'stops':
      case 'sides':
      case 'positions':
      case 'line':
        return item || showOnly;
      default:
        return true;
    }
  };

  const getClusterName = (button) => `clusters${toTitleCase(button.title)}`;

  const getTextName = (button) => `text${toTitleCase(button.title)}`;

  useEffect(() => {
    if (singleClusters) {
      setOnChildrenIn(false);
      setTimeout(() => setOnChildren(false), 250);
    }
  }, [singleClusters]);

  useEffect(() => {
    if (onChildren) {
      setSingleClustersIn(false);
      setTimeout(() => setSingleClusters(false), 250);
    }
  }, [onChildren]);

  return (
    <>
      <div className={!needMoveMap ? classes.root : classes.rootWidth}>
        <Grid>
          {items.ordering.filter((block) => getBlockVisibleStatus(block))
            .map((block) => (block === 'route' && isTablet ? 'layers' : block))
            .map((block) => (
              <Grid container item direction="column" spacing={2} key={block}>
                <Grid item>
                  <Paper className={classes.checkboxToggle} elevation={3}>
                    <Grid container direction="column">
                      {items.buttons[block].filter((button) => getButtonVisibleStatus(button.title)).map((button) => (
                        <div key={button.title}>
                          {button.title !== 'extraLayers' && (
                            <Grid item container key={button.title} direction="row-reverse">
                              <Grid item>
                                <Tooltip title={t(`panel${toTitleCase(button.title)}`)}>
                                  <Avatar
                                    onClick={() => clickHandler(button.title)}
                                    className={classes.avatar}
                                    onMouseEnter={() => setOnMouseEnter(button.title)}
                                    onMouseLeave={() => setOnMouseEnter('')}
                                  >
                                    <ComponentIcon
                                      panel={panel}
                                      value={button.title}
                                      onMouseEnter={onMouseEnter}
                                      addMarkerMode={addMarkerMode}
                                      rulerMode={rulerMode}
                                      item={item}
                                    />
                                  </Avatar>
                                </Tooltip>
                              </Grid>
                              {onChildren === button.title && button.childrens.map((child, index) => (
                                <Grid item key={child.title}>
                                  <Paper
                                    className={classes.toggleClusters}
                                    elevation={3}
                                    onMouseEnter={() => setOnMouseEnter(child.title)}
                                    onMouseLeave={() => setOnMouseEnter('')}
                                    onClick={() => turnOnValue(`on${toTitleCase(button.title)}`, child.index)}
                                    style={{ right: 50 * (index + 1), position: 'absolute' }}
                                  >
                                    <Slide direction="left" in={onChildrenIn}>
                                      <Tooltip title={t(`panel${toTitleCase(child.title)}`)}>
                                        <Avatar className={classes.avatar}>
                                          <ComponentIcon
                                            panel={panel}
                                            value={child.title}
                                            onMouseEnter={onMouseEnter}
                                            parrent={button.title}
                                            child={child.index}
                                          />
                                        </Avatar>
                                      </Tooltip>
                                    </Slide>
                                  </Paper>
                                </Grid>
                              ))}
                              {(singleClusters && button.clusters) && (
                                <Grid item>
                                  <Paper
                                    className={classes.toggleClusters}
                                    elevation={3}
                                    onMouseEnter={() => setOnMouseEnter(getClusterName(button))}
                                    onMouseLeave={() => setOnMouseEnter('')}
                                    onClick={() => turnOnValue(`on${toTitleCase(getClusterName(button))}`)}
                                    style={{ right: 50, position: 'absolute' }}
                                  >
                                    <Slide direction="left" in={singleClustersIn}>
                                      <Tooltip title={t(`panel${toTitleCase(getClusterName(button))}`)}>
                                        <Avatar className={classes.avatar}>
                                          <ClusterIcon panel={panel} value={getClusterName(button)} onMouseEnter={onMouseEnter} />
                                        </Avatar>
                                      </Tooltip>
                                    </Slide>
                                  </Paper>
                                </Grid>
                              )}
                              {(singleClusters && button.text) && (
                                <Grid item>
                                  <Paper
                                    className={classes.toggleClusters}
                                    elevation={3}
                                    onClick={() => turnOnValue(`on${toTitleCase(getTextName(button))}`)}
                                    onMouseEnter={() => setOnMouseEnter(getTextName(button))}
                                    onMouseLeave={() => setOnMouseEnter('')}
                                    style={{ right: 100, position: 'absolute' }}
                                  >
                                    <Slide direction="left" in={singleClustersIn}>
                                      <Tooltip title={t(`panel${toTitleCase(getTextName(button))}`)}>
                                        <Avatar className={classes.avatar}>
                                          <TextIcon panel={panel} value={getTextName(button)} onMouseEnter={onMouseEnter} />
                                        </Avatar>
                                      </Tooltip>
                                    </Slide>
                                  </Paper>
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </div>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </div>
      <ExtraLayersDrawer
        buttonsDrawerOpen={extraLayersDrawerOpen}
        setButtonsDrawerOpen={setExtraLayersDrawerOpen}
      />
      {
        isTablet && (
          <ComponentDrawer
            buttonsDrawerOpen={buttonsDrawerOpen}
            setButtonsDrawerOpen={setButtonsDrawerOpen}
            panel={panel}
            setPanel={setPanel}
            turnOnValue={turnOnValue}
            setOnMouseEnter={setOnMouseEnter}
            items={items.buttons.route}
            getButtonVisibleStatus={getButtonVisibleStatus}
            onChildren={onChildren}
            clickHandler={clickHandler}
            getClusterName={getClusterName}
            getTextName={getTextName}
          />
        )
      }
    </>
  );
};

export default ComponentButtons;
