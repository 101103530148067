import positionsTypes from './positionsTypes';

export default [
  'osm',
  't_ort',
  't_pale',
  'o_std',
  'geofences-fill',
  'geofences-line',
  'geofences-title',
  'points-flightRadarAirports',
  'points-flightRadarFlights',
  'accurancy',
  'selectedAccuracy',
  'path-3D-arrows',
  'path_matching-3D-arrows',
  'path-3D',
  'path_matching-3D',
  'path',
  'path_matching',
  'selectedPositionText',
  'selectedPositionRadar',
  'selectedPosition',
  'markradar-radar',
  'markradar',
  'mark',
  'mark_rotatable',
  'mark_non_rotatable',

  'mark-course',
  'markradar-course',
  'markPoint',
  `${positionsTypes.bs.name}-light`,
  positionsTypes.bs.name,
  'mark-clusters',
  'mark-clusters-count',

  positionsTypes.arrow.name,
  `${positionsTypes.arrow.name}_rotatable`,
  `${positionsTypes.arrow.name}_non_rotatable`,

  `${positionsTypes.arrow.name}-clusters`,
  `${positionsTypes.arrow.name}-clusters-count`,

  positionsTypes.stop.name,
  `${positionsTypes.stop.name}_rotatable`,
  `${positionsTypes.stop.name}_non_rotatable`,

  `${positionsTypes.stop.name}-clusters`,
  `${positionsTypes.stop.name}-clusters-count`,

  positionsTypes.startPoint.name,
  `${positionsTypes.startPoint.name}_rotatable`,
  `${positionsTypes.startPoint.name}_non_rotatable`,

  positionsTypes.finishPoint.name,
  `${positionsTypes.finishPoint.name}_rotatable`,
  `${positionsTypes.finishPoint.name}_non_rotatable`,

  `${positionsTypes.startPoint.name}-clusters`,
  `${positionsTypes.finishPoint.name}-clusters`,
  `${positionsTypes.startPoint.name}-clusters-count`,
  `${positionsTypes.finishPoint.name}-clusters-count`,

  `${positionsTypes.current.name}-radar-3D`,
  `${positionsTypes.current.name}-radar-radar-3D`,
  `${positionsTypes.current.name}-alarm-radar-3D`,
  `${positionsTypes.current.name}-radar-sector-3D`,
  `${positionsTypes.current.name}-alarm-sector-3D`,

  `${positionsTypes.current.name}-radar`,
  `${positionsTypes.current.name}-radar-radar`,
  `${positionsTypes.current.name}-radar-sector`,
  `${positionsTypes.current.name}-alarm-radar`,
  `${positionsTypes.current.name}-alarm-sector`,

  positionsTypes.temporary.name,
  `${positionsTypes.temporary.name}_rotatable`,
  `${positionsTypes.temporary.name}_non_rotatable`,

  positionsTypes.current.name,
  `${positionsTypes.current.name}_rotatable`,
  `${positionsTypes.current.name}_non_rotatable`,

  `${positionsTypes.current.name}-course`,
  `${positionsTypes.current.name}-clusters`,
  `${positionsTypes.current.name}-clusters-count`,
  'measure-lines',
  'measure-points',
];
