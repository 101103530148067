import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import maplibregl from 'maplibre-gl';
import { ThemeProvider, useTheme } from '@material-ui/core/styles';
import { Provider, useSelector } from 'react-redux';
import PositionData from './PositionData';
import store from '../../store';
import { map } from '../../map/Map';

const PopupStatusView = ({
  popupData, positionDataVisible, setPositionsBS, setNextPosition, setMoveableDevice, devices,
  countPosition, setCountPosition, nextPosition, setPositionDataVisible, selectedMarkerComponents,
}) => {
  const [popup, setPopup] = useState();
  const theme = useTheme();
  const selectedPositionData = useSelector((state) => state.positions.selectedPositionData);
  const positionsSimilar = useSelector((state) => state.tail.positionsSimilar);

  const popupRef = useRef();
  const positionDataVisibleRef = useRef();

  useEffect(() => {
    popupRef.current = popupData;
  }, [popupData]);

  useEffect(() => {
    positionDataVisibleRef.current = positionDataVisible;
  }, [positionDataVisible]);

  useEffect(() => {
    if (positionDataVisible && popupData && selectedPositionData) {
      if (popup) {
        popup.remove();
      }
      setPopup(new maplibregl.Popup({
        offset: 25,
        anchor: 'top',
        closeButton: false,
      }));
    } else {
      if (popup) {
        popup.remove();
      }
      setPopup(null);
    }
  }, [positionDataVisible, popupData, selectedPositionData]);

  useEffect(() => {
    try {
      if (popup) {
        const placeholder = document.createElement('div');
        ReactDOM.render(
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <PositionData
                setNextPosition={setNextPosition}
                countPosition={countPosition}
                setCountPosition={setCountPosition}
                nextPosition={nextPosition}
                positionsSimilar={positionsSimilar}
                popup={popup}
                setPopup={setPopup}
                setPositionDataVisible={setPositionDataVisible}
                setPositionsBS={setPositionsBS}
                selectedMarkerComponents={selectedMarkerComponents}
                setMoveableDevice={setMoveableDevice}
                devices={devices}
              />
            </ThemeProvider>
          </Provider>,
          placeholder,
        );

        popup
          .setDOMContent(placeholder)
          .setLngLat([selectedPositionData.longitude, selectedPositionData.latitude])
          .addTo(map);
      }
    } catch (error) {
      console.warn(error);
    }
  }, [popup]);

  return null;
};

export default PopupStatusView;
