/* eslint-disable no-bitwise */
/* eslint-disable no-new */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Menu, useMediaQuery } from '@material-ui/core';
import { FixedSizeList } from 'react-window';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import {
  devicesActions, devicesPanelActions, positionsActions, tailActions,
} from '../../../../store';
import EditCollectionView from '../../../../EditCollectionView';
import { getDevices } from '../../../../common/utils/selectors';
import { useStylesDeviceList } from '../../MainPage/MainPage.styles';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import VirtualHeaderRow from './VirtualHeaderRow';
import VirtualBodyRow from './VirtualBodyRow';
import useDebounce from '../../../../common/hooks/useDebounce';
import DeviceReorderingMode from './DeviceReorderingMode';
import {
  DELAY_SEARCHING_TIME, DEVICES_BUFFERING_TIME, DEVICE_ROW_HEIGHT, EMPTY, FULL, NOT_FULL, OVERSCAN_COUNT, HANDLERS_OF_DEVICE_LIST_STRUCTURE, HEADER_MENU_SETTINGS,
} from './constants';
import VirtualEmptyRow from './VirtualEmptyRow';
import useBufferedArray from '../../../../common/hooks/useBufferedArray';
import { eventName, notify, sendMessage } from '../../../../NotificationSyncController';
import { getPositionExtraProperties, getPositionProperties } from '../../../../map/funcs/positionSource';
import positionsTypes from '../../../../common/static/positionsTypes';
import { getCategory } from '../../../../common/utils/formatter';
import { isUndefinedOrNull } from '../../../../common/utils/stringUtils';
import PeriodModal from '../PeriodModal';
import GroupsModal from '../GroupsModal';
import { getExtraCoordinatesBSData } from '../../../../map/funcs/propertiesExtra';
import logout from '../../../../common/utils/logout';

const VirtualList = ({
  onMenuClick,
  handleClose,
  onTail,
  sortValue,
  progress,
  searchName,
  selectedAnchorEl,
  panel,
  setPositionsBS,
}) => {
  const classes = useStylesDeviceList();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const t = useTranslation();
  const delaySearching = useDebounce(DELAY_SEARCHING_TIME);
  const history = useHistory();

  const userId = useSelector((state) => state.session.user.id);
  const stateSyncDevices = useSelector((state) => state.devices.stateSyncDevices);
  const stateSyncClosedDevices = useSelector((state) => state.devices.stateSyncClosedDevices);
  const stateSyncItemsParams = useSelector((state) => state.devices.stateSyncItemsParams);
  const stateSyncCurrentPositions = useSelector((state) => state.positions.stateSyncCurrentPositions);
  const devices = useSelector((state) => state.devices.items);
  const user = useSelector((state) => state.session.user);
  const devicesValues = useSelector((state) => Object.values(state.devices.items));
  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const selectedPositionData = useSelector((state) => state.positions.selectedPositionData);
  const groups = useSelector((state) => state.groups.items);
  const chosenDevice = useSelector((state) => state.devices.selectedId);
  const deviceToolOff = useSelector((state) => state.map.deviceToolOff);
  const categories = useSelector((state) => state.devices.categories);
  const devicesParams = useSelector((state) => state.devices.itemsParams);
  const tailSelectedCategories = useSelector((state) => state.tail.selectedCategories);
  const tailSelectedDevices = useSelector((state) => state.tail.selectedDevices);
  const isReorderingMode = useSelector((state) => state.devicesPanel.isReorderingMode);
  const bscoderIsActive = useSelector((state) => state.bs.isActive);
  const needToShowDataPanel = useSelector((state) => state.positions.needToShowDataPanel);
  const positions = useSelector((state) => state.positions.items);
  const trackingModeOnSelect = useSelector((state) => state.session?.user?.attributes?.trackingModeOnSelect);

  const [now, setNow] = useState(new Date());
  const [textWidthClass, setTextWidthClass] = useState();
  const [devicesList, setDevicesList] = useState([]);
  const [isOpenHeaderMenu, setIsOpenHeaderMenu] = useState(false);

  const positionsForHeaderMenu = useRef({ top: 0, left: 0 });
  const contentForHeaderMenu = useRef(undefined);
  const anchorElForHeaderMenu = useRef(undefined);
  const isItemsEmpty = useRef(true);
  const listInnerEl = useRef(null);
  const hiddenDevices = useRef(new Set());
  const monitorUpdateUI = useRef(0);

  const isSearching = searchName?.length > 0;

  const updateVirtualListUI = () => monitorUpdateUI.current = new Date().getTime();

  const getId = (device) => device?.id;
  const getTitle = (device) => device?.title;
  const getInnerCategory = (device) => device?.innerCategory;

  const isNotHidden = (device) => !hiddenDevices.current.has(getId(device));
  const isDeviceEqualsToHeader = (device, deviceHeader) => !getTitle(device) && getInnerCategory(device) === getTitle(deviceHeader);
  const isVisibleDevices = (device) => getTitle(device) || (device.isVisible & isNotHidden(device));
  const isVisibleAndEqualsToHeader = (device, deviceHeader) => isVisibleDevices(device) && isDeviceEqualsToHeader(device, deviceHeader);
  const isTitlesEquals = (device, deviceHeader) => getTitle(device) === getTitle(deviceHeader);

  const handlersManager = (devicesCategories, deviceRow) => {
    try {
      // name: handler[0] - имя заголовка, isMatch: handler[1](deviceRow) - метод для проверки соответствия объекта.
      const matches = HANDLERS_OF_DEVICE_LIST_STRUCTURE.map((handler) => ({ name: handler[0], isMatch: handler[1](deviceRow) }));

      const foundMatch = matches.find((a) => a.isMatch);

      if (foundMatch) {
        devicesCategories[foundMatch.name].push({ ...deviceRow, innerCategory: foundMatch.name });
      } else {
        if (!deviceRow.category || !devicesCategories[deviceRow.category]) return;

        devicesCategories[deviceRow.category].push({ ...deviceRow, innerCategory: deviceRow.category });
      }
    } catch (error) {
      console.warn('Ошибка при обработке значения.', error, deviceRow);
    }
  };

  const defineVisibilityStatus = (deviceHeader, devicesByCategory) => {
    try {
      let closedCategoryDevicesCount = 0;

      const listFilters = devicesByCategory.filter((a) => isDeviceEqualsToHeader(a, deviceHeader));

      listFilters.forEach((device) => {
        if (closedDevices[getId(device)]) {
          closedCategoryDevicesCount += 1;
        }
      });

      if (closedCategoryDevicesCount === 0) {
        deviceHeader.visibilityStatus = FULL;
      } else if (closedCategoryDevicesCount === listFilters.length) {
        deviceHeader.visibilityStatus = EMPTY;
      } else {
        deviceHeader.visibilityStatus = NOT_FULL;
      }

      if (listFilters.length === 0) {
        deviceHeader.visibilityStatus = EMPTY;
      }
    } catch (error) {
      console.warn(`Ошибка при определении статуса видимости у заголовка устройства: ${getTitle(deviceHeader)}.`, error);
    }
  };

  const addSubtitleToDeviceHeader = (deviceHeader, devicesCategories, deviceCategory) => {
    try {
      const totalCount = devicesCategories[deviceCategory].length;
      const visibilityCount = devicesCategories[deviceCategory].filter((d) => !closedDevices[d.id]).length;

      deviceHeader.subtitle = `${visibilityCount}/${totalCount}`;
    } catch (error) {
      console.warn(`Ошибка при добавление подзаголовка с подсчетом количества устройств для ${getTitle(deviceHeader)}.`, error);
    }
  };

  const fillNewDevicesList = (devicesCategories, newDevicesList, devicesArray) => {
    try {
      // Сортировка и формирование объекта с значениями title.
      for (const device of [...Object.values(categories)].sort((a, b) => a.order - b.order)) {
        devicesCategories[getTitle(device)] = [];
      }

      // Обработка строки для определения какому типу заголовка принадлежит устройство.
      // Если условие не совпадает с типом устройства, то берётся тип устройства для заголовка.
      for (const deviceRaw of devicesArray) {
        handlersManager(devicesCategories, deviceRaw);
      }

      // Перебор объекта по ключам, где ключ - это title устройства, а значение - это массив устройств.
      for (const deviceCategory of Object.keys(devicesCategories)) {
        devicesCategories[deviceCategory].sort(getDevices[sortValue]);

        // Добавление заголовка.
        const newCategory = { ...categories[deviceCategory] };
        newCategory.name = deviceCategory;
        const index = newDevicesList.push({ ...newCategory });

        // Добавление после заголовка соответствующие ему устройства.
        for (const device of devicesCategories[deviceCategory]) {
          device.isVisible = categories[deviceCategory].expanded;

          if (getInnerCategory(device) === deviceCategory) {
            newDevicesList.push(device);
          }
        }

        const deviceHeader = newDevicesList[index - 1];

        defineVisibilityStatus(deviceHeader, devicesCategories[deviceCategory]);

        addSubtitleToDeviceHeader(deviceHeader, devicesCategories, deviceCategory);
      }
    } catch (error) {
      console.warn('Ошибка при заполнении структуры устройств.', error);
    }
  };

  /**
   * Возвращает массив заголовков, у которых устройства отсутствуют.
   */
  const getListOfExcludes = (devicesCategories) => {
    const listOfExcludes = [];

    try {
      for (const [key, value] of Object.entries(devicesCategories)) {
        if (value.length === 0) {
          listOfExcludes.push(key);
        }
      }
    } catch (error) {
      console.warn('Ошибка при получении массива заголовков.', error);
    }

    return listOfExcludes;
  };

  const excludeEmptyDeviceHeaders = (devicesCategories, newDevicesList) => {
    try {
      const listOfExcludes = getListOfExcludes(devicesCategories);

      for (const headerName of listOfExcludes) {
        const indexOfHeader = newDevicesList.findIndex((device) => getTitle(device) === headerName);

        if (indexOfHeader >= 0) {
          newDevicesList.splice(indexOfHeader, 1);
        }
      }
    } catch (error) {
      console.warn('Ошибка при удалении пустых заголовков устройств.', error);
    }
  };

  const addEmptyLabelIfRequired = (devicesCategories, newDevicesList) => {
    try {
      // Пропускать добавление надписи, если идет поиск.
      if (isSearching || hiddenDevices.current.size > 0) return;

      for (const category of Object.keys(devicesCategories)) {
        const indexOfHeader = newDevicesList.findIndex((device) => getTitle(device) === category);

        if (indexOfHeader === -1) continue;

        const foundHeader = newDevicesList[indexOfHeader];

        const numberOfVisibleDevices = newDevicesList.filter((device) => isVisibleAndEqualsToHeader(device, foundHeader)).length;

        const isNeedAddLabel = numberOfVisibleDevices === 0 && foundHeader.expanded;
        if (isNeedAddLabel) {
          // Добавляет объект обозначающий, что в списке есть устройства, но время отображения у них вышло.
          // Главное свойство - это isEmpty, нужно для обработки отображения пустой строки.
          newDevicesList.splice(indexOfHeader + 1, 0, { title: 'Empty list', name: 'Empty list', isEmpty: true });
        }
      }
    } catch (error) {
      console.warn('Ошибка при добавление надписи для пустых групп устройств.', error);
    }
  };

  const hideDevicesThatNotMatchSearchName = (devicesCollection) => {
    if (isSearching) {
      for (const device of devicesCollection) {
        if (getTitle(device)) continue;

        const groupName = groups[device.groupId]?.name;
        const isGroupNameIncludesSearchName = groupName ? groupName.toLowerCase().includes(searchName.toLowerCase()) : '';
        const isDeviceNameIncludesSearchName = device.name.toLowerCase().includes(searchName.toLowerCase());

        if (isDeviceNameIncludesSearchName || isGroupNameIncludesSearchName) {
          hiddenDevices.current.delete(getId(device));
        } else {
          hiddenDevices.current.add(getId(device));
        }
      }
    } else {
      hiddenDevices.current.clear();
    }
  };

  /**
   * Пересобираем структуру в единый массив с заголовками и устройствами.
   */
  const createDevicesStructure = (devicesArray) => {
    const devicesCategories = {};
    const newDevicesList = [];

    fillNewDevicesList(devicesCategories, newDevicesList, devicesArray);

    excludeEmptyDeviceHeaders(devicesCategories, newDevicesList);

    addEmptyLabelIfRequired(devicesCategories, newDevicesList);

    hideDevicesThatNotMatchSearchName(newDevicesList);

    return [...newDevicesList];
  };

  const devicesCaching = useBufferedArray((devices) => {
    setDevicesList(() => createDevicesStructure(devices));
  }, DEVICES_BUFFERING_TIME);

  if (listInnerEl.current) {
    listInnerEl.current.className = classes.listInner;
  }

  /**
  * Обновляет и устанавливает оригинальному списку устройств с установкой дескриптора writable в true.
  * @param {*} newList Список, которому нужно переключить дескриптор в true
  */
  const updateDevicesList = (newList) => {
    const updatedList = newList.map((device) => ({ ...device, writable: true }));
    setDevicesList(updatedList);
  };

  // т.к. идет ререндер всего VirtualBodyRow, может подвисать раз в минуту.
  useEffect(() => {
    const dateUpdater = setInterval(() => setNow(new Date()), 60 * 1000);
    return () => clearInterval(dateUpdater);
  }, []);

  // Отслеживание поиска.
  useEffect(() => {
    if (devicesList?.length === 0 || isItemsEmpty.current) return;

    try {
      delaySearching(() => {
        hideDevicesThatNotMatchSearchName(devicesList);
        setDevicesList((prevArray) => [...prevArray]);
      });
    } catch (error) {
      console.warn('Ошибка при поиске.', error);
    }
  }, [searchName]);

  useEffect(() => {
    let count = 0;
    const tools = ['editButton', 'switch', 'batteryPic', 'batteryPercent', 'category'];

    for (let i = 0; i < deviceToolOff.length; i += 1) {
      if (tools.includes(deviceToolOff[i])) {
        count += 1;
      }
    }

    switch (count) {
      case 0:
        setTextWidthClass(classes.itemTextShort);
        break;
      case 1:
        setTextWidthClass(classes.itemTextMiddle);
        break;
      default:
        setTextWidthClass(classes.itemTextLong);
        break;
    }
  }, [deviceToolOff?.length]);

  // Первый рендеринг структуры устройств.
  useEffect(() => {
    if (devicesValues.length > 0 && isItemsEmpty.current) {
      new Promise((res) => {
        setDevicesList(() => createDevicesStructure(devicesValues));
        res();
      }).catch((error) => console.warn('Ошибка при формирование единого списка устройств.', error));

      isItemsEmpty.current = false;
    }
  }, [stateSyncDevices]);

  // Кэширование устройств от reduce в оригинальный список устройств.
  useEffect(() => {
    if (devicesValues.length === 0 && devicesList?.length === 0) return;

    try {
      devicesCaching([...devicesValues]);
    } catch (error) {
      console.warn('Ошибка при передачи списка устройств для хеширования.', error);
    }
  }, [monitorUpdateUI.current, chosenDevice, stateSyncDevices, stateSyncItemsParams, stateSyncCurrentPositions, stateSyncClosedDevices]);

  // Сортировка.
  useEffect(() => {
    setDevicesList(() => createDevicesStructure(devicesValues));
  }, [sortValue]);

  /**
   * Формирует структуру (последовательность заголовка и его устройств) на основе заголовков по их порядку.
   * @param {*} devicesHeaders Заголовки устройств (categories)
   * @param {*} devices Список устройств, которые копируются в новый массив соответствующий заголовку
   * @returns Новый массив с последовательностью заголовков и их устройств соответствующий названию заголовка.
   */
  const createDevicesListStructureOnHeaders = (devicesHeaders, devices) => {
    const newDevicesList = [];

    try {
      devicesHeaders.forEach((deviceHeader) => {
        // Добавление заголовка устройства.
        newDevicesList.push(deviceHeader);

        // Добавление устройства соответствующий заголовку устройства.
        for (const device of devices) {
          if (isDeviceEqualsToHeader(device, deviceHeader)) {
            newDevicesList.push(device);
          }
        }
      });
    } catch (error) {
      console.warn('Ошибка при формирование структуры заголовок по порядку (после изменения порядка).', error);
    }

    return [...newDevicesList];
  };

  const handleMenuHeaderClose = () => {
    setIsOpenHeaderMenu(false);
  };

  const handleOnClickMoreInfo = (event) => {
    try {
      const y = isTablet ? HEADER_MENU_SETTINGS.Y.Tablet : HEADER_MENU_SETTINGS.Y.Full;
      const x = isTablet ? HEADER_MENU_SETTINGS.X.Tablet : HEADER_MENU_SETTINGS.X.Full;
      positionsForHeaderMenu.current = {
        top: event.clientY - y,
        left: event.clientX - x,
      };
      setIsOpenHeaderMenu(true);
    } catch (error) {
      console.warn('Ошибка при получении позиции для меню заголовка.', error);
    }
  };

  // Обработчики RowHeader
  const expandDeviceHeader = (deviceHeader) => {
    new Promise((res) => {
      deviceHeader.expanded = !deviceHeader.expanded;

      dispatch(devicesActions.changeCategory({ ...deviceHeader }));

      for (const device of devicesList) {
        if (isTitlesEquals(device, deviceHeader)) {
          device.expanded = deviceHeader.expanded;
        }
        if (isDeviceEqualsToHeader(device, deviceHeader)) {
          device.isVisible = deviceHeader.expanded;
        }
      }

      res();
    }).catch((error) => console.warn(`Ошибка при переключении свернут/развернут заголовка: ${getTitle(deviceHeader)}.`, error));
  };

  /**
   * Если устройства видны - true, иначе false.
   * @param {*} deviceIds Идентификаторы устройств.
   */
  const isDevicesVisible = (deviceIds) => {
    let isHided = true;

    // При поиске идёт проверка найденных устройств в списке скрытых устройств.
    if (isSearching) {
      let count = 0;

      deviceIds.forEach((id) => {
        if (closedDevices[id]) {
          count++;
        }
      });

      if (count === deviceIds.size) {
        isHided = false;
      }
    }

    return isHided;
  };

  const handleTurnOnDevices = (deviceHeader) => {
    new Promise((res) => {
      const deviceIds = new Set(devicesList
        .filter((device) => isDeviceEqualsToHeader(device, deviceHeader) && isNotHidden(device))
        .map((device) => getId(device)));

      const newClosedDevices = { ...closedDevices };
      const tailQueue = {};

      const isHide = (deviceHeader.visibilityStatus === FULL || deviceHeader.visibilityStatus === NOT_FULL) && isDevicesVisible(deviceIds);

      for (const id of deviceIds) {
        if (newClosedDevices[id] === !isHide) {
          tailQueue[id] = !isHide;
        }
        newClosedDevices[id] = isHide;
      }

      dispatch(devicesActions.turnAll({ ...newClosedDevices }));
      dispatch(tailActions.changeState(tailQueue));
      sendMessage(eventName.CloseAllDevices, newClosedDevices);

      res();
    }).catch((error) => console.warn(`Ошибка при переключении видимости устройств заголовка: ${getTitle(deviceHeader)}.`, error));
  };

  const handleTurnTailCategory = (category, val) => {
    const tailQueue = {};
    const removingDevices = [];
    devicesValues.forEach((item) => {
      if (item.temporary) {
        if (val === false && !tailSelectedDevices[item.id]) {
          removingDevices.push(item.id);
        }
      } else if (category === getCategory(item) && isUndefinedOrNull(tailSelectedDevices[item.id])) {
        if (val === false) {
          tailQueue[item.id] = false;
        } else {
          tailQueue[item.id] = true;
        }
      }
    });
    dispatch(tailActions.changeCategory({ [category]: val }));
    dispatch(tailActions.remove(removingDevices));
    dispatch(tailActions.changeState(tailQueue));
  };

  const handleTurnIconTextCategory = (deviceHeader, val) => {
    deviceHeader.iconText = val;
    dispatch(devicesActions.changeCategory({ ...deviceHeader }));
  };

  const handleTurnIconClusterCategory = (deviceHeader) => {
    deviceHeader.iconCluster = !deviceHeader.iconCluster;
    dispatch(devicesActions.changeCategory({ ...deviceHeader }));
  };

  const handleAcceptReorderingMode = (sortedCategories) => {
    const newDevicesList = createDevicesListStructureOnHeaders(sortedCategories, devicesList);

    Promise.all([
      new Promise((res) => {
        updateDevicesList(newDevicesList);
        res();
      }),
      new Promise((res) => {
        const fromArrayToObject = sortedCategories.reduce((a, dt) => ({ ...a, [dt.title]: dt }), {});
        dispatch(devicesActions.changeCategories(fromArrayToObject));
        res();
      }),
    ]);

    dispatch(devicesPanelActions.change(false));
  };

  const handleCancelReorderingMode = () => {
    dispatch(devicesPanelActions.change(false));
  };

  const handleMouseDown = () => {
    dispatch(devicesPanelActions.change(true));
  };

  const handleMouseUp = () => {
    dispatch(devicesPanelActions.change(false));
  };

  // Обработчики RowBody
  const handleClickByDevice = async (item) => {
    if (positions[item?.id]?.id) {
      setPositionsBS([]);
      const response = await fetch(`/api/positions?id=${positions[item.id].id}`);
      if (response.ok) {
        const position = await response.json();
        const text = 1;
        const properties = getPositionProperties(position[0], devices[item.id], user, t, positionsTypes.current, text);
        try {
          const extraProperties = getPositionExtraProperties(position[0], devices[item.id], positionsTypes.current, groups);
          dispatch(positionsActions.changeTrackingMode(trackingModeOnSelect));
          dispatch(positionsActions.selectPosition({ ...properties, ...extraProperties, fromVirtualListDevice: true }));
          if (bscoderIsActive && panel.onTower && extraProperties.bs) {
            await getExtraCoordinatesBSData(
              extraProperties.lacCids,
              (text) => setPositionsBS(text),
              (error) => {
                console.warn(error);
                logout(history, dispatch);
              },
            );
          }
        } catch (e) {
          console.warn(e);
        }
      }
    }

    if (chosenDevice === item.id) {
      if (!needToShowDataPanel) {
        setPositionsBS([]);
      }
      dispatch(devicesActions.deselect(null));
      dispatch(positionsActions.changeTrackingMode(false));
      dispatch(positionsActions.unselectPosition());
    } else {
      dispatch(devicesActions.select({ id: item.id }));
      if (isTablet) {
        handleClose();
      }
    }
  };

  const handleChangeVisibleDevice = (checked, deviceId) => {
    notify(eventName.CloseDevice, { flag: !checked, id: deviceId });
  };

  const [periodModalOpen, setPeriodModalOpen] = useState(false);
  const [groupsModalOpen, setGroupsModalOpen] = useState(false);
  const [modalsCategory, setModalsCategory] = useState();
  const [selectedGroups, setSelectedGroups] = useState(false);

  const handlePeriodOpenModal = (mode, category) => {
    setPeriodModalOpen(mode);
    setModalsCategory(category);
    handleMenuHeaderClose();
  };

  const handleGroupsOpenModal = (category) => {
    setGroupsModalOpen(true);
    setModalsCategory(category);
    handleMenuHeaderClose();
  };

  const renderRow = ({ index, style, data }) => {
    const device = data.devicesList[index];

    if (device.isEmpty) {
      return (
        <VirtualEmptyRow style={style} classes={classes} />
      );
    }

    if (getTitle(device)) {
      return (
        <VirtualHeaderRow
          updateVirtualListUI={updateVirtualListUI}
          menuHeaderContent={contentForHeaderMenu}
          handleMenuHeaderClose={handleMenuHeaderClose}
          handleOnClickMoreInfo={handleOnClickMoreInfo}
          style={style}
          classes={classes}
          deviceHeader={device}
          expandHandler={expandDeviceHeader}
          turnOnDevices={handleTurnOnDevices}
          turnTail={handleTurnTailCategory}
          tailOn={tailSelectedCategories[getTitle(device)]}
          turnIconText={handleTurnIconTextCategory}
          turnIconCluster={handleTurnIconClusterCategory}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          iconText={categories[getTitle(device)]?.iconText}
          handleGroupsOpenModal={handleGroupsOpenModal}
          handlePeriodOpenModal={handlePeriodOpenModal}
        />
      );
    }

    return (
      <VirtualBodyRow
        style={style}
        device={device}
        chosenDevice={chosenDevice}
        clickItem={handleClickByDevice}
        closedDevices={closedDevices}
        deviceToolOff={deviceToolOff}
        groups={groups}
        now={now}
        progress={progress}
        setNow={setNow}
        handleClose={handleClose}
        changeVisibleDevice={handleChangeVisibleDevice}
        textWidthClass={textWidthClass}
        onMenuClick={onMenuClick}
        onTail={onTail}
        iconText={devicesParams[device.id]?.iconText}
        tailOn={tailSelectedDevices[device.id]}
      />
    );
  };

  const deviceSelectedId = useSelector((state) => state.devices.selectedId);
  const sizeListRef = useRef(null);
  useEffect(() => {
    if (deviceSelectedId) {
      devicesList.filter(isVisibleDevices).forEach((device, ind) => {
        if (device.id === deviceSelectedId) {
          sizeListRef.current.scrollToItem(ind, 'smart');
        }
      });
    }
  }, [deviceSelectedId]);

  useEffect(() => {
    fetch(`/api/groups?userId=${userId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Ошибка сети');
        }
        return response.json();
      })
      .then((data) => {
        setSelectedGroups(data);
      })
      .catch((error) => {
        console.error('Ошибка запроса:', error);
      });
  }, []);

  return (
    <>
      {useMemo(() => (
        <>
          {devicesList.length > 0 ? (
            <AutoSizer className={classes.list}>
              {({ height, width }) => (
                <FixedSizeList
                  ref={sizeListRef}
                  className={classes.containerScrollStyles}
                  height={height}
                  width={width}
                  overscanCount={OVERSCAN_COUNT}
                  itemSize={DEVICE_ROW_HEIGHT}
                  itemCount={devicesList.filter(isVisibleDevices).length}
                  itemData={{ devicesList: devicesList.filter(isVisibleDevices) }}
                >
                  {renderRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          ) : (<div>{t('globalIsEmpty')}</div>)}
        </>
      ), [devicesList, selectedAnchorEl, deviceToolOff, now, selectedPositionData])}

      {useMemo(() => (
        <>
          {isReorderingMode && (
            <DeviceReorderingMode
              categories={devicesList.filter((device) => getTitle(device) && !device.isEmpty)}
              onAccept={handleAcceptReorderingMode}
              onCancel={handleCancelReorderingMode}
            />
          )}
        </>
      ), [isReorderingMode])}

      {useMemo(() => (
        <div ref={anchorElForHeaderMenu}>
          {contentForHeaderMenu.current && (
            <Menu
              open={isOpenHeaderMenu}
              className={classes.menuEditRemove}
              style={{ ...positionsForHeaderMenu.current }}
              anchorEl={anchorElForHeaderMenu.current}
              onClose={handleMenuHeaderClose}
            >
              {contentForHeaderMenu.current()}
            </Menu>
          )}
        </div>
      ), [isOpenHeaderMenu])}

      {useMemo(() => (
        periodModalOpen && modalsCategory
          ? <PeriodModal key="PeriodModal" modalOpen={periodModalOpen} setModalOpen={setPeriodModalOpen} category={modalsCategory} />
          : <></>
      ), [periodModalOpen])}

      {useMemo(() => (
        groupsModalOpen && modalsCategory
          ? <GroupsModal key="PeriodModal" modalOpen={groupsModalOpen} setModalOpen={setGroupsModalOpen} groups={selectedGroups} category={modalsCategory} />
          : <></>
      ), [groupsModalOpen])}
    </>
  );
};

const VirtualListDevices = ({
  handleClose,
  onTail,
  sortValue,
  setCommandsPanelDevice,
  progress,
  searchName,
  panel,
  setPositionsBS,
  setMoveableDevice,
}) => (
  <EditCollectionView
    stickyTop
    calculatePopupPosition
    content={VirtualList}
    editPath="/device"
    endpoint="devices"
    disableAdd
    handleClose={handleClose}
    onTail={onTail}
    sortValue={sortValue}
    setCommandsPanelDevice={setCommandsPanelDevice}
    progress={progress}
    searchName={searchName}
    mainPage
    panel={panel}
    setPositionsBS={setPositionsBS}
    setMoveableDevice={setMoveableDevice}
  />
);

export default VirtualListDevices;
