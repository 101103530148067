import React, { useEffect, useState } from 'react';
import {
  Grid, TextField,
} from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';
import {
  deformatCoordinate, formatCoordinate, getExampleCoordinate, isValidCoordinate,
} from '../utils/formatter';
import { usePreference } from '../utils/preferences';

const CoordinatesBlock = ({
  latitude, longitude, setLatitude, setLongitude, errors, setErrors, dinamicMode,
}) => {
  const t = useTranslation();

  const coordinateFormat = usePreference('coordinateFormat');

  const [currentCoordinates, setCurrentCoordinates] = useState({
    lat: formatCoordinate('latitude', latitude || 0, coordinateFormat),
    lng: formatCoordinate('longitude', longitude || 0, coordinateFormat),
  });

  const handleOnChangeLatitude = (event) => {
    setCurrentCoordinates((prevCoordinates) => ({ ...prevCoordinates, lat: event.target.value }));
    setLatitude(event.target.value);
  };

  const handleOnChangeLength = (event) => {
    setCurrentCoordinates((prevCoordinates) => ({ ...prevCoordinates, lng: event.target.value }));
    setLongitude(event.target.value);
  };

  useEffect(() => {
    if (dinamicMode) {
      setCurrentCoordinates({
        lat: formatCoordinate('latitude', latitude, coordinateFormat),
        lng: formatCoordinate('longitude', longitude, coordinateFormat),
      });
    }
  }, [latitude, longitude, coordinateFormat]);

  useEffect(() => {
    const newErrors = { ...errors };
    if (isValidCoordinate('latitude', currentCoordinates.lat, coordinateFormat)) {
      delete newErrors.latitude;
      setLatitude(deformatCoordinate(currentCoordinates.lat, coordinateFormat));
    } else {
      newErrors.latitude = true;
    }
    if (isValidCoordinate('longitude', currentCoordinates.lng, coordinateFormat)) {
      delete newErrors.longitude;
      setLongitude(deformatCoordinate(currentCoordinates.lng, coordinateFormat));
    } else {
      newErrors.longitude = true;
    }
    setErrors(newErrors);
  }, [currentCoordinates.lat, currentCoordinates.lng, coordinateFormat]);

  return (
    <Grid container spacing={1} style={{ paddingTop: 10 }}>
      <Grid item xs={6}>
        <TextField
          variant="filled"
          value={currentCoordinates.lat}
          label={t('markerLatitude')}
          onChange={handleOnChangeLatitude}
          error={errors?.latitude}
          helperText={errors?.latitude ? `${t('errorLatitude')}. ${t('sharedExample')}: ${getExampleCoordinate('latitude', coordinateFormat)}` : ''}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="filled"
          label={t('markerLongitude')}
          value={currentCoordinates.lng}
          onChange={handleOnChangeLength}
          error={errors?.longitude}
          helperText={errors?.longitude ? `${t('errorLongitude')}. ${t('sharedExample')}: ${getExampleCoordinate('longitude', coordinateFormat)}` : ''}
        />
      </Grid>
    </Grid>
  );
};

export default CoordinatesBlock;
