import React, { useEffect, useState } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import {
  TextField, Menu, IconButton, Box, Typography, Button, FormControl,
} from '@material-ui/core';
import { ColorLens as ColorLensIcon } from '@material-ui/icons';
import { useTranslation } from './LocalizationProvider';

export default function CustomColorPicker({
  color, setColor, label, presetColors,
}) {
  const t = useTranslation();

  const [presetColorsData, setPresetColorsData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [value, setValue] = useState('#000000');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowColorPicker(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowColorPicker(false);
    setValue(color || '#000000');
  };

  const handleColorSelect = (color) => {
    setValue(color);
  };

  const handleSave = () => {
    setColor(value);
    handleClose();
  };

  function getColorCodes(obj) {
    return Object.values(obj);
  }

  useEffect(() => {
    if (presetColors) {
      setPresetColorsData(getColorCodes(presetColors));
    }
  }, [presetColors]);

  useEffect(() => {
    if (color) {
      setValue(color);
    }
  }, [color]);

  return (
    <div>
      <TextField
        label={label}
        value={value}
        margin="normal"
        fullWidth
        onClick={handleClick}
        variant="filled"
        InputProps={{
          endAdornment: (
            <IconButton>
              <ColorLensIcon style={{ color: (color || '#000000') }} />
            </IconButton>
          ),
          style: { padding: 8 },
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={showColorPicker}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: `${anchorEl?.offsetWidth}px`,
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        }}
      >
        <Box width="100%" mb={2}>
          <HexColorPicker color={value} onChange={setValue} style={{ width: '100%', paddingLeft: '5px', paddingRight: '5px' }} />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          p={1}
          bgcolor="background.default"
          borderRadius={4}
        >
          <Typography variant="body2" style={{ fontSize: '16px' }}>#</Typography>
          <HexColorInput
            color={value}
            onChange={setValue}
            style={{
              width: '100%', border: 'none', backgroundColor: '#fafafa', fontSize: '16px',
            }}
          />
        </Box>
        {presetColorsData.length
          && (
          <Box>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="start"
              width="100%"
            >
              {presetColorsData.map((presetColor) => (
                <IconButton
                  key={presetColor}
                  onClick={() => handleColorSelect(presetColor)}
                  style={{
                    width: '36px',
                    height: '36px',
                    borderRadius: '50%',
                    backgroundColor: presetColor,
                    margin: '5px',
                    padding: 0,
                    border: presetColor === value ? '2px solid black' : 'none',
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: '0px 0px 0px 2px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                />
              ))}
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <FormControl fullWidth margin="normal">
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  '& > *': {
                    flexBasis: '33%',
                  },
                }}
                >
                  <Button color="primary" variant="outlined" onClick={handleClose}>
                    {t('sharedCancel')}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSave}
                  >
                    {t('sharedSave')}
                  </Button>
                </div>
              </FormControl>
            </Box>
          </Box>
          )}
      </Menu>
    </div>
  );
}
