import 'maplibre-gl/dist/maplibre-gl.css';
import './fullScreenControler/fullScreenControl.css';
import './switcher/switcher.css';
import './mapExport/css/styles.css';

import maplibregl from 'maplibre-gl';

import React, {
  useRef, useLayoutEffect, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MaplibreExportControl, Size, PageOrientation, Format, DPI,
} from './mapExport/index';

import { SwitcherControl } from './switcher/switcher';
import FullscreenControl from './fullScreenControler/fullScreenControl';
import {
  styleCarto, styleCustom, styleOsm, styleYandex,
} from './mapStyles';
import { useTranslation } from '../common/components/LocalizationProvider';
import { mapActions } from '../store';
import PageProgress from '../common/components/PageProgress';
import './miniMap/MiniMapControl';
import MapContextMenu from './MapContextMenu';

const element = document.createElement('div');
element.style.width = '100%';
element.style.height = '100%';
element.style.boxSizing = 'initial';

export const map = new maplibregl.Map({
  container: element,
  locale: {
    'NavigationControl.ZoomIn': 'Приблизить',
    'NavigationControl.ZoomOut': 'Отдалить',
    'NavigationControl.ResetBearing': 'Повернуть карту',
  },
});

let ready = false;
const readyListeners = new Set();

const addReadyListener = (listener) => {
  readyListeners.add(listener);
  listener(ready);
};

const removeReadyListener = (listener) => {
  readyListeners.delete(listener);
};

const updateReadyValue = (value) => {
  ready = value;
  readyListeners.forEach((listener) => listener(value));
};

const initMap = async () => {
  if (ready) return;
  updateReadyValue(true);
};

let minimapConteiner;
let isMinimapMount = false;

map.addControl(new maplibregl.NavigationControl({
  showCompass: true,
}));

map.addControl(new FullscreenControl());

const switcher = new SwitcherControl(
  () => updateReadyValue(false),
  () => {
    const waiting = () => {
      if (!map.loaded()) {
        setTimeout(waiting, 100);
      } else {
        initMap();
      }
    };
    waiting();
  },
);

map.addControl(switcher);

let mapExportMount = false;

const Map = ({
  children, noFixed, needMoveMap, searchCoords,
}) => {
  const containerEl = useRef(null);
  const t = useTranslation();
  const dispatch = useDispatch();

  const [mapReady, setMapReady] = useState(false);
  const [searchMarker, setSearchMarker] = useState(undefined);

  const selectStyle = useSelector((state) => state.map.selectStyle);

  const mapUrl = useSelector((state) => state.session.server?.mapUrl);
  const mapScheme = useSelector((state) => state.session.server?.map);

  const zoom = useSelector((state) => (state.session.user.zoom === 0 ? state.session.server.zoom : state.session.user.zoom));
  const center = useSelector((state) => [
    ((state.session.user.longitude === 0) && (state.session.user.latitude === 0) ? state.session.server.longitude : state.session.user.longitude),
    ((state.session.user.longitude === 0) && (state.session.user.latitude === 0) ? state.session.server.latitude : state.session.user.latitude)]);
  const init = useSelector((state) => state.map.init);

  const showMinimap = useSelector((state) => state.session.user.attributes.showMinimap);

  useEffect(() => {
    if (searchCoords !== undefined) {
      map.fitBounds(searchCoords.points, { maxZoom: 17 });
      if (searchCoords.point) {
        if (searchMarker !== undefined) {
          searchMarker.remove();
        }
        const marker = new maplibregl.Marker({
          color: '#000000',
        })
          .setLngLat(searchCoords.point)
          .addTo(map);
        setSearchMarker(marker);
      }
    }
  }, [searchCoords]);

  const mapExportLocal = {
    PageSize: t('mapExportPageSize'),
    PageOrientation: t('mapExportPageOrientation'),
    Format: t('mapExportFormat'),
    DPI: t('mapExportDPI'),
    Generate: t('mapExportGenerate'),
  };

  const mapExport = new MaplibreExportControl({
    PageSize: Size.A4,
    PageOrientation: PageOrientation.Landscape,
    Format: Format.PNG,
    DPI: DPI[200],
    Crosshair: true,
    PrintableArea: true,
    Local: mapExportLocal,
  });

  useEffect(() => {
    if (showMinimap) {
      const miniMapInitialZoom = {
        zoom: zoom - 4,
      };
      minimapConteiner = new maplibregl.Minimap(miniMapInitialZoom);
    } else if (minimapConteiner !== undefined) {
      try {
        minimapConteiner.container.remove();
      } catch (error) {
        console.error(error);
      }
    }
  }, [showMinimap]);

  useEffect(() => {
    if (showMinimap) {
      if (selectStyle === 'yandex') {
        minimapConteiner.newStyle(styleYandex());
      }

      if (selectStyle === 'osm') {
        minimapConteiner.newStyle(styleOsm());
      }

      if (selectStyle === 'carto') {
        minimapConteiner.newStyle(styleCarto());
      }

      if (selectStyle === 'custom') {
        minimapConteiner.newStyle(styleCustom(mapUrl, '', mapScheme));
      }

      if (!isMinimapMount) {
        isMinimapMount = true;
        map.addControl(minimapConteiner, 'bottom-right');
      }
    }
  }, [selectStyle]);

  useEffect(() => {
    map.resize();
  }, [needMoveMap]);

  useEffect(() => {
    if (mapReady && !init) {
      map.setZoom(zoom);
      map.setCenter(center);
      dispatch(mapActions.init(true));
    }
  }, [init, zoom, mapReady]);

  useEffect(() => {
    if (!mapExportMount) {
      map.addControl(mapExport);
      mapExportMount = true;
    }
    switcher.updateDispatch(dispatch);
    mapExport.updateDispatch(dispatch);
  }, []);

  useEffect(() => {
    switcher.updateStyles([
      { id: 'yandex', title: t('mapYandexMap'), uri: styleYandex() },
      { id: 'osm', title: t('mapOsm'), uri: styleOsm() },
      { id: 'carto', title: t('mapCarto'), uri: styleCarto() },
      { id: 'custom', title: t('mapCustomLabel'), uri: styleCustom(mapUrl, '', mapScheme) },
    ], localStorage.getItem('selectedMap') || 'yandex');
  }, [mapUrl]);

  useEffect(() => {
    const listener = (ready) => setMapReady(ready);
    addReadyListener(listener);
    return () => {
      removeReadyListener(listener);
    };
  }, []);

  useLayoutEffect(() => {
    const currentEl = containerEl.current;
    currentEl.appendChild(element);
    map.resize();
    return () => {
      currentEl.removeChild(element);
    };
  }, [containerEl]);

  return (
    <>
      <div
        style={{
          width: needMoveMap ? 'calc(100% - 390px)' : '100%',
          height: '100%',
          position: noFixed ? 'static' : 'fixed',
          left: 0,
        }}
        ref={containerEl}
      >
        {mapReady && children}
        <PageProgress />
      </div>
      <MapContextMenu map={map} />
    </>

  );
};

export default Map;
