import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { sessionReducer as session } from './session';
import { devicesReducer as devices } from './devices';
import { groupsReducer as groups } from './groups';
import { positionsReducer as positions } from './positions';
import { geofencesReducer as geofences } from './geofences';
import { mapReducer as map } from './map';
import { smsReducer as sms } from './sms';
import { bsReducer as bs } from './bs';
import { markersReducer as markers } from './markers';
import { unionsReducer as unions } from './unions';
import { errorsReducer as errors } from './errors';
import { eventsReducer as events } from './events';
import { replayReducer as replay } from './replay';
import { tailReducer as tail } from './tail';
import { devicesPanelReducer as devicesPanel } from './devicesPanel';
import { universalPanelReducer as universalPanel } from './universalPanel';
import { extraLayersReducer as extraLayers } from './extraLayers';
import { filePreviewLayoutReducer as filePreviewLayout } from './filePreviewLayout';

const reducer = combineReducers({
  session,
  devices,
  groups,
  positions,
  geofences,
  map,
  sms,
  bs,
  markers,
  unions,
  errors,
  events,
  replay,
  tail,
  devicesPanel,
  universalPanel,
  extraLayers,
  filePreviewLayout,
});

export { sessionActions } from './session';
export { devicesActions } from './devices';
export { groupsActions } from './groups';
export { positionsActions } from './positions';
export { geofencesActions } from './geofences';
export { mapActions } from './map';
export { smsActions } from './sms';
export { bsActions } from './bs';
export { markersActions } from './markers';
export { unionsActions } from './unions';
export { errorsActions } from './errors';
export { eventsActions } from './events';
export { replayActions } from './replay';
export { tailActions } from './tail';
export { devicesPanelActions } from './devicesPanel';
export { universalPanelActions } from './universalPanel';
export { extraLayersActions } from './extraLayers';
export { filePreviewLayoutActions } from './filePreviewLayout';

export default configureStore({ reducer });
