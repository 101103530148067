import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, useTheme, Typography,
  Backdrop, Fade, makeStyles, Modal, Paper,
} from '@material-ui/core';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { markersActions } from '../../store';
import { savePermissions } from '../../common/utils/savePermisionsFuncs';
import scrollStyles from '../../common/theme/scrollStyles';
import MarkerEditForm from '../../common/components/MarkerEditForm';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px',
  },
  data: {
    maxHeight: '60vh',
    padding: '10px',
    overflow: 'auto',
    ...scrollStyles(5),
  },
  adornmentButton: {
    padding: 0,
  },
  iconValue: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
  text: {
    marginTop: theme.spacing(1.5),
  },
  buttons: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
}));

const MarkerEdit = ({
  setMarker, setEdit, latitude, longitude, marker, open,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();

  const userStorage = useSelector((state) => state.session.user);

  const [permissions, setPermissions] = useState();
  const [errors, setErrors] = useState({});
  const [item, setItem] = useState(marker || {
    name: '',
    attributes: {
      lifetime: {
        value: 1,
        time: 'infinity',
      },
    },
    color: theme.palette.markers.black,
    iconName: t('markerDefaultCat'),
    icon: 'default',
    latitude,
    longitude,
    course: 0,
  });

  useEffect(() => {
    if (marker) {
      setItem(marker);
    }
  }, [marker]);

  const closeMark = () => {
    setItem(marker);
    if (setEdit) {
      setEdit(false);
    } else {
      setMarker({ type: 'Feature' });
    }
  };

  const saveMark = async () => {
    const bodyRequest = {
      ...item, altitude: 0, userId: userStorage.id,
    };

    const response = await fetch(`/api/markers${setEdit ? `/${item.id}` : ''}`, {
      method: setEdit ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyRequest),
    });

    if (response.ok) {
      const data = await response.json();

      if (permissions) {
        permissions.unions.baseId = data.id;
        Object.values(permissions).forEach((permission) => savePermissions(permission, true));
      }

      dispatch(markersActions.add(data));
      setMarker(item);
      closeMark();
    }
  };

  return (
    <>
      <Modal
        open={open}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
        onClose={closeMark}
      >
        <Fade in={open}>
          <Paper className={classes.modal}>
            <Typography id="modal-modal-title" variant="h6" component="h2" align="center" className={classes.text}>
              {t('markerEditFormTitle')}
            </Typography>

            <div className={classes.root}>
              <div className={classes.data}>
                <MarkerEditForm
                  item={item}
                  setItem={setItem}
                  permissions={permissions}
                  setPermissions={setPermissions}
                  errors={errors}
                  setErrors={setErrors}
                />
              </div>
            </div>

            <div className={classes.buttons}>
              <Button type="button" color="primary" variant="outlined" onClick={() => closeMark()}>
                {t('sharedCancel')}
              </Button>
              <Button type="button" color="primary" variant="contained" onClick={() => saveMark()}>
                {t('sharedSave')}
              </Button>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};

export default MarkerEdit;
