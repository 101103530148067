import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal, Box, Checkbox, FormControlLabel, FormGroup, DialogTitle, IconButton, styled, Divider, Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { devicesActions } from '../../../store';

export default function GroupsModal({
  modalOpen, setModalOpen, groups, category,
}) {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useDispatch();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const categories = useSelector((state) => state.devices.categories);
  const closedDevices = useSelector((state) => state.devices.closedDevices);

  const [checkedItems, setCheckedItems] = useState({});
  const scrollableContainerRef = useRef(null);
  const previousScrollTop = useRef(0);

  useEffect(() => {
    const savedCheckedItems = JSON.parse(localStorage.getItem('closedGroups'));

    const countItems = (obj) => Object.keys(obj).length;
    const countAllItems = (obj) => {
      let count = 0;
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === 'object') {
            count += countAllItems(obj[key]);
          } else {
            count++;
          }
        }
      }
      return count;
    };

    if (savedCheckedItems && countAllItems(savedCheckedItems) === countItems(categories) * (countItems(groups) + 1)) {
      setCheckedItems(savedCheckedItems);
    } else {
      const newCheckedItems = {};

      for (const category in categories) {
        if (categories.hasOwnProperty(category)) {
          newCheckedItems[category] = { 0: false };
          Object.values(groups).forEach((group) => {
            newCheckedItems[category][group.id] = false;
          });
        }
      }

      if (savedCheckedItems) {
        for (const category in savedCheckedItems) {
          if (newCheckedItems.hasOwnProperty(category)) {
            for (const key in savedCheckedItems[category]) {
              if (newCheckedItems[category].hasOwnProperty(key)) {
                newCheckedItems[category][key] = savedCheckedItems[category][key];
              }
            }
          }
        }
      }
      setCheckedItems(newCheckedItems);
    }
  }, [categories, groups]);

  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = previousScrollTop.current;
    }
  }, [checkedItems]);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleChange = (event, groupId) => {
    if (scrollableContainerRef.current) {
      previousScrollTop.current = scrollableContainerRef.current.scrollTop;
    }

    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };

      if (groupId === 'selectAll') {
        if (category) {
          newCheckedItems[category] = { ...newCheckedItems[category] };
          newCheckedItems[category][0] = !event.target.checked;
          for (const key in groups) {
            if (groups.hasOwnProperty(key)) {
              newCheckedItems[category][groups[key].id] = !event.target.checked;
            }
          }
        } else {
          for (const cat in newCheckedItems) {
            if (prevCheckedItems.hasOwnProperty(cat)) {
              newCheckedItems[cat] = { ...newCheckedItems[cat] };
              newCheckedItems[cat][0] = !event.target.checked;
              for (const key in groups) {
                if (groups.hasOwnProperty(key)) {
                  newCheckedItems[cat][groups[key].id] = !event.target.checked;
                }
              }
            }
          }
        }
      } else if (category) {
        newCheckedItems[category] = { ...newCheckedItems[category] };
        newCheckedItems[category][groupId] = !event.target.checked;
      } else {
        for (const cat in newCheckedItems) {
          if (newCheckedItems.hasOwnProperty(cat)) {
            newCheckedItems[cat] = { ...newCheckedItems[cat] };
            newCheckedItems[cat][groupId] = !event.target.checked;
          }
        }
      }

      return newCheckedItems;
    });
  };

  const handleSave = () => {
    localStorage.setItem('closedGroups', JSON.stringify(checkedItems));
    const result = { ...closedDevices };

    Object.values(devices).forEach((device) => {
      const { groupId } = device;
      const deviceCategory = device.category;

      if (category) {
        if (category === deviceCategory) {
          result[device.id] = checkedItems[deviceCategory][groupId];
        }
      } else {
        result[device.id] = checkedItems[deviceCategory][groupId];
      }

      // if (checkedItems[deviceCategory][groupId]) {
      //   result[device.id] = true;
      // } else {
      //   result[device.id] = false;
      // }
    });

    dispatch(devicesActions.turnAll(result));

    handleCloseModal();
  };

  const getCheckboxStatus = (groupId) => {
    if (category && !checkedItems[category]) {
      return { status: true, color: '#4CAF50' };
    } if (!category && Object.keys(checkedItems).length === 0) {
      return { status: true, color: '#4CAF50' };
    }

    let allTrue;
    let allFalse;
    if (category) {
      if (groupId === 'selectAll') {
        allTrue = Object.values(checkedItems[category]).every((status) => status === true);
        allFalse = Object.values(checkedItems[category]).every((status) => status === false);
      } else {
        allTrue = checkedItems[category][groupId] === true;
        allFalse = checkedItems[category][groupId] === false;
      }
    } else if (groupId === 'selectAll') {
      allTrue = Object.values(checkedItems).every((cat) => Object.values(cat).every((status) => status === true));
      allFalse = Object.values(checkedItems).every((cat) => Object.values(cat).every((status) => status === false));
    } else {
      allTrue = Object.values(checkedItems).every((cat) => cat[groupId] === true);
      allFalse = Object.values(checkedItems).every((cat) => cat[groupId] === false);
    }

    if (allTrue) {
      return {
        status: false, // Все скрыты
        color: '',
      };
    }
    if (allFalse) {
      return { // Все есть
        status: true,
        color: '#4CAF50',
      };
    }
    return { // частично
      status: true,
      color: '#e37c1b',
    };
  };

  const ScrollableContainer = styled('div')({
    maxHeight: '300px',
    overflowY: 'auto',
    paddingRight: '10px',
  });

  return (
    <Modal open={modalOpen} onClose={handleCloseModal}>
      <Box sx={modalStyle}>
        <DialogTitle style={{ textAlign: 'center', paddingTop: 0 }}>
          {t('allGroupsOnMap')}
          <IconButton aria-label="close" onClick={handleCloseModal} style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {groups
          ? (
            <>
              <ScrollableContainer ref={scrollableContainerRef}>
                <Divider />
                <FormControlLabel
                  key="selectAll"
                  control={(
                    <Checkbox
                      checked={getCheckboxStatus('selectAll').status}
                      onChange={(e) => { handleChange(e, 'selectAll'); }}
                      style={{ color: getCheckboxStatus('selectAll').color }}
                      name={t('selectAllGroupsOnMap')}
                    />
                  )}
                  label={t('selectAllGroupsOnMap')}
                />
                <Divider />
                <FormControlLabel
                  key={0}
                  control={(
                    <Checkbox
                      checked={getCheckboxStatus(0).status}
                      onChange={(e) => { handleChange(e, 0); }}
                      style={{ color: getCheckboxStatus(0).color }}
                      name={t('withoutGroupsOnMap')}
                    />
                  )}
                  label={t('withoutGroupsOnMap')}
                />
                <FormGroup>
                  {Object.values(groups).map((group) => (
                    <FormControlLabel
                      key={group.id}
                      control={(
                        <Checkbox
                          checked={getCheckboxStatus(group.id).status}
                          onChange={(e) => { handleChange(e, group.id); }}
                          style={{ color: getCheckboxStatus(group.id).color }}
                          name={group.name}
                        />
                      )}
                      label={group.name}
                    />
                  ))}
                </FormGroup>
              </ScrollableContainer>
              <Button type="button" color="primary" fullWidth variant="contained" onClick={handleSave}>
                {t('saveOnMap')}
              </Button>
            </>
          )
          : (
            <>
              <Skeleton variant="rect" height={40} />
              <Skeleton variant="rect" height={40} />
              <Skeleton variant="rect" height={40} />
            </>
          )}
      </Box>
    </Modal>

  );
}
