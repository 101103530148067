import {
  memo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { map } from './Map';
import {
  createArrow3DPath, createGeojson3DPath, createGeojsonPath, pathData3DFromPositions, pathDataFromPositions,
} from './funcs/pathFuncs';

const active3D = true;

const ReplayPathMap = ({
  positions, tail, matching, devices, stateSyncPositions,
}) => {
  const id = `path${matching ? '_matching' : ''}`;
  // const [colorsDevices, setColorsDevices] = useState({});

  const createCollection = (positions) => {
    const result = {};
    Object.keys(positions).forEach((id) => {
      const clearPosition = [];
      let lastPosition;
      let partPath = [];
      positions[id].forEach((position) => {
        if (lastPosition) {
          if (new Date(position.fixTime).getTime() - new Date(lastPosition.fixTime).getTime() > 3600000) {
            clearPosition.push(partPath);
            clearPosition.push({ timeGap: [lastPosition, position] });
            partPath = [];
          }
        }
        partPath.push(position);
        lastPosition = position;
      });
      if (partPath.length) {
        clearPosition.push(partPath);
      }
      result[id] = clearPosition;
    });
    return result;
  };

  // const getColors = () => {
  //   const colorsDevices = {};
  //   Object.values(devices)
  //     .forEach((device) => colorsDevices[device.id] = device.attributes.color);
  //   return colorsDevices;
  // };

  // useEffect(() => {
  //   setColorsDevices(getColors());
  // }, [stateSyncDevices]);

  useEffect(() => {
    const cleanupGeojsonPath = createGeojsonPath(id);
    let cleanupGeojson3DPath;
    let cleanupArrow3DPath;

    if (active3D) {
      cleanupGeojson3DPath = createGeojson3DPath(`${id}-3D`);
      cleanupArrow3DPath = createArrow3DPath(`${id}-3D-arrows`);
    }

    return () => {
      if (cleanupGeojsonPath) {
        cleanupGeojsonPath();
      }
      if (cleanupGeojson3DPath) {
        cleanupGeojson3DPath();
        cleanupArrow3DPath();
      }
    };
  }, []);

  useEffect(() => {
    const collectedPositions = tail ? createCollection(positions) : positions;
    map.getSource(id).setData(pathDataFromPositions(
      collectedPositions,
      devices,
    ));
    if (active3D) {
      map.getSource(`${id}-3D`).setData(pathData3DFromPositions(
        collectedPositions,
        devices,
      ).lines);
      map.getSource(`${id}-3D-arrows`).setData(pathData3DFromPositions(
        collectedPositions,
        devices,
      ).arrows);
    }
  }, [stateSyncPositions ?? positions]);

  return null;
};

export default memo(ReplayPathMap);

ReplayPathMap.propTypes = {
  positions: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  stateSyncDevices: PropTypes.any,
  tail: PropTypes.bool,
  matching: PropTypes.bool,
};
