import React, { useEffect, useState, useMemo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Grid, Paper, Switch, Typography, makeStyles, IconButton,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../LocalizationProvider';
import { formatPosition, toTitleCase } from '../../utils/formatter';
import scrollStyles from '../../theme/scrollStyles';
import ExtraSettings from '../ExtraSettings';
import { useEffectAsync } from '../../utils/reactHelper';
import { usePreference } from '../../utils/preferences';
import { convertHtmlCodesToString, isUndefinedOrNull } from '../../utils/stringUtils';
import SmartFileManager from '../SmartFileManager';
import { extraCoordinatesConvert } from '../../utils/converter';

const useStyles = makeStyles((theme) => ({
  ...theme.palette.colors,
  listItemLong: {
    width: '100%',
  },
  smallText: {
    fontSize: 12,
  },
  middleText: {
    fontSize: 14,
  },
  textRed: {
    color: theme.palette.common.red,
  },
  subtitle: {
    width: '100%',
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: '4px',
  },
  scroll: {
    ...scrollStyles(5),
  },
  accordionRoot: {
    boxShadow: 'none',
  },
  accordionSummaryRoot: {
    minHeight: '38px',
    height: '38px',
    padding: '0px',

    '&$accordionSummaryExp': {
      minHeight: '38px',
      height: '38px',
    },
  },
  accordionSummaryExp: {
  },
  accordionSummaryExpIcon: {
    marginRight: '0px',
  },
  accordionDetailsRoot: {
    paddingBottom: '8px',
    flexDirection: 'column',
  },
}));

const Data = ({
  data, off, includeProperties, redFields,
  speedUnit, openChoice, openExtraSettings, rootClass, size,
  isOpenAttachedFilesWindow, closeAttachedFilesWindow,
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const coordinateFormat = usePreference('coordinateFormat');
  const extraCoordinateFormatSelected = useSelector((state) => state.session?.user?.attributes?.extraCoordinateFormat);

  const selectedPositionData = useSelector((state) => state.positions.selectedPositionData);

  const [id, setId] = useState();
  const [item, setItem] = useState();
  const [categoryParams, setCategoryParams] = useState();
  const [isChanged, setIsChanged] = useState();
  const [expandedAccordion, setExpandedAccordion] = useState(false);
  const devicesData = useSelector((state) => state.devices);
  const [expandedExtraPositionAttributes, setExpandedExtraPositionAttributes] = useState(false);
  const [expandedExtraDeviceAttributes, setExpandedExtraDeviceAttributes] = useState(false);

  const getTextClass = () => {
    switch (size) {
      case 'small':
        return classes.smallText;
      default:
        return classes.middleText;
    }
  };

  const checkProperty = (property) => {
    if (!off[selectedPositionData?.deviceCategory]?.includes(`${property}`)
      && property !== 'extraAttributes'
      && property !== 'categoryParams'
      && (![undefined, false].includes(selectedPositionData[property]) || data[property].value)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (selectedPositionData.objClass !== 'marker') {
      const newId = devicesData?.items[selectedPositionData?.deviceId]?.id;
      if (isUndefinedOrNull(newId)) {
        return;
      }
      setId(newId);
    }
  }, [selectedPositionData]);

  useEffectAsync(async () => {
    if (id !== undefined) {
      const response = await fetch(`/api/devices/${id}`);
      if (response.ok) {
        setItem(await response.json());
      }
    }
  }, [id]);

  useEffect(() => {
    if (item !== undefined) {
      setCategoryParams(item.attributes.categoryParams);
    }
  }, [item]);

  async function handleSave() {
    const body = item;
    body.attributes.categoryParams = categoryParams;
    await fetch(`/api/devices/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
  }

  useEffect(() => {
    if (isChanged) {
      handleSave();
      setIsChanged(false);
    }
  }, [isChanged]);

  const handleAccordionOnClick = (accordion) => (event, newExpanded) => {
    if (document.getElementById('categoryParamsSwitch').contains(event.target)) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      setExpandedAccordion(newExpanded ? accordion : false);
    }
  };

  const fileManagerComponent = (
    <>
      {useMemo(() => (
        <>
          {isOpenAttachedFilesWindow && (
            <SmartFileManager
              disableWindowMode
              disableColumnFixedSize
              deviceId={selectedPositionData.deviceId}
              onCloseFilesWindow={closeAttachedFilesWindow}
            />
          )}
        </>
      ), [selectedPositionData.deviceId, isOpenAttachedFilesWindow])}
    </>
  );

  if (isOpenAttachedFilesWindow) {
    return (
      <Paper className={rootClass} elevation={0} square>
        <AutoSizer style={{ maxHeight: '100%', maxWidth: '100%' }}>
          {({ height, width }) => (
            <Grid
              style={{
                height,
                width,
                overflowY: 'auto',
                overflowX: 'hidden',
                paddingBottom: 5,
              }}
              className={classes.scroll}
              container
              direction="column"
            >
              {fileManagerComponent}
            </Grid>
          )}
        </AutoSizer>
      </Paper>
    );
  }

  return (
    <Paper className={rootClass} elevation={0} square>
      <AutoSizer style={{ maxHeight: '100%', maxWidth: '100%' }}>
        {({ height, width }) => (
          <Grid
            style={{
              height,
              width,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            className={classes.scroll}
            container
            direction="column"
          >
            {/* Область свойств позиции устройства */}
            {openChoice ? (
              <Grid container item>
                <Grid container item direction="column">
                  {Object.keys(data).map((property) => (
                    <Grid container item key={property} className={classes.listItemLong} alignItems="center">
                      {(property !== 'categoryParams' && property !== 'extraAttributes') && (
                        <>
                          <Grid item xs="auto">
                            <Typography className={getTextClass()}>{data[property].title}</Typography>
                          </Grid>
                          <Grid item xs style={{ textAlign: 'right' }}>
                            <Switch
                              checked={!off[selectedPositionData?.deviceCategory]?.includes(property)}
                              onClick={() => includeProperties(selectedPositionData?.deviceCategory, property)}
                              size={size || 'medium'}
                            />
                          </Grid>
                        </>
                      )}
                      {(property !== 'categoryParams' && property === 'extraAttributes') && (
                        <Grid container item direction="column">
                          <Grid item>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item xs="auto">
                                <Typography className={getTextClass()}>{t('sharedExtraPositionAttributes')}</Typography>
                              </Grid>
                              <Grid item xs style={{ textAlign: 'right' }}>
                                <Switch
                                  checked={!off[selectedPositionData?.deviceCategory]?.includes('extraPositionAttributes')}
                                  onClick={() => includeProperties(selectedPositionData?.deviceCategory, 'extraPositionAttributes')}
                                  size={size || 'medium'}
                                />
                              </Grid>
                              {selectedPositionData.extraAttributes && (
                                <Grid item>
                                  <IconButton onClick={() => { setExpandedExtraPositionAttributes(!expandedExtraPositionAttributes); }}>
                                    <ExpandLessIcon style={{ transform: expandedExtraPositionAttributes ? 'rotate(180deg)' : 'rotate(90deg)' }} />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                          {(expandedExtraPositionAttributes && selectedPositionData.extraAttributes) && (
                            <Grid item>
                              <Grid container item direction="column">
                                {Object.entries(selectedPositionData.extraAttributes).map(([key, value]) => (
                                  <Grid item key={key} style={{ marginLeft: '20px' }}>
                                    <Grid container spacing={1}>
                                      <Grid item xs="auto">
                                        <Typography className={getTextClass()}>{key}</Typography>
                                      </Grid>
                                      <Grid item xs style={{ textAlign: 'right' }}>
                                        <Switch
                                          disabled={!!off[selectedPositionData?.deviceCategory]?.includes('extraPositionAttributes')}
                                          checked={!off[selectedPositionData?.deviceCategory]?.includes(`extraPositionAttributes_${key}`)}
                                          onClick={() => includeProperties(selectedPositionData?.deviceCategory, `extraPositionAttributes_${key}`)}
                                          size={size || 'medium'}
                                          label={value}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      {property === 'categoryParams' && selectedPositionData.categoryParams && Object.keys(selectedPositionData.categoryParams).filter((attribute) => attribute !== 'type').length > 0
                        ? (
                          <Accordion square expanded={expandedAccordion === property} onChange={handleAccordionOnClick(property)} classes={{ root: classes.accordionRoot }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              classes={{
                                root: classes.accordionSummaryRoot,
                                expanded: classes.accordionSummaryExp,
                                expandIcon: classes.accordionSummaryExpIcon,
                              }}
                            >
                              <Grid container alignItems="center" wrap="nowrap">
                                <Grid item xs="auto">
                                  <Typography className={getTextClass()}>{data[property].title}</Typography>
                                </Grid>
                                <Grid item xs style={{ textAlign: 'right' }}>
                                  <Switch
                                    id="categoryParamsSwitch"
                                    checked={!off[selectedPositionData?.deviceCategory]?.includes(property)}
                                    onClick={() => includeProperties(selectedPositionData?.deviceCategory, property)}
                                    size={size || 'medium'}
                                  />
                                </Grid>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                              {Object.keys(selectedPositionData.categoryParams).filter((attribute) => attribute !== 'type').map((attribute) => (
                                <Grid container key={attribute} alignItems="center" wrap="nowrap">
                                  <Grid item xs="auto">
                                    <Typography className={getTextClass()}>{t(`position${toTitleCase(attribute)}`)}</Typography>
                                  </Grid>
                                  <Grid item xs style={{ textAlign: 'right' }}>
                                    <Switch
                                      disabled={off[selectedPositionData?.deviceCategory]?.includes(property)}
                                      checked={!off[selectedPositionData?.deviceCategory]?.includes(`${property}-${attribute}`)}
                                      onClick={() => includeProperties(selectedPositionData?.deviceCategory, `${property}-${attribute}`)}
                                      size={size || 'medium'}
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        )
                        : (<></>)}
                    </Grid>
                  ))}

                  {(item?.attributes?.extraAttributes) && (
                    <Grid container item direction="column">
                      <Grid item>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs="auto">
                            <Typography className={getTextClass()}>{t('sharedExtraDeviceAttributes')}</Typography>
                          </Grid>
                          <Grid item xs style={{ textAlign: 'right' }}>
                            <Switch
                              checked={!off[selectedPositionData?.deviceCategory]?.includes('extraDeviceAttributes')}
                              onClick={() => includeProperties(selectedPositionData?.deviceCategory, 'extraDeviceAttributes')}
                              size={size || 'medium'}
                            />
                          </Grid>
                          {item?.attributes?.extraAttributes && (
                            <Grid item>
                              <IconButton onClick={() => { setExpandedExtraDeviceAttributes(!expandedExtraDeviceAttributes); }}>
                                <ExpandLessIcon style={{ transform: expandedExtraDeviceAttributes ? 'rotate(180deg)' : 'rotate(90deg)' }} />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      {(expandedExtraDeviceAttributes && item?.attributes?.extraAttributes) && (
                        <Grid item>
                          <Grid container item direction="column">
                            {Object.entries(item?.attributes?.extraAttributes).map(([key, value]) => (
                              <Grid item key={key} style={{ marginLeft: '20px' }}>
                                <Grid container spacing={1}>
                                  <Grid item xs="auto">
                                    <Typography className={getTextClass()}>{key}</Typography>
                                  </Grid>
                                  <Grid item xs style={{ textAlign: 'right' }}>
                                    <Switch
                                      disabled={!!off[selectedPositionData?.deviceCategory]?.includes('extraDeviceAttributes')}
                                      checked={!off[selectedPositionData?.deviceCategory]?.includes(`extraDeviceAttributes_${key}`)}
                                      onClick={() => includeProperties(selectedPositionData?.deviceCategory, `extraDeviceAttributes_${key}`)}
                                      size={size || 'medium'}
                                      label={value}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}

                </Grid>
              </Grid>
            ) : (<></>)}

            {/* Область с настройками */}
            {openExtraSettings ? (
              <Grid container item>
                <Grid container item direction="column">
                  <Grid item xs="auto">
                    {categoryParams !== undefined ? <ExtraSettings item={item} categoryParams={categoryParams} setCategoryParams={setCategoryParams} setIsChanged={setIsChanged} /> : <></>}
                  </Grid>
                </Grid>
              </Grid>
            ) : (<></>)}

            {(!openChoice && !openExtraSettings) ? (
              <Grid container item>
                <Grid container item direction="column" spacing={size === 'small' ? 0 : 1}>
                  {Object.keys(data).filter((property) => checkProperty(property)).map((property) => (
                    <Grid container item direction="column" key={property}>
                      <Grid container item spacing={2} className={classes.listItemLong}>
                        <Grid item xs>
                          <Typography className={`${getTextClass()} ${redFields.includes(property) && classes.textRed}`}>
                            {data[property].title}
                          </Typography>
                        </Grid>
                        <Grid item xs className={`${redFields.includes(property) && classes.textRed}`} style={{ textAlign: 'right' }}>
                          {data[property].value || formatPosition(selectedPositionData[property], `${property}`, t, speedUnit, coordinateFormat)}
                        </Grid>
                      </Grid>
                      {(property === 'coordinates' && extraCoordinateFormatSelected && extraCoordinateFormatSelected !== 'none') && (
                        <Grid container item key="positionExtraCoordinate" spacing={2} className={classes.listItemLong}>
                          <Grid item xs>
                            <Typography className={`${getTextClass()} ${redFields.includes(property) && classes.textRed}`}>
                              {`${t('positionExtraCoordinate')} (${t(`shared${toTitleCase(extraCoordinateFormatSelected)}ExtraCoordinates`)})`}
                            </Typography>
                          </Grid>
                          <Grid item xs className={`${redFields.includes(property) && classes.textRed}`} style={{ textAlign: 'right' }}>
                            {extraCoordinatesConvert(selectedPositionData.coordinates, extraCoordinateFormatSelected)}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>

                {/* Область Параметры позиции устройства */}
                {!!(!off[selectedPositionData?.deviceCategory]?.includes('categoryParams') && selectedPositionData.categoryParams) && (
                  <Grid container item direction="column">
                    <Grid item>
                      <Divider style={{ margin: '8px 0px' }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2" color="inherit" className={classes.subtitle}>
                        {t('sharedCategoryParams')}
                      </Typography>
                    </Grid>
                    <Grid container item direction="column" spacing={size === 'small' ? 0 : 1}>
                      {Object.keys(selectedPositionData.categoryParams).filter((attribute) => attribute !== 'type').map((attr) => (
                        !off[selectedPositionData?.deviceCategory]?.includes(`categoryParams-${attr}`)
                          ? (
                            <Grid container item key={attr} className={classes.listItemLong} spacing={2}>
                              <Grid item xs>
                                <Typography className={getTextClass()}>{t(`position${toTitleCase(attr)}`)}</Typography>
                              </Grid>
                              <Grid item xs style={{ textAlign: 'right' }}>
                                {formatPosition(selectedPositionData.categoryParams[attr], `${attr}`, t, speedUnit, coordinateFormat)}
                              </Grid>
                            </Grid>
                          )
                          : null
                      ))}
                    </Grid>
                  </Grid>
                )}

                {/* Область Дополнительных данных позиции */}
                {!!(!off[selectedPositionData?.deviceCategory]?.includes('extraPositionAttributes') && selectedPositionData.extraAttributes) && (
                  <Grid container item direction="column">
                    <Grid item>
                      <Divider style={{ margin: '8px 0px' }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2" color="inherit" className={classes.subtitle}>
                        {t('sharedExtraPositionAttributes')}
                      </Typography>
                    </Grid>
                    <Grid container item direction="column" spacing={size === 'small' ? 0 : 1}>
                      {Object.keys(selectedPositionData.extraAttributes).map((attribute) => (
                        <>
                          {(!off[selectedPositionData?.deviceCategory]?.includes(`extraPositionAttributes_${attribute}`))
                            ? (
                              <Grid container item key={attribute} className={classes.listItemLong} spacing={2}>
                                <Grid item xs>
                                  <Typography className={getTextClass()}>{attribute}</Typography>
                                </Grid>
                                <Grid item xs style={{ textAlign: 'right' }}>
                                  {convertHtmlCodesToString(selectedPositionData.extraAttributes[attribute])}
                                </Grid>
                              </Grid>
                            )
                            : <></>}
                        </>
                      ))}
                    </Grid>
                  </Grid>
                )}

                {/* Область Дополнительных данных устройства */}
                {!!(!off[selectedPositionData?.deviceCategory]?.includes('extraDeviceAttributes') && item?.attributes?.extraAttributes) && (
                  <Grid container item direction="column">
                    <Grid item>
                      <Divider style={{ margin: '8px 0px' }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2" color="inherit" className={classes.subtitle}>
                        {t('sharedExtraDeviceAttributes')}
                      </Typography>
                    </Grid>
                    <Grid container item direction="column" spacing={size === 'small' ? 0 : 1}>
                      {Object.keys(item?.attributes?.extraAttributes).map((attribute) => (
                        <>
                          {(!off[selectedPositionData?.deviceCategory]?.includes(`extraDeviceAttributes_${attribute}`))
                            ? (
                              <Grid container item key={attribute} className={classes.listItemLong} spacing={2}>
                                <Grid item xs>
                                  <Typography className={getTextClass()}>{attribute}</Typography>
                                </Grid>
                                <Grid item xs style={{ textAlign: 'right' }}>
                                  {convertHtmlCodesToString(item?.attributes?.extraAttributes[attribute])}
                                </Grid>
                              </Grid>
                            )
                            : <></>}
                        </>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ) : (<></>)}
          </Grid>
        )}
      </AutoSizer>
    </Paper>
  );
};

export default Data;
