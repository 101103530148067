import { useEffect } from 'react';
import circle from '@turf/circle';

import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { map } from './Map';
import { createRadarFeature, getBeforeId } from './funcs/pathFuncs';
import { getTitle } from '../common/utils/formatter';

const SelectedPositionMap = ({ position }) => {
  const id = 'selectedAccuracy';
  const selectedPositionId = 'selectedPosition';
  const selectedPositionTextId = 'selectedPositionText';
  const radarId = 'selectedPositionRadar';
  const theme = useTheme();
  const devices = useSelector((state) => state.devices.items);

  const getRadius = (positionType, objClass) => {
    if (objClass === 'marker') {
      return 20;
    }
    switch (positionType) {
      case 'current':
        return 25;
      case 'temporary':
      case 'stop':
      case 'startPoint':
      case 'finishPoint':
        return 20;
      default:
        return 15;
    }
  };

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    map.addLayer({
      source: id,
      id,
      type: 'fill',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
      ],
      paint: {
        'fill-color': '#3bb2d0',
        'fill-outline-color': '#3bb2d0',
        'fill-opacity': 0.25,
      },
    }, getBeforeId(id));

    map.addLayer({
      id: selectedPositionId,
      type: 'circle',
      source: id,
      filter: [
        'all',
        ['==', '$type', 'Point'],
      ],
      paint: {
        // 'circle-color': '#633ef7',
        'circle-color': '#fff',
        'circle-stroke-color': '#000',
        'circle-stroke-width': 3,
        'circle-radius': { type: 'identity', property: 'radius' },
        'circle-blur': 0.3,
      },
    }, getBeforeId(selectedPositionId));

    map.addLayer({
      id: selectedPositionTextId,
      type: 'symbol',
      source: id,
      filter: [
        'all',
        ['==', '$type', 'Point'],
      ],
      layout: {
        'text-field': '{title}',
        'text-anchor': 'bottom',
        'text-offset': [0, -1.5],
        'text-font': ['Roboto Medium'],
        'text-size': 12,
        'text-allow-overlap': true,
      },
      paint: {
        'text-halo-color': 'white',
        'text-halo-width': 1,
        'text-color': '#000',
      },
    }, getBeforeId(selectedPositionTextId));

    map.addLayer({
      source: id,
      id: radarId,
      type: 'fill',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', 'category', 'radar'],
      ],
      paint: {
        'fill-color': { type: 'identity', property: 'radarColor' },
        'fill-outline-color': '#705439',
        'fill-opacity': 0.25,
      },
    }, getBeforeId(radarId));

    return () => {
      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getLayer(selectedPositionId)) {
        map.removeLayer(selectedPositionId);
      }
      if (map.getLayer(selectedPositionTextId)) {
        map.removeLayer(selectedPositionTextId);
      }
      if (map.getLayer(radarId)) {
        map.removeLayer(radarId);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, []);

  useEffect(() => {
    const features = [];

    if (position.accuracy > 0) {
      features.push(circle([position.longitude, position.latitude], position.accuracy * 0.001));
    }

    features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [position.longitude, position.latitude],
      },
      properties: {
        title: position.title ? '' : getTitle(position.name, position.fixTime || position.startTime, position.objClass),
        radius: getRadius(position.type, position.objClass),
      },
    });

    try {
      const { categoryParams } = position;
      const device = devices[position.deviceId];
      if (categoryParams
        && device.category === 'radar'
        && position.type !== 'current'
        && categoryParams?.radius) {
        features.push(createRadarFeature(
          position.longitude, position.latitude, position.altitude, device.attributes.showIn3D,
          categoryParams.radius, categoryParams.azimuth, categoryParams.diagram,
          device.attributes.categoryParams.radarColor, theme,
        ));
      }
    } catch (error) {
      console.warn(error);
    }

    map.getSource(id).setData({
      type: 'FeatureCollection',
      features,
    });
  }, [position]);

  return null;
};

export default SelectedPositionMap;
