import {
  makeStyles,
} from '@material-ui/core';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WebAssetRounded } from '@material-ui/icons';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import CustomizableBadge from '../../../common/components/CustomizableBadge';
import { universalPanelActions } from '../../../store/universalPanel';
import { availableComponents } from '../../../common/hooks/useComponentsController';
import useStylesForUniversalPanelOfPerfectTable from '../../Settings/common/useStylesForUniversalPanelOfPerfectTable';
import { isUndefinedOrNull } from '../../../common/utils/stringUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(7),
  },
}));

const UniversalPanelButton = () => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const accessibleCategoriesByDetected = useSelector((state) => state.devices.accessibleCategoriesByDetected);

  const panelState = useSelector((state) => state.universalPanel);

  const listTables = {
    detected_object: availableComponents.DetectedObjectsPage,
    radar: availableComponents.RadarsPage,
    anti_uav: availableComponents.AntiUavsPage,
  };

  const universalPanelSettings = {
    header: availableComponents.SelectCategory,
    settings: {
      disableStickyHeader: true,
      disableHeader: true,
      classes: useStylesForUniversalPanelOfPerfectTable(),
      label: t('deviceCategoriesDetected_object'),
      linkText: 'settings/detected',
      disableFilterRowTool: true,
      disableSpeedColumn: true,
      disableCoordinateColumn: true,
    },
  };

  useEffect(() => {
    if (accessibleCategoriesByDetected.length > 0
      && isUndefinedOrNull(localStorage.getItem('selectedObjectCategoryOnMap'))) {
      universalPanelSettings.body = listTables[accessibleCategoriesByDetected[0]];
    }
  }, [accessibleCategoriesByDetected]);

  const handleClick = () => {
    if (panelState.isShow || panelState.header) {
      dispatch(universalPanelActions.changeVisibility());
    } else {
      dispatch(universalPanelActions.createAndShow(universalPanelSettings));
    }
  };

  return (
    <div className={classes.root}>
      <CustomizableBadge
        isActive={panelState.isShow ?? false}
        title={t('globalObjectDetectedPanel')}
        icon={<WebAssetRounded />}
        onClick={handleClick}
      />
    </div>
  );
};

export default memo(() => <UniversalPanelButton />);
