import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'replay',
  initialState: {
    items: {},
  },
  reducers: {
    update(state, action) {
      action.payload.forEach((item) => {
        state.items[item.id] = item;
      })
    },
    clean(state){
      state.items = {};
    }
  },
});

export { actions as replayActions };
export { reducer as replayReducer };
