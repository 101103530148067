import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonGroup,
  makeStyles, useTheme,
} from '@material-ui/core';
import { useTranslation } from '../common/components/LocalizationProvider';
import { markersActions } from '../store';
import { savePermissions } from '../common/utils/savePermisionsFuncs';
// import { prefixString } from '../common/utils/stringUtils';
// import MarkerCategoryFields from '../views/Settings/components/MarkerCategoryFields';
// import markerCategoryParams from '../common/static/markerCategoryParams';
import scrollStyles from '../common/theme/scrollStyles';
import MarkerEditForm from '../common/components/MarkerEditForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '300px',
    margin: '10px',
    maxHeight: '60vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    ...scrollStyles(5),
  },
  adornmentButton: {
    padding: 0,
  },
  iconValue: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}));

const MarkPopup = ({
  popup, setMarker, latitude, longitude,
}) => {
  const t = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector((state) => state.session.user);
  const [permissions, setPermissions] = useState();
  const [errors, setErrors] = useState({});

  const [item, setItem] = useState({
    name: '',
    attributes: {
      lifetime: {
        value: 1,
        time: 'infinity',
      },
    },
    color: theme.palette.markers.black,
    icon: 'default',
    latitude,
    longitude,
    course: 0,
  });

  const closeMark = () => {
    popup.remove();
    setMarker({ type: 'Feature' });
  };

  const saveMark = async () => {
    const bodyRequest = {
      ...item, altitude: 0, userId: user.id,
    };

    const response = await fetch('/api/markers', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyRequest),
    });

    if (response.ok) {
      const data = await response.json();

      if (permissions) {
        permissions.unions.baseId = data.id;
        Object.values(permissions).forEach((permission) => savePermissions(permission, true));
      }
      dispatch(markersActions.add(data));
      closeMark();
    }
  };

  return (
    <div className={classes.root}>
      <MarkerEditForm
        item={item}
        setItem={setItem}
        permissions={permissions}
        setPermissions={setPermissions}
        errors={errors}
        setErrors={setErrors}
      />
      <ButtonGroup size="small" fullWidth>
        <Button variant="" onClick={() => closeMark()}>{t('sharedCancel')}</Button>
        <Button variant="" color="secondary" onClick={() => saveMark()}>
          {t('sharedSave')}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default MarkPopup;
