import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { useSelector } from 'react-redux';
import { useTranslation } from './LocalizationProvider';
import EventSidePanel from './EventSidePanel';
import CustomizableBadge from './CustomizableBadge';

const useStyles = makeStyles((theme) => ({
  trackingMode: {
    borderRadius: '10px',
    minWidth: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
    cursor: 'pointer',
  },
}));

const EventNotifications = ({ events }) => {
  const classes = useStyles();
  const t = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <div className={classes.trackingMode}>
        <CustomizableBadge
          isActive={events.length > 0 || open}
          title={t('reportEvents')}
          icon={(<NotificationsNoneIcon />)}
          badgeContent={events.length}
          onClick={handleClick}
        />
      </div>
      {open && (
        <EventSidePanel setOpen={setOpen} />
      )}
    </>
  );
};

export default EventNotifications;
