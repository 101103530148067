import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, FormControl, InputLabel, Select, MenuItem, ListItemIcon, ListItemText, Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  DialogActions,
} from '@material-ui/core';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { useTheme } from '@material-ui/core/styles';

const SelectDeviceIcon = ({
  icons, currentIcon, onChange, disabled,
}) => {
  const t = useTranslation();
  const [markerIconOpen, setMarkerIconOpen] = useState(false);

  const chooseIcon = (icon) => {
    onChange(icon);
    setMarkerIconOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setMarkerIconOpen(true)}
        fullWidth
        variant="contained"
        required
        disabled={!!disabled}
        startIcon={(
          <Avatar style={{ background: '#fff0' }}>
            <img src={`/images/icon/device/${currentIcon ?? 'default'}.svg`} alt="" />
          </Avatar>
        )}
      >
        {t('deviceIcon')}
      </Button>

      <Dialog
        maxWidth="lg"
        open={markerIconOpen}
        onClose={() => setMarkerIconOpen(false)}
      >
        <DialogTitle>{t('deviceIcon')}</DialogTitle>
        <DialogContent>
          <Grid container columns={2} direction="column">
            {Object.keys(icons).map((ic) => (
              <Grid
                container
                item
                key={ic}
                alignItems="center"
                spacing={2}
                onClick={() => chooseIcon(ic)}
                style={{ cursor: 'pointer' }}
              >
                <Grid item>
                  <Avatar
                    style={{ background: currentIcon === ic ? '#198cffa4' : '#fff' }}
                  >
                    <img src={`/images/icon/device/${ic}.svg`} alt="" />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography>
                    {t(`icon${ic.replace(/^\w/, (c) => c.toUpperCase())}`)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setMarkerIconOpen(false)}>{t('closeTitle')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectDeviceIcon;

SelectDeviceIcon.propTypes = {
  icons: PropTypes.object.isRequired,
  currentIcon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};


// const SelectDeviceIcon = ({
//   currentIcon, onChange, disabled,
// }) => {
//   const t = useTranslation();
//   const theme = useTheme();
//   const [icons, setIcons] = useState([]);

//   useEffect(() => {
//     const fetchIcons = async () => {
//       const response = await fetch('/api/icons');
//       if (response.ok) {
//         setIcons(await response.json());
//       } else {
//         setIcons([]);
//       }
//     };

//     fetchIcons();
//   }, []);

//   return (
//     <>
//       {icons.length > 0 ? (
//         <FormControl fullWidth variant="filled" margin="dense" required>
//           <InputLabel>{t('deviceIcon')}</InputLabel>
//           <Select
//             value={currentIcon}
//             onChange={(e) => onChange(e.target.value)}
//             disabled={disabled}
//             renderValue={() => (
//               <div style={{ display: 'flex', gap: theme.spacing(2) }}>
//                 <img src={`/images/icon/device/${currentIcon}.svg`} alt="" />
//                 <Typography>{currentIcon}</Typography>
//               </div>
//             )}
//           >
//             {icons.map((cat) => (
//               <MenuItem value={cat.icon} key={cat.id}>
//                 <ListItemIcon>
//                   <img src={`/images/icon/device/${cat.icon}.svg`} alt="" />
//                 </ListItemIcon>
//                 <ListItemText>{cat.name}</ListItemText>
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       ) : (
//         <Typography>{t('noIconsAvailable')}</Typography>
//       )}
//     </>
//   );
// };

// SelectDeviceIcon.propTypes = {
//   currentIcon: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   disabled: PropTypes.bool,
// };

// export default SelectDeviceIcon;
