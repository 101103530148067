import {
  Box,
  Checkbox,
  FormControl, InputLabel, LinearProgress, ListItemText, MenuItem, Select, TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useEffectAsync } from '../utils/reactHelper';
import SuperMenuItem from '../components/SuperMenuItem';
import logout from '../utils/logout';
import { useTranslation } from '../components/LocalizationProvider';
import { errorsActions } from '../../store';

const ELEMENT_HEIGHT = 48;
const MIN_CONTAINER_HEIGHT = 360;

const LinkField = ({
  margin,
  variant,
  label,
  endpointAll,
  endpointLinked,
  keyGetter = (item) => item.id,
  titleGetter = (item) => item.name,
  subtitleGetter = () => null,
  permissions,
  setPermissions,
  type,
  fullWidth,
  newItem,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslation();
  const adminEnabled = useSelector((state) => state.session.user?.administrator);

  const [items, setItems] = useState();
  const [searchValue, setSearchValue] = useState('');

  const filteredItems = items ? items.filter((item) => titleGetter(item).toLowerCase().includes(searchValue.toLowerCase())) : [];

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const apiCall = async () => {
      try {
        const response = await fetch(endpointAll, { signal });
        if (response.ok) {
          setItems(await response.json());
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        }
      }
    };
    apiCall();
    return () => {
      abortController.abort(); // Cancel the request if component unmounts
    };
  }, []);

  useEffectAsync(async () => {
    if (!newItem) {
      const response = await fetch(endpointLinked);
      if (response.ok) {
        const data = await response.json();
        const per = permissions;
        per[type].linked = new Set(data.map((it) => it.id));
        per[type].old = new Set(data.map((it) => it.id));
        setPermissions({ ...per });
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  }, []);

  useEffect(() => {
    if (items && permissions && setPermissions && type === 'devices' && permissions?.groups?.linked && adminEnabled) {
      const per = permissions;
      items.forEach((device) => {
        if (per.groups.linked.has(device.groupId)) {
          per.devices.groupReserve.add(device.id);
        } else {
          per.devices.groupReserve.delete(device.id);
        }
        if (per.groups.old.has(device.groupId)) {
          per.devices.linked.delete(device.id);
        }
      });
      setPermissions({ ...per });
    }
  }, [items, permissions?.groups?.linked.size]);

  const clearOrFillPermissions = () => {
    const anyLinked = filteredItems.some((item) => permissions[type].linked.has(keyGetter(item)));

    if (anyLinked) {
      filteredItems.forEach((item) => {
        permissions[type].linked.delete(keyGetter(item));
      });
    } else {
      filteredItems.forEach((item) => {
        permissions[type].linked.add(keyGetter(item));
      });
    }
    setPermissions({ ...permissions });
  };

  const deleteOrAddItemToPermissions = (item) => {
    const id = keyGetter(item);

    if (permissions[type].linked.has(id)) {
      permissions[type].linked.delete(id);
    } else {
      permissions[type].linked.add(id);
    }
  };

  const handleElementClick = (item) => {
    if (item === 'all') {
      clearOrFillPermissions();
    } else {
      deleteOrAddItemToPermissions(item);
    }

    setPermissions({ ...permissions });
  };

  const getValue = () => {
    if (type === 'devices') {
      return Array.from(new Set([...permissions[type].linked, ...permissions[type].groupReserve]));
    }
    return Array.from(permissions[type].linked);
  };

  const isChecked = (item) => {
    if (permissions[type].linked.has(keyGetter(item))) {
      return true;
    }
    if (type === 'devices' && permissions[type].groupReserve.has(keyGetter(item))) {
      return true;
    }
    return false;
  };

  const isDisabled = (item) => {
    if (type === 'devices' && permissions[type].groupReserve.has(keyGetter(item))) {
      return true;
    }
    return false;
  };

  const menuItemLazyLoad = ({ index, style }) => (
    <MenuItem
      style={style}
      value={keyGetter(filteredItems[index])}
      key={keyGetter(filteredItems[index])}
      disabled={isDisabled(filteredItems[index])}
      onClick={() => handleElementClick(filteredItems[index])}
    >
      <Checkbox
        checked={isChecked(filteredItems[index])}
      />
      <ListItemText primary={titleGetter(filteredItems[index])} secondary={subtitleGetter(filteredItems[index])} />
    </MenuItem>
  );

  const calcHeight = () => (items.length >= 7 ? MIN_CONTAINER_HEIGHT : items.length * ELEMENT_HEIGHT);

  if (!items) {
    return (
      <FormControl margin={margin} variant={variant} fullWidth={fullWidth}>
        <LinearProgress />
      </FormControl>
    );
  }

  if (permissions[type].linked) {
    return (
      <FormControl margin={margin} variant={variant} fullWidth={fullWidth}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          value={getValue()}
          renderValue={(selected) => (items ? `${selected.length} ${t('sharedNumbers')}` : null)}
          MenuProps={theme.overrides.MenuProps}
        >
          <div>
            {items?.length ? (
              <Box
                style={{
                  marginTop: '-10px',
                  backgroundColor: theme.palette.grey[300],
                  paddingLeft: 0,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <MenuItem
                  value="all"
                  onClick={() => handleElementClick('all')}
                >
                  <SuperMenuItem all={permissions[type].linked.size} />
                </MenuItem>
                <TextField
                  style={{ backgroundColor: '#fff', borderRadius: '5px', marginRight: '8px' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  placeholder={t('sharedSearch')}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </Box>
            ) : (<></>)}
            {items?.length ? (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <FixedSizeList
                    height={calcHeight()}
                    width={width}
                    itemCount={filteredItems.length}
                    itemSize={ELEMENT_HEIGHT}
                  >
                    {menuItemLazyLoad}
                  </FixedSizeList>
                )}
              </AutoSizer>
            ) : (
              <MenuItem>
                <Typography />
              </MenuItem>
            )}
          </div>
        </Select>
      </FormControl>
    );
  }
  return null;
};

export default LinkField;
