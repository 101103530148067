import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  Typography, AccordionActions, AccordionSummary,
  Checkbox, Tooltip, IconButton, MenuItem, ListItemIcon, ButtonGroup, Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import {
  ExpandLess, LaunchRounded,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { toTitleCase } from '../../../../common/utils/formatter';
import { FULL, LINKS_TO_CATEGORIES_FOR_HEADERS, NOT_FULL } from './constants';
import useStatusCheckboxChecker from '../../../../common/hooks/useStatusCheckboxChecker';

const getIconTextColor = (status) => (status ? 'blue' : 'gray');

const VirtualHeaderRow = ({
  updateVirtualListUI,
  style, deviceHeader, classes, iconText, turnTail, tailOn,
  turnOnDevices, expandHandler, turnIconText,
  menuHeaderContent, handleOnClickMoreInfo, handleMenuHeaderClose,
  handleGroupsOpenModal, handlePeriodOpenModal,
}) => {
  const t = useTranslation();
  const checkboxStatus = useStatusCheckboxChecker();
  const isReorderingMode = useSelector((state) => state.devicesPanel.isReorderingMode);

  checkboxStatus.defineStatus(deviceHeader.visibilityStatus === FULL, deviceHeader.visibilityStatus === NOT_FULL);

  const handleTurnOnDevices = () => {
    turnOnDevices(deviceHeader);
    handleMenuHeaderClose();
    updateVirtualListUI();
  };

  const handleTurnIconTextCategory = (val) => {
    turnIconText(deviceHeader, val);
    handleMenuHeaderClose();
    updateVirtualListUI();
  };

  const handleTurnTail = (val) => {
    turnTail(deviceHeader.title, val);
    handleMenuHeaderClose();
    updateVirtualListUI();
  };

  const handleLinkClick = (link) => {
    window.open(link, '_blank');
    handleMenuHeaderClose();
  };

  const getIconTextButtonStyle = (val) => ({
    margin: 0,
    backgroundColor: (deviceHeader.iconText ?? null) === val ? '#deefd8' : '',
  });

  const getTailButtonStyle = (val) => ({
    margin: 0,
    backgroundColor: (tailOn ?? null) === val ? '#deefd8' : '',
  });

  const handleExpandDevicesForHeader = () => {
    expandHandler(deviceHeader);
    updateVirtualListUI();
  };

  const handleClickTurnOnNewDevices = (title) => {
    let closeNewDevices = JSON.parse(localStorage.getItem('closeNewDevices'));
    if (closeNewDevices) {
      closeNewDevices[title] = !closeNewDevices[title];
    } else {
      closeNewDevices = {};
      closeNewDevices[title] = !closeNewDevices[title];
    }
    localStorage.setItem('closeNewDevices', JSON.stringify(closeNewDevices));
    handleMenuHeaderClose();
  };

  const getCheckboxStatus = () => {
    let lcItem = localStorage.getItem('closedGroups');

    if (lcItem && lcItem.trim() !== '') {
      lcItem = JSON.parse(lcItem);

      const allTrue = Object.values(lcItem[deviceHeader.title]).every((value) => value === true);
      const allFalse = Object.values(lcItem[deviceHeader.title]).every((value) => value === false);

      if (allTrue) {
        return {
          status: false, // Все скрыты
          color: '',
        };
      }
      if (allFalse) {
        return { // Все есть
          status: true,
          color: '#4CAF50',
        };
      }
      return { // Частично
        status: true,
        color: '#e37c1b',
      };
    }
    return {
      status: true,
      color: '#4CAF50',
    };
  };

  const handleMenuOpening = (event) => {
    menuHeaderContent.current = () => {
      const componentsArray = [
        <MenuItem key={1} style={{ paddingTop: '0px', paddingBottom: '0px' }} button onClick={handleTurnOnDevices}>
          <ListItemIcon>
            <Checkbox style={checkboxStatus.getStyles()} checked={checkboxStatus.notEmpty()} />
          </ListItemIcon>
          <Typography>
            {t('allShowDevicesOnMap')}
          </Typography>
        </MenuItem>,
      ];

      componentsArray.push(
        <MenuItem key={5} style={{ paddingTop: '0px', paddingBottom: '0px' }} button onClick={() => { handleClickTurnOnNewDevices(deviceHeader.title); }}>
          <ListItemIcon>
            <Checkbox checked={JSON.parse(localStorage.getItem('closeNewDevices')) === null ? true : !(JSON.parse(localStorage.getItem('closeNewDevices')))[deviceHeader.title]} />
          </ListItemIcon>
          <Typography>
            {t('allShowNewDevicesOnMap')}
          </Typography>
        </MenuItem>,
      );

      componentsArray.push(
        <MenuItem key={6} style={{ paddingTop: '0px', paddingBottom: '0px' }} button onClick={() => { handleGroupsOpenModal(deviceHeader.title); }}>
          <ListItemIcon style={{
            width: '42px', height: '42px', paddingLeft: '12px', alignItems: 'center', color: getCheckboxStatus().color,
          }}
          >
            <GroupWorkIcon />
          </ListItemIcon>
          <Typography>
            {t('allGroupsOnMap')}
          </Typography>
        </MenuItem>,
      );

      componentsArray.push(
        <MenuItem key={7} style={{ paddingTop: '0px', paddingBottom: '0px' }} button onClick={() => { handlePeriodOpenModal('show', deviceHeader.title); }}>
          <ListItemIcon style={{
            width: '42px', height: '42px', paddingLeft: '12px', alignItems: 'center',
          }}
          >
            <Visibility />
          </ListItemIcon>
          <Typography>
            {t('showForPeriodOnMap')}
          </Typography>
        </MenuItem>,
      );

      componentsArray.push(
        <MenuItem key={8} style={{ paddingTop: '0px', paddingBottom: '0px' }} button onClick={() => { handlePeriodOpenModal('hide', deviceHeader.title); }}>
          <ListItemIcon style={{
            width: '42px', height: '42px', paddingLeft: '12px', alignItems: 'center',
          }}
          >
            <VisibilityOff />
          </ListItemIcon>
          <Typography>
            {t('hideForPeriodOnMap')}
          </Typography>
        </MenuItem>,
      );

      if (LINKS_TO_CATEGORIES_FOR_HEADERS[deviceHeader.title]) {
        componentsArray.push(
          <MenuItem key={4} style={{ paddingTop: '10px', paddingBottom: '10px' }} button onClick={() => handleLinkClick(LINKS_TO_CATEGORIES_FOR_HEADERS[deviceHeader.title])}>
            <ListItemIcon style={{ paddingLeft: 8 }}>
              <LaunchRounded color="action" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {t('sharedShowDetails')}
            </Typography>
          </MenuItem>,
        );
      }
      componentsArray.push((
        <MenuItem style={{ paddingTop: '5px', paddingBottom: '5px' }} key={2}>
          <ButtonGroup fullWidth variant="outlined" size="small">
            <Button size="small" style={getIconTextButtonStyle(null)} onClick={() => handleTurnIconTextCategory(null)}>
              <Typography variant="caption">{t('auto')}</Typography>
            </Button>
            <Button size="small" style={getIconTextButtonStyle(true)} onClick={() => handleTurnIconTextCategory(true)}>
              <TextFieldsIcon style={{ color: '#198cff' }} fontSize="small" />
            </Button>
            <Button size="small" style={getIconTextButtonStyle(false)} onClick={() => handleTurnIconTextCategory(false)}>
              <TextFieldsIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        </MenuItem>
      ));

      componentsArray.push((
        <MenuItem style={{ paddingTop: '5px', paddingBottom: '5px' }} key={3}>
          <ButtonGroup fullWidth variant="outlined" size="small">
            <Button size="small" style={getTailButtonStyle(null)} onClick={() => handleTurnTail(null)}>
              <Typography variant="caption">{t('auto')}</Typography>
            </Button>
            <Button size="small" style={getTailButtonStyle(true)} onClick={() => handleTurnTail(true)}>
              <img
                src="/images/icon/switch/tail-1.svg"
                alt=""
              />
            </Button>
            <Button size="small" style={getTailButtonStyle(false)} onClick={() => handleTurnTail(false)}>
              <img
                src="/images/icon/switch/tail-off.svg"
                alt=""
              />
            </Button>
          </ButtonGroup>
        </MenuItem>
      ));

      return componentsArray;
    };

    handleOnClickMoreInfo(event);
  };

  return (
    <>
      <div style={style} className={classes.categoryPanel}>
        <AccordionSummary
          style={{ flex: 1 }}
          className={classes.accordionSummary}
          onClick={handleExpandDevicesForHeader}
        >
          <div className={classes.accordionSummaryContainer}>
            {deviceHeader.expanded ? (<ExpandMoreIcon color="action" />) : (<ExpandLess className={classes.toRightIcon} color="action" />)}

            <div className={`${classes.accordionSummaryCaption} ${classes.accordionSummaryContainer}`}>
              <Tooltip title={t(`deviceCategories${toTitleCase(deviceHeader.title)}`)}>
                <Typography noWrap variant="subtitle1">
                  {t(`deviceCategories${toTitleCase(deviceHeader.title)}`)}
                </Typography>
              </Tooltip>
              <Typography variant="caption">
                (
                {deviceHeader.subtitle}
                )
              </Typography>
              {![undefined, null].includes(tailOn) && (
                <img
                  src={`/images/icon/switch/${tailOn ? 'tail-1' : 'tail-off'}.svg`}
                  alt=""
                  className={classes.tailIcon}
                />
              )}
              {![undefined, null].includes(iconText) && (
                <TextFieldsIcon className={`${classes[getIconTextColor(iconText)]} ${classes.textIcon}`} />
              )}
            </div>
          </div>
        </AccordionSummary>
        {!isReorderingMode && (
          <AccordionActions>
            <IconButton onClick={handleMenuOpening}>
              <MoreVertIcon />
            </IconButton>
          </AccordionActions>
        )}
      </div>
    </>
  );
};

export default VirtualHeaderRow;
