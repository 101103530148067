export default {
  airplane_silhouette: {
    canRotate: true,
  },
  alert_circle: {
    canRotate: false,
  },
  animal: {
    canRotate: true,
  },
  anti_aircraft_gun: {
    canRotate: false,
  },
  arrow_bounce: {
    canRotate: false,
  },
  artificial_object: {
    canRotate: false,
  },
  asterisk: {
    canRotate: false,
  },
  bicycle: {
    canRotate: false,
  },
  bird: {
    canRotate: false,
  },
  birds: {
    canRotate: false,
  },
  boat: {
    canRotate: false,
  },
  bunker: {
    canRotate: false,
  },
  bus: {
    canRotate: false,
  },
  cannon: {
    canRotate: false,
  },
  car: {
    canRotate: false,
  },
  circle_dashed: {
    canRotate: false,
  },
  crane: {
    canRotate: false,
  },
  decoy: {
    canRotate: false,
  },
  default: {
    canRotate: false,
  },
  explosion: {
    canRotate: false,
  },
  helicopter: {
    canRotate: false,
  },
  helicopter_silhouette: {
    canRotate: false,
  },
  hierarchy: {
    canRotate: false,
  },
  location: {
    canRotate: false,
  },
  message_circle: {
    canRotate: false,
  },
  motorcycle: {
    canRotate: false,
  },
  navigation: {
    canRotate: true,
  },
  offroad: {
    canRotate: false,
  },
  pentagon: {
    canRotate: false,
  },
  person: {
    canRotate: false,
  },
  pickup: {
    canRotate: false,
  },
  plane: {
    canRotate: true,
  },
  point: {
    canRotate: false,
  },
  point_fill: {
    canRotate: false,
  },
  radar: {
    canRotate: false,
  },
  radar_dish: {
    canRotate: false,
  },
  rocket: {
    canRotate: false,
  },
  run: {
    canRotate: false,
  },
  scooter: {
    canRotate: false,
  },
  ship: {
    canRotate: false,
  },
  soldiers: {
    canRotate: false,
  },
  square_rotated: {
    canRotate: false,
  },
  square: {
    canRotate: false,
  },
  square_arrow_up: {
    canRotate: true,
  },
  square_dot: {
    canRotate: false,
  },
  submarine: {
    canRotate: false,
  },
  tank_canon: {
    canRotate: false,
  },
  tank_gun: {
    canRotate: false,
  },
  tank_torpedo: {
    canRotate: false,
  },
  torpedo: {
    canRotate: false,
  },
  tractor: {
    canRotate: false,
  },
  train: {
    canRotate: false,
  },
  tram: {
    canRotate: false,
  },
  trolleybus: {
    canRotate: false,
  },
  truck: {
    canRotate: false,
  },
  uav: {
    canRotate: false,
  },
  uav_big: {
    canRotate: true,
  },
  uav_copter: {
    canRotate: false,
  },
  uav_plane: {
    canRotate: true,
  },
  uav_wing: {
    canRotate: false,
  },
  unknown: {
    canRotate: false,
  },
  users: {
    canRotate: false,
  },
  van: {
    canRotate: false,
  },
  vessel: {
    canRotate: false,
  },
  viewfinder: {
    canRotate: false,
  },
  remote_control: {
    canRotate: false,
  },
  'wireless-charging-i': {
    canRotate: false,
  },
  'wireless-charging-t': {
    canRotate: false,
  },
  phone: {
    canRotate: false,
  },
  phone2: {
    canRotate: false,
  },
  phone3: {
    canRotate: false,
  },
  phone4: {
    canRotate: false,
  },
  radio: {
    canRotate: false,
  },
  station: {
    canRotate: false,
  },
  station2: {
    canRotate: false,
  },
};
