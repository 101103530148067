import React, { useMemo, useState } from 'react';
import {
  IconButton, LinearProgress, Paper, Toolbar, Tooltip,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useSelector } from 'react-redux';
import TailFilter from '../../../common/components/TailFilter';
import SearchField from '../../../common/form/SearchField';
import CommandsPanel from './CommandsPanel';
import SortingMenu from './SortingMenu';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import usePersistedState from '../../../common/utils/usePersistedState';
import { useStylesMainPanel } from '../MainPage/MainPage.styles';
import VirtualListDevices from './VirtualListDevices';

const MainPanel = ({
  collapsed, progress, period, setPeriod, item, handleClose, setSmsSent, panel, setPanel, setPositionsBS, setMoveableDevice,
}) => {
  const classes = useStylesMainPanel();
  const t = useTranslation();
  const [searchField, setSearchField] = useState(false);
  const [sortValue, setSortValue] = usePersistedState('sorting', 'sortByName');
  const [sortReverseValue, setSortReverseValue] = usePersistedState('sortingReverse');
  const [commandsPanelDevice, setCommandsPanelDevice] = useState();
  const [searchName, setSearchName] = useState('');
  const deviceInited = useSelector((state) => state.devices.deviceInited);

  return (
    <Paper square elevation={3} className={`${classes.sidebar} ${collapsed && classes.sidebarCollapsed}`}>
      <Paper className={classes.paper} square elevation={3}>
        <Toolbar className={classes.toolbar} disableGutters>
          {!searchField && (
            <TailFilter
              period={period}
              setPeriod={setPeriod}
            />
          )}
          {!searchField ? (
            <Tooltip title={t('searchNameAndGroupsTitle')}>
              <IconButton onClick={() => setSearchField(true)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <SearchField searchName={searchName} setSearchName={setSearchName} placeholder={t('searchNameGroup')} />
              <Tooltip title={t('searchFieldTitle')}>
                <IconButton
                  onClick={() => {
                    setSearchField(false);
                    setSearchName('');
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>

            </>
          )}
          <Tooltip title={t('collapseTitle')}>
            <IconButton onClick={handleClose} className={classes.buttonHide}>
              <ArrowBackIosIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </Paper>
      {commandsPanelDevice ? (
        <CommandsPanel
          device={commandsPanelDevice}
          setCommandsPanelDevice={setCommandsPanelDevice}
          setSmsSent={setSmsSent}
        />
      ) : (
        <SortingMenu
          sortValue={sortValue}
          setSortValue={setSortValue}
          sortReverseValue={sortReverseValue}
          setSortReverseValue={setSortReverseValue}
          searchName={searchName}
          panel={panel}
          setPanel={setPanel}
        />
      )}
      <div className={classes.deviceList}>
        {useMemo(() => (
          <>
            {deviceInited ? (
              <>
                <VirtualListDevices
                  handleClose={handleClose}
                  onTail={item}
                  sortValue={sortValue}
                  setCommandsPanelDevice={setCommandsPanelDevice}
                  progress={progress}
                  searchName={searchName}
                  panel={panel}
                  setPositionsBS={setPositionsBS}
                  setMoveableDevice={setMoveableDevice}
                />
              </>
            ) : (
              <LinearProgress />
            )}
          </>
        ), [sortValue, item, progress, searchName, deviceInited])}

      </div>
    </Paper>
  );
};

export default MainPanel;
