/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  useTheme,
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
  ListItemIcon,
  ListItemText,
  InputLabel,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from './LocalizationProvider';
import LinkField from '../form/LinkField';
import { toTitleCase } from '../utils/formatter';
import { useEffectAsync } from '../utils/reactHelper';
import { getIsAdmin } from '../utils/selectors';
import CoordinatesBlock from './CoordinatesBlock';
import CustomColorPicker from './CustomColorPicker';

const MIN_VALUE = 0;
const MAX_VALUE = 500;
const timesList = ['second', 'minute', 'hour', 'day', 'month', 'infinity'];

const MarkerEditForm = ({
  item, setItem, permissions, setPermissions, errors, setErrors,
}) => {
  const t = useTranslation();
  const theme = useTheme();
  const isAdmin = useSelector(getIsAdmin);
  const userStorage = useSelector((state) => state.session.user);

  const [icons, setIcons] = useState();
  useEffect(() => {
    if (item?.attributes?.lifetime?.time && !item.attributes.lifetime.value) {
      setItem({ ...item, attributes: { ...item?.attributes, lifetime: { ...item?.attributes?.lifetime, value: 1 } } });
    }
  }, [item]);

  useEffect(() => {
    if (item && !permissions) {
      const permissionsDict = {
        unions: {
          baseId: null,
          keyBase: 'markerId',
          keyLink: 'unionId',
          linked: new Set(),
          old: new Set(),
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  useEffectAsync(async () => {
    const response = await fetch('/api/icons');
    if (response.ok) {
      setIcons(await response.json());
    } else {
      setIcons([]);
    }
  }, []);

  const isNumber = (value) => !Number.isNaN(value) && !Number.isNaN(parseFloat(value));

  const isNumberRangeCorrect = (event) => event.target.value > MIN_VALUE && event.target.value <= MAX_VALUE;

  const handleCourseChange = (e) => {
    const { value } = e.target;
    if (isNumber(value)) {
      let numberValue = parseFloat(value);

      if (numberValue > 360) {
        numberValue = 360;
      } else if (numberValue < -360) {
        numberValue = -360;
      }
      setItem({ ...item, course: numberValue });
    } else {
      setItem({ ...item, course: 0 });
    }
  };

  return (
    <div>
      <TextField
        fullWidth
        value={item.name || ''}
        label={t('markerName')}
        onChange={(e) => e.target.value.length < 26 && setItem({ ...item, name: e.target.value })}
        margin="dense"
        variant="filled"
      />
      <TextField
        fullWidth
        value={item.description || ''}
        label={t('markerDescription')}
        multiline
        onChange={(e) => e.target.value.length < 4001 && setItem({ ...item, description: e.target.value })}
        margin="dense"
        variant="filled"
      />
      <TextField
        fullWidth
        value={item.course}
        label={t('markerCourse')}
        onChange={handleCourseChange}
        margin="dense"
        variant="filled"
      />
      <CoordinatesBlock
        latitude={item.latitude}
        longitude={item.longitude}
        setLatitude={(latitude) => setItem((prevCoordinates) => ({ ...prevCoordinates, latitude }))}
        setLongitude={(longitude) => setItem((prevCoordinates) => ({ ...prevCoordinates, longitude }))}
        errors={errors}
        setErrors={setErrors}
      />
      {icons && (
        <FormControl fullWidth variant="filled" margin="dense" required>
          <InputLabel>{t('markerIcon')}</InputLabel>
          <Select
            value={item.iconName}
            onChange={(e) => setItem({
              ...item,
              iconName: e.target.value,
              icon: icons.find((icon) => icon.name === e.target.value)?.icon || 'default',
              color: icons.find((icon) => icon.name === e.target.value)?.color || theme.palette.markers.black,
            })}
            renderValue={() => (
              <div style={{ display: 'flex', gap: theme.spacing(2) }}>
                <img src={`/images/icon/device/${item.icon}.svg`} alt="" />
                <Typography>{item.iconName}</Typography>
              </div>
            )}
          >
            {[{ id: 0, name: t('markerDefaultCat'), icon: 'default' }, ...icons].map((cat) => (
              <MenuItem value={cat.name} key={cat.id}>
                <ListItemIcon>
                  <img src={`/images/icon/device/${cat.icon}.svg`} alt="" />
                </ListItemIcon>
                <ListItemText>{cat.name}</ListItemText>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <CustomColorPicker presetColors={theme.palette.markers} color={item.color} label={t('markerColor')} setColor={(e) => { setItem({ ...item, color: e.toLowerCase() }); }} />

      <label style={{ color: 'rgba(0, 0, 0, 0.54)', paddingLeft: '12px' }}>{t('markerLifetime')}</label>
      <Grid container spacing={1} style={{ marginBottom: '4px' }}>
        {(item?.attributes?.lifetime?.time !== 'infinity' && item?.attributes?.lifetime?.time) && (
          <Grid item xs={8}>
            <TextField
              style={{ margin: 0 }}
              value={item?.attributes?.lifetime?.value}
              inputProps={{ min: MIN_VALUE, max: MAX_VALUE }}
              fullWidth
              variant="filled"
              type="number"
              onChange={(e) => {
                if (isNumberRangeCorrect(e)) {
                  setItem({
                    ...item,
                    attributes: {
                      ...item?.attributes,
                      lifetime: {
                        ...item?.attributes?.lifetime,
                        value: Number(e.target.value),
                      },
                    },
                  });
                }
              }}
            />
          </Grid>
        )}
        <Grid item xs={(item?.attributes?.lifetime?.time !== 'infinity' && item?.attributes?.lifetime?.time) ? 4 : 12}>
          <Select
            variant="filled"
            value={item?.attributes?.lifetime?.time || 'infinity'}
            fullWidth
            onChange={(e) => setItem({
              ...item,
              attributes: {
                ...item?.attributes,
                lifetime: {
                  ...item?.attributes?.lifetime,
                  time: e.target.value,
                },
              },
            })}
          >
            {timesList.map((val) => (
              <MenuItem key={val} value={val}>
                {t(`globalTime${toTitleCase(val)}`)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      {permissions && (
        <LinkField
          margin="dense"
          endpointAll={`/api/unions?${isAdmin ? 'all=true' : `userId=${userStorage?.id}`}`}
          endpointLinked={`/api/unions?markerId=${item.id}`}
          label={t('settingsUnions')}
          variant="filled"
          permissions={permissions}
          setPermissions={setPermissions}
          type="unions"
          fullWidth
          newItem={!item.id}
        />
      )}
    </div>
  );
};

export default MarkerEditForm;
