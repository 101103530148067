import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AccordionDetails, Checkbox, Divider, FormControlLabel, TextField, Typography, makeStyles, useTheme,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import SelectField from '../../../common/form/SelectField';
import categoryParamsStructure from '../../../common/static/categoryParamsStructure';
import {
  isIpValid, isPortValid, isDistanceValid, isGeocoordValid,
} from '../../../common/utils/validators';
import { getIsAdmin } from '../../../common/utils/selectors';
import useDebounceWithCancel from '../../../common/hooks/useDebounceWithCancel';
import CustomColorPicker from '../../../common/components/CustomColorPicker';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  divider: {
    marginBottom: '6px',
  },
}));

const applyInitState = (initState) => {
  const initParams = {};
  if (initState) {
    Object.entries(initState).forEach(([k, v]) => initParams[k] = { value: v, error: false });
  }
  return initParams;
};

const DeviceCategoryFields = ({
  initState, category, model, updateCategoryParams, updateCategoryParamsError,
}) => {
  const t = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isAdmin = useSelector(getIsAdmin);

  const [params, setParams] = useState(applyInitState(initState));

  const delayedOnChangeHandler = useDebounceWithCancel((f, args) => f(...args));

  const textFieldOnChangeHandler = (field, validator) => {
    setParams({ ...params, [field.name]: { value: field.value, error: !validator(field.value) } });
  };

  useEffect(() => {
    updateCategoryParams(Object.fromEntries(Object.entries(params).map((e) => [e[0], e[1].value])));
    updateCategoryParamsError(Object.keys(params).map((k) => params[k].error).some((v) => v));
  }, [params]);

  useEffect(() => {
    setParams(applyInitState(initState));
  }, [initState]);

  const getDefaultValue = (field) => (categoryParamsStructure[category].model[model]
    ? categoryParamsStructure[category].model[model].writen[field]
    : categoryParamsStructure[category].model.default.writen[field]);

  switch (category) {
    case 'radar':
      return (
        <AccordionDetails className={classes.details}>
          <TextField
            required
            key={`azimuth${initState.azimuth}`}
            name="azimuth"
            disabled={!isAdmin}
            margin="normal"
            defaultValue={initState.azimuth !== undefined ? initState.azimuth : getDefaultValue('azimuth')}
            onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, (v) => isGeocoordValid(v, 0, 360)])}
            label={t('deviceAzimuth')}
            error={params.azimuth?.error}
            helperText={params.azimuth?.error ? t('azimuthValidateErrorMsg') : ''}
            variant="filled"
          />
          <TextField
            required
            key={`diagram${initState.diagram}`}
            name="diagram"
            disabled={!isAdmin}
            margin="normal"
            defaultValue={initState.diagram !== undefined ? initState.diagram : getDefaultValue('diagram')}
            onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, (v) => isGeocoordValid(v, 0, 360)])}
            label={t('deviceDiagram')}
            error={params.diagram?.error}
            helperText={params.diagram?.error ? t('diagramValidateErrorMsg') : ''}
            variant="filled"
          />
          <TextField
            required
            key={`radius${initState.radius}`}
            name="radius"
            disabled={!isAdmin}
            margin="normal"
            defaultValue={initState.radius !== undefined ? initState.radius : getDefaultValue('radius')}
            onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isDistanceValid])}
            label={t('deviceRadius')}
            error={params.radius?.error}
            helperText={params.radius?.error ? t('radiusValidateErrorMsg') : ''}
            variant="filled"
          />
          <CustomColorPicker
            presetColors={theme.palette.tracks}
            color={params.radarColor?.value || getDefaultValue('radarColor')}
            label={t('deviceRadarColor')}
            setColor={(event) => setParams({ ...params, radarColor: { value: event, error: false } })}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={!!params.showRadarArea?.value}
                onChange={(e) => setParams({ ...params, showRadarArea: { value: e.target.checked, error: false } })}
              />
            )}
            label={t('deviceShowRadarArea')}
          />
          <TextField
            required
            key={`externalId${initState.externalId}`}
            name="externalId"
            disabled={!isAdmin}
            margin="normal"
            defaultValue={initState.externalId !== undefined ? initState.externalId : getDefaultValue('externalId')}
            onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isDistanceValid])}
            label={t('deviceExternalId')}
            error={params.externalId?.error}
            helperText={params.externalId?.error ? 'Недопустимое значение' : ''}
            variant="filled"
          />
          {(() => {
            switch (model) {
              case 'rubezh':
                return (
                  <>
                    <TextField
                      required
                      key={`mti${initState.mti}`}
                      name="mti"
                      disabled={!isAdmin}
                      margin="normal"
                      defaultValue={initState.mti !== undefined ? initState.mti : getDefaultValue('mti')}
                      onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isDistanceValid])}
                      label={t('deviceMti')}
                      error={params.mti?.error}
                      helperText={params.mti?.error ? 'Недопустимое значение' : ''}
                      variant="filled"
                    />
                    <TextField
                      required
                      key={`ip${initState.ip}`}
                      name="ip"
                      disabled={!isAdmin}
                      margin="normal"
                      defaultValue={initState.ip !== undefined ? initState.ip : getDefaultValue('ip')}
                      onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isIpValid])}
                      label={t('deviceIp')}
                      error={params.ip?.error}
                      helperText={params.ip?.error ? t('ipValidateErrorMsg') : ''}
                      variant="filled"
                    />
                    <TextField
                      required
                      key={`dataPort${initState.dataPort}`}
                      name="dataPort"
                      disabled={!isAdmin}
                      margin="normal"
                      defaultValue={initState.dataPort !== undefined ? initState.dataPort : getDefaultValue('dataPort')}
                      onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isPortValid])}
                      label={t('deviceDataPort')}
                      error={params.dataPort?.error}
                      helperText={params.dataPort?.error ? t('portValidateErrorMsg') : ''}
                      variant="filled"
                    />
                    <TextField
                      required
                      key={`controlPort${initState.controlPort}`}
                      name="controlPort"
                      disabled={!isAdmin}
                      margin="normal"
                      defaultValue={initState.controlPort !== undefined ? initState.controlPort : getDefaultValue('controlPort')}
                      onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isPortValid])}
                      label={t('deviceControlPort')}
                      error={params.controlPort?.error}
                      helperText={params.controlPort?.error ? t('portValidateErrorMsg') : ''}
                      variant="filled"
                    />

                  </>
                );
              case 'shade':
                return (
                  <>
                    <TextField
                      required
                      key={`mti${initState.mti}`}
                      name="mti"
                      disabled={!isAdmin}
                      margin="normal"
                      defaultValue={initState.mti !== undefined ? initState.mti : getDefaultValue('mti')}
                      onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isDistanceValid])}
                      label={t('deviceMti')}
                      error={params.mti?.error}
                      helperText={params.mti?.error ? 'Недопустимое значение' : ''}
                      variant="filled"
                    />
                    <TextField
                      required
                      key={`ip${initState.ip}`}
                      name="ip"
                      disabled={!isAdmin}
                      margin="normal"
                      defaultValue={initState.ip !== undefined ? initState.ip : getDefaultValue('ip')}
                      onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isIpValid])}
                      label={t('deviceIp')}
                      error={params.ip?.error}
                      helperText={params.ip?.error ? t('ipValidateErrorMsg') : ''}
                      variant="filled"
                    />
                    <TextField
                      required
                      key={`dataPort${initState.dataPort}`}
                      name="dataPort"
                      disabled={!isAdmin}
                      margin="normal"
                      defaultValue={initState.dataPort !== undefined ? initState.dataPort : getDefaultValue('dataPort')}
                      onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isPortValid])}
                      label={t('deviceDataPort')}
                      error={params.dataPort?.error}
                      helperText={params.dataPort?.error ? t('portValidateErrorMsg') : ''}
                      variant="filled"
                    />
                  </>
                );
              case 'globus':
                return (
                  <>
                    <TextField
                      required
                      key={`ip${initState.ip}`}
                      name="ip"
                      disabled={!isAdmin}
                      margin="normal"
                      defaultValue={initState.ip !== undefined ? initState.ip : getDefaultValue('ip')}
                      onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isIpValid])}
                      label={t('deviceIp')}
                      error={params.ip?.error}
                      helperText={params.ip?.error ? t('ipValidateErrorMsg') : ''}
                      variant="filled"
                    />
                    <TextField
                      required
                      key={`port${initState.port}`}
                      name="port"
                      disabled={!isAdmin}
                      margin="normal"
                      defaultValue={initState.port !== undefined ? initState.port : getDefaultValue('port')}
                      onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isPortValid])}
                      label={t('deviceDataPort')}
                      error={params.port?.error}
                      helperText={params.port?.error ? t('portValidateErrorMsg') : ''}
                      variant="filled"
                    />
                    <TextField
                      required
                      key={`elevationAngle${initState.dataPort}`}
                      name="elevationAngle"
                      margin="normal"
                      defaultValue={initState.elevationAngle !== undefined ? initState.elevationAngle : getDefaultValue('elevationAngle')}
                      onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, (v) => isGeocoordValid(v, 90)])}
                      label={t('deviceElevationAngle')}
                      error={params.elevationAngle?.error}
                      helperText={params.elevationAngle?.error ? t('elevationAngleValidateErrorMsg') : ''}
                      variant="filled"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={!!params.periodicMessages?.value}
                          onChange={(e) => setParams({ ...params, periodicMessages: { value: e.target.checked, error: false } })}
                        />
                      )}
                      label={t('devicePeriodicMessages')}
                    />
                  </>
                );
              default:
                return null;
            }
          })()}
        </AccordionDetails>
      );

    case 'anti_uav':
      switch (model) {
        case 'strizh3': {
          const modes = categoryParamsStructure.anti_uav.model.strizh3.fields.workMode;
          const controleModes = categoryParamsStructure.anti_uav.model.strizh3.fields.controlMode;

          const isNumberOfHostsValid = (value) => /^\d+$/.test(value) && Number(value) >= 1 && Number(value) <= 10;

          const changeNumberOfHosts = (numberOfHosts) => {
            if (!isNumberOfHostsValid(numberOfHosts)) {
              setParams({ ...params, numberOfHosts: { ...params.numberOfHosts, error: true } });
              return;
            }

            const newNumberOfHosts = Number(numberOfHosts);
            const newParams = {};

            if (newNumberOfHosts > params.numberOfHosts.value) {
              for (let i = Number(params.numberOfHosts.value); i < newNumberOfHosts; i++) {
                newParams[`ip${i + 1}`] = { value: '0.0.0.0', error: false };
                newParams[`port${i + 1}`] = { value: Number(params.port0.value) + i, error: false };
                newParams[`radius${i + 1}`] = { value: 0, error: false };
                newParams[`azimuth${i + 1}`] = { value: 0, error: false };
              }
            } else if (newNumberOfHosts < params.numberOfHosts.value) {
              setParams({
                ...Object.fromEntries(Object.entries(params).filter(([key]) => {
                  if (['ip', 'port', 'radius', 'azimuth'].some((pref) => key.startsWith(pref))) {
                    const num = Number(key.replace(/(ip|port|radius|azimuth)/, ''));
                    return !(num > newNumberOfHosts);
                  }
                  return true;
                })),
                numberOfHosts: { value: newNumberOfHosts, error: false },
              });
              return;
            }
            setParams({ ...params, ...newParams, numberOfHosts: { value: newNumberOfHosts, error: false } });
          };

          return (
            <AccordionDetails className={classes.details}>
              <SelectField
                margin="normal"
                value={params.workMode?.value || getDefaultValue('workMode')}
                emptyValue={null}
                onChange={(event) => setParams({ ...params, workMode: { value: event.target.value, error: false } })}
                data={Object.entries(modes).filter(([mode]) => mode !== 'unknown').map(([mode, title]) => ({ id: mode, name: t(title) }))}
                label={t('deviceWorkMode')}
                variant="filled"
              />
              <>
                <Typography>{t('deviceHostMainAntena')}</Typography>
                <TextField
                  required
                  key={`ip${initState.ip0}`}
                  name="ip0"
                  disabled={!isAdmin}
                  margin="normal"
                  defaultValue={(() => {
                    if (params.ip0 !== undefined) {
                      return params.ip0?.value;
                    }
                    if (getDefaultValue('ip0') !== undefined) {
                      return getDefaultValue('ip0');
                    }
                    return '0.0.0.0';
                  })()}
                  onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isIpValid])}
                  label={t('deviceIp0')}
                  error={params.ip0?.error}
                  helperText={params.ip0?.error ? t('ipValidateErrorMsg') : ''}
                  variant="filled"
                />
                <TextField
                  required
                  name="port0"
                  disabled={!isAdmin}
                  margin="normal"
                  defaultValue={(() => {
                    if (params.port0 !== undefined) {
                      return params.port0?.value;
                    }
                    if (getDefaultValue('port0') !== undefined) {
                      return getDefaultValue('port0');
                    }
                    return getDefaultValue('port0');
                  })()}
                  onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isPortValid])}
                  label={t('devicePort0')}
                  error={params.port0?.error}
                  helperText={params.port0?.error ? t('portValidateErrorMsg') : ''}
                  variant="filled"
                />
                <TextField
                  required
                  key={`radius${initState.radius0}`}
                  name="radius0"
                  disabled={!isAdmin}
                  margin="normal"
                  defaultValue={(() => {
                    if (params.radius0 !== undefined) {
                      return params.radius0?.value;
                    }
                    if (getDefaultValue('radius0') !== undefined) {
                      return getDefaultValue('radius0');
                    }
                    return 0;
                  })()}
                  onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isDistanceValid])}
                  label={t('deviceRadius0')}
                  error={params.radius0?.error}
                  helperText={params.radius0?.error ? t('radiusValidateErrorMsg') : ''}
                  variant="filled"
                />
                <TextField
                  required
                  key={`azimuth${initState.azimuth0}`}
                  name="azimuth0"
                  margin="normal"
                  defaultValue={(() => {
                    if (params.azimuth0 !== undefined) {
                      return params.azimuth0?.value;
                    }
                    if (getDefaultValue('azimuth0') !== undefined) {
                      return getDefaultValue('azimuth0');
                    }
                    return 0;
                  })()}
                  onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, (v) => isGeocoordValid(v, 0, 360)])}
                  label={t('deviceAzimuth0')}
                  error={params.azimuth0?.error}
                  helperText={params.azimuth0?.error ? t('azimuthValidateErrorMsg') : ''}
                  variant="filled"
                />
                <Divider className={classes.divider} />
              </>
              <TextField
                required
                key={`noh${initState.numberOfHosts}`}
                name="numberOfHosts"
                disabled={!isAdmin}
                type="number"
                margin="normal"
                defaultValue={initState.numberOfHosts !== undefined ? initState.numberOfHosts : getDefaultValue('numberOfHosts')}
                onChange={(event) => delayedOnChangeHandler(changeNumberOfHosts, [event.target.value])}
                label={t('deviceNumberOfHosts')}
                error={params.numberOfHosts?.error}
                helperText={params.numberOfHosts?.error ? t('NumberOfHostsValidateErrorMsg') : ''}
                variant="filled"
              />
              {[...Array(isNumberOfHostsValid(params.numberOfHosts?.value) ? params.numberOfHosts?.value : 1).keys()].map((n) => (
                <Fragment key={n + 1}>
                  <Typography>{`${t('deviceHost')} №${n + 1}`}</Typography>
                  <TextField
                    required
                    key={`ip${initState[`ip${n + 1}`]}`}
                    name={`ip${n + 1}`}
                    disabled={!isAdmin}
                    margin="normal"
                    defaultValue={(() => {
                      if (params[`ip${n + 1}`] !== undefined) {
                        return params[`ip${n + 1}`].value;
                      }
                      if (getDefaultValue(`ip${n + 1}`) !== undefined) {
                        return getDefaultValue(`ip${n + 1}`);
                      }
                      return '0.0.0.0';
                    })()}
                    onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isIpValid])}
                    label={t('deviceIp0')}
                    error={params[`ip${n + 1}`]?.error}
                    helperText={params[`ip${n + 1}`]?.error ? t('ipValidateErrorMsg') : ''}
                    variant="filled"
                  />
                  <TextField
                    required
                    name={`port${n + 1}`}
                    disabled={!isAdmin}
                    margin="normal"
                    defaultValue={(() => {
                      if (params[`port${n + 1}`] !== undefined) {
                        return params[`port${n + 1}`].value;
                      }
                      if (getDefaultValue(`port${n + 1}`) !== undefined) {
                        return getDefaultValue(`port${n + 1}`);
                      }
                      return getDefaultValue('port0') + n + 1;
                    })()}
                    onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isPortValid])}
                    label={t('devicePort0')}
                    error={params[`port${n + 1}`]?.error}
                    helperText={params[`port${n + 1}`]?.error ? t('portValidateErrorMsg') : ''}
                    variant="filled"
                  />
                  <TextField
                    required
                    key={`radius${initState[`radius${n + 1}`]}`}
                    name={`radius${n + 1}`}
                    disabled={!isAdmin}
                    margin="normal"
                    defaultValue={(() => {
                      if (params[`radius${n + 1}`] !== undefined) {
                        return params[`radius${n + 1}`].value;
                      }
                      if (getDefaultValue(`radius${n + 1}`) !== undefined) {
                        return getDefaultValue(`radius${n + 1}`);
                      }
                      return 0;
                    })()}
                    onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isDistanceValid])}
                    label={t('deviceRadius0')}
                    error={params[`radius${n + 1}`]?.error}
                    helperText={params[`radius${n + 1}`]?.error ? t('radiusValidateErrorMsg') : ''}
                    variant="filled"
                  />
                  <TextField
                    required
                    key={`azimuth${initState[`azimuth${n + 1}`]}`}
                    name={`azimuth${n + 1}`}
                    margin="normal"
                    defaultValue={(() => {
                      if (params[`azimuth${n + 1}`] !== undefined) {
                        return params[`azimuth${n + 1}`].value;
                      }
                      if (getDefaultValue(`azimuth${n + 1}`) !== undefined) {
                        return getDefaultValue(`azimuth${n + 1}`);
                      }
                      return 0;
                    })()}
                    onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, (v) => isGeocoordValid(v, 0, 360)])}
                    label={t('deviceAzimuth0')}
                    error={params[`azimuth${n + 1}`]?.error}
                    helperText={params[`azimuth${n + 1}`]?.error ? t('azimuthValidateErrorMsg') : ''}
                    variant="filled"
                  />
                  <Divider className={classes.divider} />
                </Fragment>
              ))}
              <SelectField
                margin="normal"
                value={params.controlMode?.value || getDefaultValue('controlMode')}
                emptyValue={null}
                onChange={(event) => setParams({ ...params, controlMode: { value: event.target.value, error: false } })}
                data={Object.entries(controleModes).map(([mode, title]) => ({ id: mode, name: t(title) }))}
                label={t('deviceControlMode')}
                variant="filled"
              />
            </AccordionDetails>
          );
        }
        default: {
          const modes = categoryParamsStructure.anti_uav.model.default.fields.workMode;
          return (
            <AccordionDetails className={classes.details}>
              <SelectField
                margin="normal"
                value={params.workMode?.value || getDefaultValue('workMode')}
                emptyValue={null}
                onChange={(event) => setParams({ ...params, workMode: { value: event.target.value, error: false } })}
                data={Object.entries(modes).map(([mode, title]) => ({ id: mode, name: t(title) }))}
                label={t('deviceWorkMode')}
                variant="filled"
              />
            </AccordionDetails>
          );
        }
      }

    case 'vcam':
      return (
        <AccordionDetails className={classes.details}>
          <TextField
            required
            key={initState.url}
            name="url"
            disabled={!isAdmin}
            margin="normal"
            defaultValue={initState.url !== undefined ? initState.url : getDefaultValue('url')}
            onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, (v) => v !== ''])}
            label={t('deviceVcamDescription')}
            error={params.url?.error}
            helperText={params.url?.error ? t('ValidateErrorMsg') : ''}
            variant="filled"
          />
          <TextField
            required
            key={`azimuth${initState.azimuth}`}
            name="azimuth"
            disabled={!isAdmin}
            margin="normal"
            defaultValue={initState.azimuth !== undefined ? initState.azimuth : getDefaultValue('azimuth')}
            onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, (v) => isGeocoordValid(v, 0, 360)])}
            label={t('deviceAzimuth')}
            error={params.azimuth?.error}
            helperText={params.azimuth?.error ? t('azimuthValidateErrorMsg') : ''}
            variant="filled"
          />
          <TextField
            required
            key={`diagram${initState.diagram}`}
            name="diagram"
            disabled={!isAdmin}
            margin="normal"
            defaultValue={initState.diagram !== undefined ? initState.diagram : getDefaultValue('diagram')}
            onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, (v) => isGeocoordValid(v, 0, 360)])}
            label={t('deviceDiagram')}
            error={params.diagram?.error}
            helperText={params.diagram?.error ? t('diagramValidateErrorMsg') : ''}
            variant="filled"
          />
          <TextField
            required
            key={`radius${initState.radius}`}
            name="radius"
            disabled={!isAdmin}
            margin="normal"
            defaultValue={initState.radius !== undefined ? initState.radius : getDefaultValue('radius')}
            onChange={(e) => delayedOnChangeHandler(textFieldOnChangeHandler, [e.target, isDistanceValid])}
            label={t('deviceRadius')}
            error={params.radius?.error}
            helperText={params.radius?.error ? t('radiusValidateErrorMsg') : ''}
            variant="filled"
          />
          <CustomColorPicker
            presetColors={theme.palette.tracks}
            color={params.videoCameraColor?.value || getDefaultValue('videoCameraColor')}
            label={t('deviceRadarColor')}
            setColor={(event) => setParams({ ...params, videoCameraColor: { value: event, error: false } })}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={!!params.showVideoCameraArea?.value}
                onChange={(e) => setParams({ ...params, showVideoCameraArea: { value: e.target.checked, error: false } })}
              />
            )}
            label={t('deviceShowRadarArea')}
          />
        </AccordionDetails>
      );

    default:
      return null;
  }
};

export default DeviceCategoryFields;

DeviceCategoryFields.propTypes = {
  initState: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  updateCategoryParams: PropTypes.func.isRequired,
  updateCategoryParamsError: PropTypes.func.isRequired,
};
