import { map } from '../Map';
import { getBeforeId } from './pathFuncs';

const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
const onMouseLeave = () => map.getCanvas().style.cursor = '';

export const createLayers = (id, onClusters, openMarkerInfo, onClusterClick) => {
  const clusters = `${id}-clusters`;
  const clustersCount = `${clusters}-count`;
  const course = `${id}-course`;

  map.addSource(id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
    cluster: !!onClusters,
    clusterMaxZoom: 14,
    clusterRadius: 20,
  });

  // Слой для иконок с canRotate = true
  map.addLayer({
    id: `${id}_rotatable`,
    type: 'symbol',
    source: id,
    filter: ['==', ['get', 'canRotate'], true],
    layout: {
      'icon-image': '{markerSymbol}',
      'icon-allow-overlap': true,
      'text-field': '{title}',
      'text-allow-overlap': true,
      'text-anchor': 'bottom',
      'text-font': ['Roboto Medium'],
      'text-offset': [0, -2],
      'text-size': 12,
      'icon-rotate': ['get', 'course'],
      'icon-rotation-alignment': 'map',
    },
    paint: {
      'icon-opacity': { type: 'identity', property: 'iconOpacity' },
      'text-opacity': { type: 'identity', property: 'textOpacity' },
    },
  }, getBeforeId(`${id}_rotatable`));

  // Слой для иконок с canRotate = false
  map.addLayer({
    id: `${id}_non_rotatable`,
    type: 'symbol',
    source: id,
    filter: ['==', ['get', 'canRotate'], false],
    layout: {
      'icon-image': '{markerSymbol}',
      'icon-allow-overlap': true,
      'text-field': '{title}',
      'text-allow-overlap': true,
      'text-anchor': 'bottom',
      'text-font': ['Roboto Medium'],
      'text-offset': [0, -2],
      'text-size': 12,
      'icon-rotate': 0,
      'icon-rotation-alignment': 'viewport',
    },
    paint: {
      'icon-opacity': { type: 'identity', property: 'iconOpacity' },
      'text-opacity': { type: 'identity', property: 'textOpacity' },
    },
  }, getBeforeId(`${id}_non_rotatable`));

  map.addLayer({
    id: course,
    type: 'symbol',
    source: id,
    layout: {
      'icon-image': '{icon}',
      'icon-rotate': { type: 'identity', property: 'course' },
      'icon-rotation-alignment': 'map',
    },
    paint: {
      'icon-opacity': { type: 'identity', property: 'iconOpacity' },
    },
  }, getBeforeId(course));

  if (onClusters) {
    map.addLayer({
      id: clusters,
      type: 'circle',
      source: id,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': '#6643b5',
        'circle-opacity': 0.8,
        'circle-blur': 0.1,
        'circle-radius': 15,
      },
    }, getBeforeId(clusters));

    map.addLayer({
      id: clustersCount,
      type: 'symbol',
      source: id,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['Roboto Regular'],
        'text-size': 14,
      },
    }, getBeforeId(clustersCount));
    map.on('click', clusters, onClusterClick);
    map.on('mouseenter', clusters, onMouseEnter);
    map.on('mouseleave', clusters, onMouseLeave);
  }

  map.on('mouseenter', `${id}_rotatable`, onMouseEnter);
  map.on('mouseleave', `${id}_rotatable`, onMouseLeave);
  map.on('click', `${id}_rotatable`, openMarkerInfo);

  map.on('mouseenter', `${id}_non_rotatable`, onMouseEnter);
  map.on('mouseleave', `${id}_non_rotatable`, onMouseLeave);
  map.on('click', `${id}_non_rotatable`, openMarkerInfo);

  return () => {
    map.off('mouseenter', `${id}_rotatable`, onMouseEnter);
    map.off('mouseleave', `${id}_rotatable`, onMouseLeave);
    map.off('click', `${id}_rotatable`, openMarkerInfo);

    map.off('mouseenter', `${id}_non_rotatable`, onMouseEnter);
    map.off('mouseleave', `${id}_non_rotatable`, onMouseLeave);
    map.off('click', `${id}_non_rotatable`, openMarkerInfo);

    if (onClusters) {
      map.off('click', clusters, onClusterClick);
      map.off('mouseenter', clusters, onMouseEnter);
      map.off('mouseleave', clusters, onMouseLeave);
      if (map.getLayer(clustersCount)) {
        map.removeLayer(clustersCount);
      }
      if (map.getLayer(clusters)) {
        map.removeLayer(clusters);
      }
    }
    if (map.getLayer(`${id}_rotatable`)) {
      map.removeLayer(`${id}_rotatable`);
    }
    if (map.getLayer(`${id}_non_rotatable`)) {
      map.removeLayer(`${id}_non_rotatable`);
    }
    if (map.getLayer(course)) {
      map.removeLayer(course);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  };
};

export const createRadarLayers = (id) => {
  const radar = `${id}-radar`;

  map.addSource(id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    source: id,
    id: radar,
    type: 'fill',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
    ],
    paint: {
      'fill-color': { type: 'identity', property: 'color' },
      'fill-outline-color': '#705439',
      'fill-opacity': 0.25,
    },
  }, getBeforeId(radar));

  return () => {
    if (map.getLayer(radar)) {
      map.removeLayer(radar);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  };
};
