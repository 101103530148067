import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { map } from './Map';
import { getExtraMapId } from '../common/utils/formatter';

const ExtraMap = ({ opacityMaps }) => {
  const extraMaps = useSelector((state) => state.session.server.attributes.extraMaps ?? []);

  useEffect(() => {
    extraMaps.forEach((extraMap) => {
      if (extraMap.params.tiles.length === 0) {
        return;
      }

      const id = getExtraMapId(extraMap.id);
      map.addSource(id, {
        type: 'raster',
        tiles: extraMap.params.tiles,
        tileSize: Number(extraMap.params.tileSize),
        scheme: 'xyz',
        attribution: extraMap.params.attribution ?? '',
      });

      map.addLayer({
        id,
        type: 'raster',
        source: id,
        layout: {
          visibility: 'none',
        },
        paint: {
          'raster-opacity': 0,
        },
      });
    });

    return (() => {
      extraMaps.forEach((extraMap) => {
        const id = getExtraMapId(extraMap.id);
        if (map.getLayer(id)) {
          map.removeLayer(id);
        }
        if (map.getSource(id)) {
          map.removeSource(id);
        }
      });
    });
  }, [extraMaps]);

  useEffect(() => {
    Object.keys(opacityMaps).forEach((opacityMapId) => {
      if (map.getLayer(opacityMapId)) {
        if (opacityMaps[opacityMapId]?.s) {
          map.setLayoutProperty(opacityMapId, 'visibility', 'visible');
        } else {
          map.setLayoutProperty(opacityMapId, 'visibility', 'none');
        }
        map.setPaintProperty(opacityMapId, 'raster-opacity', opacityMaps[opacityMapId]?.opacity ?? 0);
      }
    });
  }, [opacityMaps]);

  return null;
};

export default ExtraMap;
